@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.resourceWrapper {
  margin: 0 165px 0 62px;
  .on-lg-down({
    margin: 0 31px;
  });
  .on-sm-down({
    margin: 0 24px;
  });
  .additionalRes {
    font-size: 13px;
    font-weight: bold;
    .on-lg-down({
      margin-left: 5px;
    });
  }
  .resIcon {
    margin-top: 1px;
    margin-right: 13px;
  }
  .iconSize {
    width: 24px;
    height: 25px;
  }

  .resourceLinksWrapper {
    margin-bottom: 15px;
    a {
      &:hover {
        text-decoration: underline;
        color: @primaryLinkColor;
      }
      margin-left: -5px;
    }
    .on-lg-down({
      margin-left: 5px
    });
  }

  :global(.ant-collapse-item-active) {
    .collapseCaret {
      transform: rotate(180deg);
      transition: 0.2s linear;
    }
  }
  :global(.ant-collapse-item) {
    .collapseCaret {
      transition: 0.2s linear;
    }
  }

  :global {
    .ant-collapse-borderless > .ant-collapse-item {
      margin-bottom: 10px;
      background: @white;
      border: 1px solid @mdvCardBorder;
      box-sizing: border-box;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding: 6px 12px;
      .on-sm-down({
        padding: 0
      });
    }
    .ant-collapse-borderless {
      .on-sm-down({
        width: 100%;
      });
    }

    .ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      display: flex;
      margin-right: 20px;
      .on-sm-down({
        top: 83%;
        left: 0;
        padding: 10px 18px;
      });
    }

    .ant-collapse
      > .ant-collapse-item.ant-collapse-no-arrow
      > .ant-collapse-header {
      padding-left: 15px;
    }

    .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
      border-bottom: 1px solid @mdvCardBorder;
    }

    .ant-collapse {
      .ant-collapse-header {
        .on-sm-down({
          padding-bottom: 45px;
        });
      }
    }
  }

  .resourceHeader {
    width: 85%;
    .on-sm-down({
      width: 100%;
    });
  }

  .providerInfo {
    margin-left: -24px;
    .on-sm-down({
      margin-left: 0;
    });

    .providerType {
      .text-card-sub-header();
      color: @planCardTextColor;
    }

    .providerNameRow {
      display: flex;
    }

    .providerName {
      .text-card-header();
      font-weight: bold;
      width: 60%;
      white-space: nowrap;
      .on-sm-down({
        width: 100%;
      });
      &.fullWidth {
        width: 100%;
      }
    }
    .carrierUrlWrapper {
      .carrierUrl {
        .text-banner-description();
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
      }
    }
  }

  .providerContact {
    margin-top: 7px;
    padding-left: 20px;
    .text-submit-button();
    color: @defaultFontColor;
    .on-sm-down({
      margin: 10px 0;
      padding-left: 0;
    });
  }

  a {
    &:hover {
      text-decoration: none;
      color: @primaryLinkColor;
    }
  }

  .expandIconWrapper {
    .expandText {
      .text-banner-description();
      color: @resourceLinkColor;
      text-decoration: underline;
      margin-right: 8px;
    }
  }

  .resTextArea {
    margin-top: 40px;
    width: 230px;
    word-wrap: break-word;
    .resourceContent {
      padding: 3px 5px 4px;
      .on-lg-down({
        img, iframe {
          max-width: 200px
        }
      });
    }
    .on-lg-down({
      width: 100%;
      margin-top: 20px;
    });
  }

  .resContentBtn {
    margin-top: 40px;
    width: 220px;
    .linkHotspot {
      border-radius: 3px;
      color: @primaryFontColor;
      padding: 6px 10px 4px;
      .text-button();
      img {
        margin: 0 0 4px 5px;
      }
    }
    :global(.overlayWrapper) {
      border-radius: 5px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;