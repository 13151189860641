@import 'styles/variables.less';
@import 'styles/typography.less';

.container {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: @white;
  display: flex;
  padding: 30px;
  .logoWrapper {
    height: 100%;
  }
  .info {
    display: flex;
    margin-left: 40px;
    flex-direction: column;
    justify-content: center;
  }
  .header {
    .text-banner-header();
    margin-bottom: 12px;
    color: @defaultFontColor;
  }
  .description {
    .text-banner-description();
    color: @black;
  }
  .logoWrapper {
    display: flex;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;