@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 80px;
  left: 750px;
  width: 25%;
}
.perksWrapper {
  margin: 0 163px 40px 62px;
  .on-lg-down({
    margin: 0 4%;
  });
  .on-sm-down({
    margin: 0 4%;
  });
  .perksIcon {
    margin-top: 2px;
    margin-right: 13px;
  }
  .addOrRemovePlansWrapper {
    :global(.overlayWrapper) {
      border-radius: 7px;
      width: 174px;
      left: -4px;
    }
    position: absolute;
    margin-top: -65px;
    margin-left: 547px;
    width: 165px;
    height: 28px;
    left: 0px;

    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 10px;
    }
  }
  .imageUploadWrapper {
    display: inherit;
  }
  .mainImage {
    width: 100%;
  }

  .noPlanView {
    margin-left: unset;
  }

  .planCardsContainer {
    .on-lg-down({
      display: flex;
      column-gap: 4%;
      flex-wrap: wrap;
    });
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;