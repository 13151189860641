@import 'styles/variables.less';
@import 'styles/typography.less';

.documentUploaderWrapper {
  position: relative;
  display: flex;

  input[type='file'] {
    display: none;
  }

  .fileLabel {
    display: flex;
    align-items: center;
    float: right;
    margin-left: 30px;
    overflow: auto;
    .text-link-button();
  }

  .fileNameArea {
    margin-left: 8px;
    cursor: pointer;
  }

  .removeFile {
    display: flex;
    align-items: center;
    margin-left: 7px;
    color: @removeFileTextColor;
    cursor: pointer;
    .noFileSelected {
      color: @primaryLinkDisabledColor;
    }
  }

  :global {
    .ant-btn {
      width: 150px;
      border: 1px solid @black;
      margin-top: 0px;
      border-radius: 4px;
      .text-submit-button();
    }
  }

  .invalidError {
    .form-error-text();
    margin-top: 8px;
  }
}
.cancelUploadBody {
  margin-bottom: -20px;
  p {
    .text-popconfirm-secondary-text();
  }
}

.uploadButton {
  height: 40px;
}

:global(.planDocumentUploaderWrapper) {
  width: 580px;
  :global(.ant-popover-inner-content) {
    width: 100%;
    padding: 30px;
    :global(.ant-popover-message) {
      :global(.ant-popover-message-title) {
        margin-left: 35px;
        margin-top: -40px;
        :global(.title) {
          font-weight: 800;
          font-size: 14px;
        }
        :global(.seconday-text) {
          font-weight: 400;
          font-size: 14px;
        }
        :global(.list) {
          font-weight: 400;
          font-size: 14px;
          margin-top: 20px;
        }
      }
    }

    :global(.ant-popover-buttons) {
      margin-left: 40px;
      margin-top: -20px;
      :global(button) {
        height: 40px;
        border-radius: 5px;
        width: 400px;
        display: block;
        height: 40px;
        border: 1px solid @black;
        border-radius: 4px;
        .text-submit-button();
        margin-top: 10px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;