@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.medicalCardWrapper {
  display: flex;
  padding-top: 38px;
  position: relative;
  .on-sm-down({
    display: flex;
    flex-direction: column;
  });

  .leftRoundIcon {
    position: absolute;
    left: 125px;
    z-index: 1;
    top: 100px;
    cursor: pointer;
    .on-sm-down({
      display: none
    });
  }
  @media only screen and (max-width: 1050px) {
    .leftRoundIcon {
      left: 26%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 1025px) {
    .leftRoundIcon {
      left: 26.5%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 1000px) {
    .leftRoundIcon {
      left: 26%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
    .leftRoundIcon {
      left: 25.5%;
      top: 20px;
    }
  }
  .rightRoundIcon {
    position: absolute;
    left: 1155px;
    cursor: pointer;
    top: 100px;
    .on-sm-down({
      display: none
    });
  }
  @media only screen and (max-width: 1496px) {
    .rightRoundIcon {
      top: 100px;
      left: 90%;
    }
  }
  @media only screen and (max-width: 1396px) {
    .rightRoundIcon {
      left: 92%;
      top: 100px;
    }
  }
  @media only screen and (max-width: 1300px) {
    .rightRoundIcon {
      left: 91.5%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .rightRoundIcon {
      left: 92%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .rightRoundIcon {
      left: 91.5%;
      top: 20px;
    }
  }
  @media only screen and (max-width: 1000px) {
    .rightRoundIcon {
      left: 92%;
      top: 20px;
    }
  }

  .benefitDetail {
    padding-left: 16px;
    min-height: 29px;
    background-color: @white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .viewSbc {
      .view-sbc-link();
      padding-top: 12px;
      height: 40px;
      border-top: 1px solid @borderColor;
      width: 101%;
      margin-left: -8px;
      .on-sm-down({
        margin-left: 0;
      });
    }

    .sbcLink {
      cursor: pointer;
    }
  }

  .emptySummary {
    padding-left: 16px;
    background-color: @white;
    padding-top: 6px;
    height: 40px;
  }

  .missingInfo {
    height: 40px;
    :global {
      .missingInfoWrapper {
        width: 150px;
      }
    }
  }
}
.customMargin {
  padding-top: 16px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;