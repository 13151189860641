@import 'styles/variables.less';
@import 'styles/typography.less';

.footerColorCustomizer {
  padding: 0px 0px 6px 15px;
}

.sectionEditorWrapper {
  .selectLabel {
    .select-label-section-editor();
    margin: 30px 17px 0px 0px;
  }

  .selectDropdown {
    margin: 13px 0px 28px 0px;

    :global {
      .ant-select-selector {
        padding: 10px 18px;
        border-radius: 4px;
        height: 50px;
        width: 400px;
        color: @defaultFontColor;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;