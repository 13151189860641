@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}
.volBenefitsWrapper {
  margin: 0 165px 0 62px;
  position: relative;

  .on-lg-down({
    margin: 0 31px;
    width: auto;
  });

  .on-sm-down({
    margin: 0 24px;
  });
  .noPlans {
    .on-lg-down({
      display: block;
    });
  }

  .volBenefitsContainer {
    .on-lg-down({
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    });
    .on-sm-down({
      display: block;
    });
  }

  .addOrRemovePlansWrapper {
    :global(.overlayWrapper) {
      border-radius: 7px;
      width: 174px;
      left: -4px;
    }
    position: absolute;
    width: 165px;
    height: 28px;
    left: 288px;
    top: 20px;

    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 10px;
    }
  }

  .volIcon {
    width: 24px;
    height: 22.26px;
    margin-top: 2px;
    margin-right: 3px;
  }

  .noPlanView {
    margin-left: unset;
  }

  .darkButton {
    .add-new-plan-button ();
    background: @defaultFontColor;
    color: @white;
    width: 164px;
    height: 40px;
    border-radius: 4px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;