@import 'styles/typography';
@import 'styles/variables';

.cardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 520px;

  .hiddenContainer {
    width: 544px;
    display: none;
    height: 280px;
  }

  .heading {
    font-size: 24px;
    color: @primaryHeaderColor;
    font-weight: 900;
    margin-bottom: 12px;
  }

  .linkText {
    color: @primaryLinkColor !important;
    font-size: 14px;
  }

  .footerContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .flexEndContainer {
      width: 50%;
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
    }
  }

  .actionsHeader {
    margin-bottom: 16px;
  }

  .actionsFooter {
    margin-top: 16px;
  }

  .flexContainer {
    color: @pageHeaderIconColor;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .tabContainer {
    width: 100%;
    padding: 0px 12px;

    :global {
      .ant-tabs-nav::before {
        border-bottom: 1px solid @defaultLightBorderColor;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          position: relative;
          display: flex;
          align-items: center;
          width: 144px;
          justify-content: center;
          text-align: center;
          padding: 12px 0;
          font-size: 14px;
          background: transparent;
          border: 0;
          outline: none;
          cursor: pointer;
        }

        .ant-tabs-tab {
          color: @pageHeaderIconColor;
          font-weight: bolder;
        }

        .ant-tabs-tab-active {
          text-align: center;
          .text-tab-header();
        }
        .ant-tabs-ink-bar {
          background: @primaryLinkColor !important;
        }
      }

      .ant-tabs-ink-bar {
        background: var(--color-theme);
        min-height: 3px;
        max-height: 3px;
      }
    }

    .tabWrapper {
      .innerWrapper {
        margin: 12px 0px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;