@import 'styles/variables.less';

.dropdownWrapper {
  font-weight: 600;

  .title {
    display: flex;
    align-items: center;
    color: @primaryLinkColor;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    gap: 6px;
  }

  :global {
    .ant-dropdown-menu {
      border-radius: 4px;
    }
    .ant-dropdown-menu-title-content {
      font-size: 13px;
      font-weight: bolder;
    }
    .ant-dropdown-menu-item {
      color: @defaultFontColor;
    }
    .ant-dropdown-menu-item-disabled .ant-dropdown-menu-title-content {
      color: @primaryLinkDisabledColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;