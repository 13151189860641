@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 80px;
  left: 750px;
  width: 25%;
}
.familyAndLeaveWrapper {
  margin: 0 164px 40px 62px;
  position: relative;
  .on-lg-down({
    margin: 0 4%;
    width: auto;
  });
  .on-sm-down({
    margin: 0 4%;
  });

  .mainImage {
    width: 100%;
  }

  .famIcon {
    margin-right: 4px;
  }

  .imageUploadWrapper {
    display: inherit;
  }

  .addOrRemovePlansWrapper {
    :global(.overlayWrapper) {
      border-radius: 7px;
      width: 174px;
      left: -4px;
    }
    position: absolute;
    width: 165px;
    height: 28px;
    left: 247px;
    top: 20px;

    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 10px;
    }
  }

  .noPlanView {
    margin-left: unset;
    .on-lg-down({
      width: 100%;
    });
  }

  .darkButton {
    .add-new-plan-button ();
    background: @defaultFontColor;
    color: @white;
    width: 164px;
    height: 40px;
    border-radius: 4px;
  }
}

.planCardsContainer {
  .on-lg-down({
    display: flex;
    column-gap: 4%;
    flex-wrap: wrap;
  });
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;