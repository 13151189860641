@import 'styles/variables.less';
@import 'styles/typography.less';

.addOrRemovePlansWrapper {
  padding: 5px;
  .header {
    display: flex;
    width: 520px;
    .addIcon {
      .add-and-remove-plans-icon();
      margin-right: 10px;
      margin-top: 6px;
    }

    .title {
      .add-and-remove-plans-title ();
    }
    .noPlansHeader {
      .infoIcon {
        padding-left: 5px;
      }
    }
  }

  .selectPlanText {
    .add-and-remove-plans-secondary-text();
    margin-top: 25px;
    margin-bottom: 7px;
  }

  .planNameWrapper {
    height: 220px;
    overflow-y: auto;
  }

  .heightWithNotificationAlert {
    height: 150px;
  }

  .planNames {
    display: inline-flex;
    flex-wrap: wrap;
    .planName {
      color: @black;
      white-space: nowrap;
      width: 176px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.ant-checkbox-wrapper) {
      width: 220px;
      margin-left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.ant-checkbox-wrapper:nth-child(even)) {
      padding-left: 20px;
    }
    :global {
      .ant-checkbox + span {
        padding-top: 5px;
      }
    }
  }

  .buttons {
    display: flex;
    margin-top: 25px;
    float: right;
    :global(.ant-btn) {
      width: 140px;
      height: 40px;
      margin-left: 8px;
      border-radius: 4px;
      border: 1px solid @defaultFontColor;
      font-weight: bold;
    }

    .addSelectedBtn {
      width: 165px;
      background-color: @defaultFontColor;
      color: white;
    }

    .darkButton {
      .add-new-plan-button ();
      background: @defaultFontColor;
      color: @white;
      width: 164px;
      height: 40px;
      border-radius: 4px;
    }

    button[disabled] {
      color: @white;
      background-color: @disabledButton;
      border: none;
    }

    .createNewPlanWrapper {
      :global(.overlayWrapper) {
        border: none;
        background-color: unset;
      }
    }
  }
}

.planNameWrapperNoPlan {
  min-height: 270px;
}

.planModalWrapper {
  padding-top: 32px;
}

.addOrRemovePlansModal {
  :global(.ant-modal-body) {
    height: 450px;
  }
  :global(.ant-modal) {
    box-shadow: 0px 3px @benefitTextColor;
  }
}

.widthoutModal {
  width: 574px;
  margin-top: 45px;
  border: solid 2px @defaultFontColor;
  display: flex;
  position: relative;
  padding: 20px;
  background-color: @white;
  box-shadow: 0px 4px @basicInfoBackgroundColor;
}

:global {
  .addPlansOverlay {
    max-width: 427px;
    :global {
      .ant-popover-arrow {
        overflow: visible;
        height: 8px;
        width: 18px;
        background-color: transparent;
        transform: translateY(0%) rotate(0deg);
      }

      .ant-popover-arrow-content {
        left: calc(50% - 13.2px / 2 - 6.5px);
        width: 11px;
        height: 11px;
        border-right: 0.5px solid @black;
        border-bottom: 0.5px solid @black;
        border-radius: 0px 0px 4px 0px;
      }

      .ant-popover-inner {
        border-radius: 8px;
      }
      .ant-popover-inner-content {
        border: 1px solid;
        border-radius: 8px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;