@import 'styles/variables.less';
@import 'styles/typography.less';

.missingDataModalWrapper {
  .errorMessage {
    font-size: 13px;
  }

  .messageWrapper {
    padding-top: 15px;
  }

  button {
    height: 40px;
    width: 150px;
    .dbg-edit-modal-button();
  }

  .done {
    margin-left: 12px;
    background-color: @primaryHeaderColor;
    color: @white;
  }

  :global(.ant-modal-body) {
    padding: 0px 30px;
    margin-top: 0px;
    max-height: 550px;
    margin-right: 15px;
    border-left: 5px solid @primaryHeaderColor;
    overflow-y: auto;
  }

  :global(.ant-modal-footer) {
    border-left: 5px solid @primaryHeaderColor;
    border-top: none;
  }

  :global(.ant-modal-header) {
    border-left: 5px solid @primaryHeaderColor;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 2px;
  }

  .title {
    .dbg-edit-modal-title();
  }

  .planText {
    .dbg-edit-modal-title();
    font-size: 16px;
    padding-bottom: 2px;
    padding-top: 15px;
  }

  .missingText {
    font-size: 13px;
    padding-top: 8px;
    font-weight: 500;
  }

  .validationText {
    margin-top: 30px;
    font-weight: bold;
  }

  li {
    line-height: 0;
  }

  ul {
    padding-left: 18px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @lineBorderColor;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: @primaryLinkDisabledColor;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;