@import 'styles/variables.less';
@import 'styles/typography.less';

.frequencyWrapper {
  .disabledContributionNotice {
    padding: 15px;
    background-color: #fefbf4;
    border: 1px solid @editableHotspotBorderColor;
  }

  .frequencyDetailsRow {
    padding: 10px 10px 10px 0;
  }

  .frequencyDisplayRow {
    align-items: center;
    display: flex;
  }

  .frequencySelect {
    width: 200px;
  }

  .planRedirectLink {
    color: @primaryLinkColor;
    cursor: pointer;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;