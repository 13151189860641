@import 'styles/media.less';
@import 'styles/variables.less';

.editDrawer {
  :global(.rs-drawer-header-close) {
    display: none;
  }

  :global {
    .rs-drawer-body {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    .rs-drawer-backdrop.in {
      opacity: 0.5;
    }
    .rs-drawer-right.rs-drawer-md {
      width: 100%;
    }

    .rs-drawer-backdrop.in,
    .rs-drawer-backdrop.fade {
      background-color: @white;
    }
  }
}

.editModeHeaderWrapper {
  padding-top: 30px;
  margin-left: 20px;
  margin-right: 20px;

  .editYourAnswers {
    height: 29px;
    color: @defaultFontColor;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    float: left;
  }
  .backArrow {
    margin-right: 20px;
  }

  & .updateButton:disabled {
    background-color: @disableButton;
  }

  .splitter {
    margin-top: 60px;
    margin-bottom: 0px;
  }
}

.editModeWrapper {
  :global {
    .content {
      min-height: unset;
    }

    .planRecommenderOptions {
      margin-top: -30px;
      text-align: left;
      border-bottom: 1px solid @defaultLightBorderColor;

      .content {
        .options-wrapper {
          margin-top: 35px;
        }
      }

      .title {
        text-align: left;
        font-size: 17px;
        color: @defaultFontColor;
        .on-xsm-down({
          padding-right: 10%;
         });
      }
      .subTitle {
        text-align: left;
        font-size: 12px;
        color: @defaultFontColor;
        margin-left: 0px;
        .on-xsm-down({
          margin-left: 10%;
        });
      }
      .optionsWrapper {
        margin-left: 0px;
        .option {
          width: 350px;
          height: 77px;
          .on-xsm-down({
            width: 350px;
            height: 65px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: 10%;
          });
          img {
            max-width: 90%;
          }

          .labelWrapper {
            .label {
              width: unset;
              font-size: 11px;
              line-height: 13px;
            }
          }
        }
      }
    }

    .stateSelectorWrapper {
      text-align: left;
      border-bottom: 1px solid #e6e7e8;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10%;
      padding-right: 10%;
      .on-xsm-down({
        margin-left: 0px;
     });
      .title {
        text-align: left;
        color: @defaultFontColor;
        font-size: 16px;
        width: 400px;
        .on-xsm-down({
            width: 300px;
         });
      }
      .stateDropdown {
        margin-left: 0px;
        margin-top: 30px;
        width: 282px;
        margin-bottom: -15px;
      }
      .stateErrorWrapper {
        margin-left: 15px;

        .stateError {
          margin-left: 0;
        }
      }
      .nextButtonWrapper {
        margin-top: 60px;
      }
    }

    .nextButton {
      display: none;
    }

    .planRecommenderPlansWrapper {
      display: none;
    }

    .planRecommenderResultsWrapper {
      display: none;
    }

    .emailSuccessWrapper {
      display: none;
    }

    .planRecommenderCombinedSlider {
      text-align: left;
      .planRecommenderSlider {
        border-bottom: none;
      }

      .planRecommenderSlider:nth-of-type(even) {
        border-bottom: 1px solid #e6e7e8;
      }

      .content {
        .sliderWrapper {
          margin-left: 0px;
        }
        .title {
          text-align: left;
          font-size: 16px;
          color: @defaultFontColor;
          margin-top: 50px;
          padding-right: 10%;
        }
        .titleSlider {
          text-align: left;
          font-size: 16px;
          color: @defaultFontColor;
          margin-left: 17px;
          margin-left: 0px;
        }
        .subTitle {
          text-align: left;
          font-size: 12px;
          color: @defaultFontColor;
          margin-left: 10%;
          margin-right: 10%;
          .on-xsm-down({
            margin-left: 10%;
         });
        }
      }
    }

    .planRecommenderSlider {
      margin-top: -30px;
      text-align: left;
      border-bottom: 1px solid @defaultLightBorderColor;

      .sliderWrapper {
        margin-left: 17px;
        margin-left: 0px;
      }
      .title {
        text-align: left;
        font-size: 16px;
        color: @defaultFontColor;
      }
      .subTitle {
        text-align: left;
        font-size: 12px;
        color: @defaultFontColor;
        margin-left: 0px;
      }
    }

    .planRecommenderIncrementer {
      text-align: left;
      margin-left: 0px;
      margin-right: 0px;
      .on-xsm-down({
        margin-left: 0px;
        margin-right: 0px;
      });
      .skipText {
        display: none;
      }

      .title {
        text-align: left;
        font-size: 16px;
        color: @defaultFontColor;
      }

      .subTitle {
        text-align: left;
        font-size: 12px;
        color: @defaultFontColor;
      }
    }
  }
}

.updateButton {
  float: right;
  height: 41px;
  width: 157px;
  color: @white;
  text-align: center;
  background-color: var(--color-theme);
  user-select: none;
  outline: none;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  width: 100% !important;
  height: 77px !important;
  text-align: center !important;
  margin: 0px;
  position: absolute;
  z-index: 200;
}
.drawerBody {
  height: 82% !important;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;