@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.pageHeaderWrapper {
  height: 66px;
  display: flex;
  border-bottom: 1px solid @defaultLightBorderColor;
  margin-bottom: 20px;
  padding-top: 22px;
  white-space: pre;
  .dbg-page-title();
  .on-lg-down({
    .tab-dbg-page-title();
  });
  .on-sm-down({
    .mob-dbg-page-title();
  });
  .iconComponent {
    margin-right: 9.82px;

    // All page header icons should be in the images/PageHeaderIcons folder with same class as below.
    :global {
      .pageHeaderIcon {
        fill: @pageHeaderIconColor;
      }
    }
  }
  .managePlansBtn {
    margin-top: -4px;
    margin-left: 40px;
    :global {
      .overlayWrapper {
        border-radius: 7px;
        width: 170px;
        left: -4px;
      }
    }
    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 10px;
      padding: 5px 10px 8px 10px;
      margin-top: -4px;
      span {
        height: auto;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;