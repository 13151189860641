@import 'styles/variables.less';
@import 'styles/media.less';
@import 'styles/typography.less';

.familyAndLeaveCardWrapper {
  min-height: 187px;
  margin-top: 41px;
  position: relative;
  display: flex;
  .on-lg-down({
    width: 48%;
    display: flex;
    flex-direction: column;
    min-height: unset;
  });
  .on-sm-down({
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: unset;
  });

  .summaryWrapper {
    width: 281px;
    height: 100%;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    .on-lg-down({
      width: 100%;
      border-top: 5px solid #DADFE8;
      border-radius: 8px 8px 0 0;
    });

    :global(.editableHotspotWrapper) {
      height: 100%;
    }

    .summary {
      padding-top: 20px;
      padding-left: 25px;
      min-height: 187px;
      .on-lg-down({
        padding: 20px 5px 20px 20px;
        min-height: unset;
       });

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 10px;
        .on-lg-down({
          margin-bottom: 0px;
          position: absolute;
          top: -25px;
          right: 30px;
        });
      }

      .planName {
        .plan-name();
        margin-bottom: 15px;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        color: @black;
        display: inline-block;
        .on-lg-down({
          max-width: 75%;
          word-break: normal;
        });
      }
    }
  }
  .viewDetailsLink {
    width: 230px;
    color: @primaryLinkColor;
    cursor: pointer;
    .plan-view-details();
    padding-top: 10px;
    border-top: 1px solid @defaultLightBorderColor;
    .on-lg-down({
      width: 88%;
      padding: 15px 0 12px;
      margin: 0 auto;
    });
  }

  .description {
    width: 100%;
    min-height: 187px;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    word-break: break-word;
    :global(.editableHotspotWrapper) {
      height: 100%;
    }
    .on-lg-down({
      border-left: 1px solid @defaultLightBorderColor;
      border-top: unset;
      border-radius: 0 0 8px 8px;
    });

    .content {
      padding: 30px;
    }
  }

  .addTextWrapper {
    position: relative;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    width: 100%;
    .addTextHotspot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;