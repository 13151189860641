@import 'styles/variables.less';
@import 'styles/media.less';
@import 'styles/typography.less';

.contentWrapper {
  .on-xl-up( {
    min-height: 340px;
  });
  .on-xxl-up( {
    min-height: 426px;
  });
  position: relative;
  .lineBorder {
    border-bottom: 1px solid var(--home-text-color);
    position: absolute;
    width: 100%;
    top: 45px;
  }
  .textWrapper {
    right: 40px;
    top: 20px;
  }
}

.contentWrapper.noImage {
  min-height: unset;
}

.addImgWrapper {
  position: absolute;
  margin-left: 78%;
  top: 93px;
  width: 250px;
  text-align: right;
  .linkHotspot {
    color: @primaryFontColor;
    background-color: @white;
    padding: 5px 5px 5px 12px;
    .text-button();
    img {
      margin: -5px 10px 0 30px;
    }
    span {
      margin-right: -15px;
    }
  }
  :global {
    .editableHotspotWrapper {
      .overlayWrapper {
        border-radius: 3px;
      }
    }
  }
}

.overviewImageWrapper {
  position: absolute;
  margin-left: 78%;
  top: 93px;
  .imgAction {
    cursor: pointer;
    text-align: right;
    img {
      margin-left: 10px;
    }
  }
  .editImageBtn {
    .imgAction();
    margin: 13px 10px 0 0;
  }
  .removeImgBtn {
    .imgAction();
    margin: 5px 10px 0 0;
  }
  .imgPlaceholder {
    z-index: -1;
  }
  .homeImgWrapper {
    position: absolute;
    top: 5px;
    left: 34px;
    // Hiding image when on smaller screens (tablet/mobile)
    .on-lg-down({
      display: none
    });
  }

  .isEditWrapper {
    cursor: pointer;
  }
}

.contentBody {
  width: 75%;
  margin-top: 50px;
  .on-lg-down({
    margin-right: 0
  });

  .textContent {
    .title {
      .home-text-wrapper-title();
      color: var(--home-text-color);
      padding-top: 10px;
    }
    .text {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--home-text-color);
      }

      color: var(--home-text-color);
      margin: 39px 0 0 0px;
      img {
        max-width: 100%;
      }
      iframe {
        max-width: 100%;
      }
    }
    .benefitsWrapper {
      margin: 28px 0 0 0;
      .on-lg-down({
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

      });
      .checklistLink {
        display: block;
        text-decoration: none;
        color: inherit;
        .on-lg-down({
          width: 49%;
          margin-bottom: 10px;

        });
        .on-sm-down({
          width: 100%
        });

        .areaWrapper {
          border-radius: 4px;
          margin-top: 2px;
          background-color: @white;
          .on-lg-down({
            border: 1px solid @pageHeaderIconColor
          });
          .on-sm-down({
            width: 100%
          });

          .area {
            margin-left: 10px;
            display: flex;
            height: 60px;
            .benefitImage {
              max-width: 34px;
              margin-top: 13px;
              max-height: 34px;
              :global {
                .benefitIcon {
                  fill: var(--color-theme);
                }
              }
            }
            .areaName {
              .area-text();
              margin: 18px 0 0 15px;
            }
          }
        }
      }
      .planRecommenderBtn {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
        .on-lg-down({
          width: 49%;
          margin-bottom: 10px;

        });
        .on-sm-down({
          width: 100%
        });
      }
    }
  }

  .benefitsOverlayWrapper {
    padding: 20px;
  }

  .benefitsEnrollmentWrapper {
    :global {
      .ant-btn:active,
      .ant-btn:hover,
      .ant-btn:focus {
        border: none;
      }
    }
    .on-xs-down({
        width: 100%;
      });
    .on-sm-up({
        width: 470px;
      });
    margin-top: 40px;
    &.editMode {
      cursor: pointer;
    }

    .linkButtonOffset {
      margin: 8px 0 0 250px;
      color: @primaryFontColor;
      display: flex;
      img {
        margin: -20px 0 0 10px;
      }
    }
    .linkButton {
      background: var(--color-theme);
      border: none;
      color: @white;
      height: 45px;
      border-radius: 7px;
      .text-button();
      .on-lg-down({
        width: 325px;
      });
      .on-sm-down({
        width: 100%;
      });

      .enrollLinkText {
        margin-top: 8px;
      }
    }
    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          border-radius: 7px;
        }
      }
    }
  }

  .benefitsHotspotWrapper {
    margin-top: 40px;
    width: 280px;
    .linkHotspot {
      border-radius: 3px;
      color: @primaryFontColor;
      background-color: @white;
      padding: 5px 5px 5px 12px;
      .text-button();
      img {
        margin: -5px 10px 0 27px;
      }
    }
    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          border-radius: 3px;
        }
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;