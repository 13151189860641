@import 'styles/variables.less';

.timePickerWrapper {
  :global {
    .ant-picker-time-panel-column {
      text-transform: lowercase;
    }

    .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background: @lineBorderColor;
    }

    .ant-btn-sm {
      height: 26px;
      padding: 0px 10px;
      width: 50px;
      border-radius: 4px;
      font-weight: bold;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;