@import 'styles/media.less';
@import 'styles/variables';

.planRecommenderWrapper {
  width: 100%;
  height: 100vh;
  padding-top: 85px;

  :global {
    .nextButton {
      background-color: var(--color-theme);
      width: 150px;
      height: 40px;
      border: none;
      border-radius: 5px;
      color: white;
      user-select: none;
      outline: none;
      cursor: pointer;
      .on-sm-down({
        width: 100%;
        height: 65px;
        border-radius: 0px;
        position: absolute;
        margin-top: 185px;
        right: 0;
      });
    }

    .nextButton:disabled {
      background-color: @inputBorderColor;
    }
  }
}
.moreInfoModal {
  :global {
    .ant-modal {
      max-width: 100%;
      padding-bottom: 0px;
    }
  }
}

.__react_component_tooltip {
  text-align: left;
  border-radius: 5px;
}

.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: white;
}

.__react_component_tooltip.type-dark.place-right:before {
  border-right: 8px solid #cccbca;
}

.__react_component_tooltip.type-light.border.place-right:before {
  border-right: 8px solid #cccbca;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;