@import 'styles/variables.less';
@import 'styles/media';

.planRecommenderIncrementer {
  text-align: center;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 80px;
    height: unset;
    color: black;
    .on-sm-down({
      margin-left: 10%;
      margin-right: 10%;
    });
    .on-sm-down({
      margin-left: 10%;
      margin-right: 10%;
    });
  }

  .subTitle {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    .on-sm-down({
      margin-left: 10%;
      margin-right: 10%;
    });
    .on-xsm-down({
      margin-left: 10%;
      margin-right: 10%;
    });
  }

  .incrementListWrapper {
    margin-top: 100px;
    .on-sm-down({
      margin-top: 58px;
    });
    .perYear {
      color: #a5adaf;
      margin-bottom: 15px;
      margin-left: 329px;
      .on-sm-down({
        display: none;
      });
    }
    .listItemWrapper {
      display: block;
      .subTitle {
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        .on-sm-down({
          font-size: 12px;
        });
      }
      .listItem {
        display: inline-flex;
        width: 336px;
        .on-sm-down({
          margin-left: 10%;
          margin-bottom: 20px;
          width: 100%;
        });
        .on-xsm-down({
          margin-left: 10%;
          margin-bottom: 20px;
        });
        .listTitle {
          min-width: 300px;
          color: #2a3233;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: left;
          font-weight: bold;
          .on-sm-down({
            width: 140px;
            min-width: 0px;
            margin-right: 15%;
            font-size: 12px;
          });
          .on-xsm-down({
            width: 120px;
            min-width: 0px;
            margin-right: 5%;
            font-size: 12px;
            margin-left: 15px;
          });
        }

        .controlBtn {
          cursor: pointer;
          .disabled {
            opacity: 0.4;
          }
        }

        .inputField {
          input {
            box-sizing: border-box;
            height: 45px;
            width: 61px;
            border: 1px solid #dadfe8;
            border-radius: 7px;
            background-color: #ffffff;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: -10px;
            margin-bottom: 20px;
            text-align: center;
            font-size: 18px;
            .on-xsm-down({
              margin-top: -5px;
              margin-left: 16px;
              margin-right: 16px;
            });
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }

  .skipText {
    height: 41px;
    width: 228px;
    color: #3970b0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }

  .nextBtnWrapper {
    margin-top: 50px;
    .on-sm-down({
      margin-top:0;
    });
    button {
      width: 200px;
      .on-sm-down({
        width: 100%;
      });
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;