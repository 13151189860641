@import 'styles/variables.less';

#root {
  height: 100%;
}

.fa-check.green::before {
  color: @checkmarkColor;
}

.fa-times.red::before {
  color: @crossmarkColor;
}

.fa-check.green,
.fa-times.red {
  display: inline-block;
  margin-right: 12px;
}

.hiw-list-header {
  margin-bottom: 12px;
}

.hiw-list-header tr th,
.hiw-list-header tr td {
  text-align: left;
  vertical-align: top;
}

.default-font-16 {
  font-size: 16px;
}

.default-font-14 {
  font-size: 14px;
}

.hiw-deductible-work-default-img {
  margin: 25px 0px;
}

.hiw-deductible-work-default-img img {
  max-width: 900px;
}

.bullet-disc {
  list-style-type: disc;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;