@import 'styles/variables';
.cardBodyRow {
  width: 212px;
  display: inline-block;
  height: 28px;
  padding-left: 15px;
  padding-top: 3px;
  margin-left: -20px;
}

.cardBodyDark {
  background-color: @mdvCardBorder;
  color: @defaultFontColor;
  width: 150px;
}

.cardBodyLight {
  color: @defaultFontColor;
  width: 150px;
}

.cardBodyRowSeperator {
  height: 32px;
}

.planCardContianer > .planCardValues {
  margin-top: 18px;
}

.planDetailComparison {
  width: 425px;
}

.planCardInTitle,
.planCardOutTitle {
  display: inline-block;
  height: 18px;
  color: @defaultFontColor;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}
.planCardInTitle {
  margin-left: 3px;
  padding-right: 151px;
}

.planDetailCardBody {
  margin-top: 10px;

  .cardBodyRowCaontainer {
    max-height: 28px;
    margin-left: 170px;
  }

  .cardBodyValue {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 19px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 500px) {
  .mdvPlanDetailWrapper {
    .mobilePlanDetailCardBody {
      width: 84vw;
      .cardBodyRowCaontainer {
        width: 84vw;
        .cardBodyRow {
          width: 84vw;
          height: auto;
          .cardBodyValue {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .mdvPlanDetailWrapper {
    .mobilePlanDetailCardBody {
      display: none;
    }
  }
}

.TabView {
  margin-left: -30px;
  margin-top: -40px;
  display: flex;
  margin-left: -170px;
  position: fixed;
  :global {
    .ant-tabs-tab {
      flex-grow: 1;
      margin-right: 0px;
      text-align: center;
      border-color: @black;
    }
    .ant-tabs-tab-btn {
      outline: none;
      transition: all 0.3s;
      font-size: 11px;
      font-size: 13px;
      margin-right: 45px;
    }
    .ant-tabs-ink-bar {
      background: var(--color-theme);
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;