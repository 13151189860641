@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.onSchedWrapper {
  margin-top: -20px;

  :global {
    * {
      font-family: 'Chivo';
    }
    .onsched-theme-primary.sm .onsched-availability {
      .onsched-calendar-header {
        flex-direction: row;
        margin-top: 20px;
      }

      .onsched-service-name {
        color: @black;
        font-size: 12px;
        border-bottom: none;
        padding: 0px;
        margin: 0px;
        font-weight: 400;
      }

      .onsched-calendar-title {
        font-size: 12px;
        font-weight: 400;
        color: @black;
      }

      .onsched-calendar-row {
        height: 0px;
      }

      .onsched-calendar-col.dow {
        color: @black;
        font-size: 12px;
      }

      .loading #onsched-availability-loading {
        border-top: 4px solid @black;
        border-right: 4px solid @black;
        border-bottom: 4px solid @black;
        margin-top: 118px;
      }

      .onsched-calendar button:not([disabled='disabled']) {
        color: @black;
        font-size: 12px;
        font-weight: 400;
      }
      .onsched-calendar button:disabled {
        font-size: 12px;
        font-weight: 400;
        color: @primaryLinkDisabledColor;
      }

      .onsched-calendar-col {
        .day {
          padding: 0px;
        }

        .day:hover:not([disabled='disabled']) {
          background-color: @disabledButton;
        }

        .dow {
          color: @black;
          font-size: 12px;
        }
      }

      .onsched-available-times h5 {
        color: @black;
        font-weight: 400;
        font-size: 12px;
        opacity: 1;
      }

      .display-times {
        flex-direction: row;
        flex-wrap: nowrap;
        .on-sm-down({
          flex-direction: column;
        });

        .day.selected {
          background-color: @defaultFontColor;
          color: @white;
        }

        .onsched-available-times {
          width: 100%;
          overflow: auto;
          height: 263px;
          margin-left: 25px;
          margin-top: -17px;
          line-height: 30px;
          .on-sm-down({
            margin-left: 0;
            margin-top: 0;
          });
        }
      }

      .onsched-chip {
        border: 1px solid @black;
        color: @black;
        font-size: 12px;
        font-weight: 400;
        height: 40px;
        line-height: 30px;
      }

      .onsched-chip:hover {
        background-color: @black;
        color: @white;
      }

      .onsched-timezone {
        margin-bottom: 5px !important; //important is needed here because important is used in the library too.
      }

      .onsched-form {
        .onsched-form-row.submit-row {
          .btn-submit {
            background-color: @black;
            color: @white;
            width: 95%;
          }
          .btn-cancel {
            background-color: transparent;
            border: 1px solid @black;
            color: @black;
            width: 95%;
          }
        }

        input:hover,
        textarea:hover {
          border-color: @black;
        }
      }

      .onsched-booking-summary {
        h5:first-child {
          display: none;
        }
        i {
          position: relative;
          margin-right: 10px;
        }
      }
    }

    .onsched-booking-confirmation h4 {
      display: none;
    }

    .onsched-theme-primary.sm .onsched-dropdown {
      .onsched-dropdown-button {
        width: 270px;
        height: 32px;
        margin-left: 6px;
        padding-left: 12px;
        line-height: 30px;
        font-size: 12px;
        color: @black;
      }

      .onsched-dropdown-button:hover {
        border-color: @black;
      }
    }

    .onsched-theme-primary.sm .onsched-dropdown.active {
      .onsched-dropdown-button {
        border-bottom-width: thin;
      }
      .onsched-dropdown-button {
        border-color: @disabledButton;
      }
    }

    .onsched-theme-primary.sm .onsched-dropdown::after {
      position: revert;
      font-size: 12px;
    }

    .onsched-theme-primary.sm .onsched-dropdown.active::after {
      margin-left: -30px;
    }

    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      li:hover:not(.onsched-timezone-region),
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      li:hover:not(.onsched-province-region) {
      background-color: @black;
    }

    .onsched-form {
      input[type='text'],
      input[type='email'],
      input[type='phone'] {
        font-size: 13px;
        color: @black;
        height: 30px;
        width: 95%;
      }
    }

    .onsched-theme-primary.sm .onsched-form {
      textarea {
        font-size: 13px;
        color: @black;
        width: 95%;
      }
    }

    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-timezone-search
      input,
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-province-search
      input,
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-country-search
      input {
      font-size: 12px;
      padding: 5px;
      margin-top: 9px;
    }

    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content {
      border: 1px solid @disabledButton;
      border-top-width: thin;
      margin-top: 10px;
    }

    // search icon
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-country-search::after {
      font-size: 12px;
      top: 10px;
    }

    // search bar clicked
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-timezone-search
      input:focus,
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      .onsched-country-search
      input:active {
      border-color: @disabledButton; // or black, TBD
    }

    // font size of timezones list
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      li {
      font-size: 12px;
    }

    // font size of timezone list heading
    .onsched-theme-primary.sm
      .onsched-dropdown.active
      .onsched-dropdown-content
      li.onsched-timezone-region {
      font-size: 12px;
    }

    .onsched-close-btn {
      display: none;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;