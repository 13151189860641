@import 'styles/media.less';

.titleContainer {
  width: 165px;
  font-size: 13px;
  font-weight: bold;
  color: #212326;
  .on-sm-down({
    width: 120px;
    font-size: 11px;
});
}

.titleItem {
  &:first-of-type {
    .title-top {
      margin-top: -6px;
      &.medicalPlanName {
        margin-top: -10px;
      }
    }

    .popover {
      top: -20px;
    }
  }
}

.titleContainerMargin {
  margin-top: 63px;
  .on-sm-down({
    margin-top: 100px;
});
}

.titleTop {
  margin-top: 10px;
  height: 51px;

  .popover {
    top: -22px;
  }
}

.title {
  height: 28px;
  line-height: 29px;

  .popover {
    top: -16px;
  }
}

.title,
.titleTop {
  position: relative;
  &.medicalMonthlyCost {
    margin-top: 70px;
  }
  &:hover {
    .popover {
      display: block;
    }
  }

  .popover {
    color: white;
    min-width: 275px;
    min-height: 55px;
    display: none;
    border-radius: 4px;
    border: none;
    background: rgba(0, 0, 0, 0.95);
    padding: 13px 16px 11px 20px;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    left: 160px;
    position: absolute;
    z-index: 9;

    &:first-of-type {
      top: -20px;
    }

    &:before {
      border-top: 12px solid #000;
      border-right: 1px solid #000;
      border-left: 12px solid #000;
      background: rgba(0, 0, 0, 0.95);
      transform: rotate(45deg);
      // box-shadow: -1.5px 2px 2px 0 rgba(0, 0, 0, 0.13);
      -webkit-transform: rotate(48deg);
      top: 25px;
      content: '';
      display: block;
      left: 0%;
      margin-left: -6px;
      position: absolute;
    }
  }
}

.titleSeperator {
  height: 32px;
  line-height: 29px;
}

.titleContainer > *:last-child {
  .title {
    .popover.large {
      top: -55px;

      &:before {
        top: 65px;
      }
    }
  }
}

.mdvPlanDetailWrapper {
  .titleValue {
    display: block;
    width: 93%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;