@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.whosCoverSelectorWrapper {
  width: 325px;
  height: 100%;
  margin-left: auto;
  padding-top: 3px;
  position: relative;
  display: flex;
  user-select: none;
  .on-lg-down({
    width: 55%;
    margin-left: 0;
    padding-top: 0;
    align-items: center;
  });
  .on-sm-down({
    width: 50%;
  });

  .dot {
    background-color: var(--color-theme);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 26px;
    margin-right: 5px;
    .on-lg-down({
      margin-top: 0;
    });
    .on-sm-down({
      display: none;
    });
  }

  label {
    .mdv-whos-cover-label();
    text-align: center;
    text-transform: uppercase;
    margin-right: 25px;
    .on-lg-down({
      width: 150px;
    });
    .on-sm-down({
      display: none;
    });
  }

  .dropdownWrapper {
    .mdv-whos-cover-dropdown();
    width: 205px;
    height: 40px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    position: relative;
    background: @white;
    border: 0.5px solid @lineBorderColor;
    padding-left: 10px;
    padding-top: 8px;
    .on-lg-down({
      width: 100%;
      margin-top: 0px;
      margin-right: 10px;
    });
    .on-sm-down({
      margin-right: 0;
    });

    .selectedTypeLabel {
      margin-left: 5px;
      margin-top: 2px;
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 12px;
    }

    .outside {
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999998;
    }

    .overlay {
      position: absolute;
      top: 37px;
      left: -1px;
      width: 190px;
      z-index: 9999999;
      padding-top: 10px;
      background: @white;
      border-top: 0.5px solid @lineBorderColor;
      border: 0.5px solid @lineBorderColor;
      .on-lg-down({
        width: 100%
      });
    }

    .tabs {
      display: flex;
      height: 30px;
      border-bottom: 0.5px solid @lineBorderColor;
      margin-bottom: 10px;

      .tab {
        width: 50%;
        text-align: center;
      }

      .tab.active {
        color: @primaryLinkColor;
      }
    }

    .item {
      height: 30px;
      padding-left: 10px;
    }

    .item.selected {
      color: @primaryLinkColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;