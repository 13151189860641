@import 'styles/variables.less';
@import 'styles/typography.less';

.errorBoundaryWrapper {
  margin-top: 15%;
  margin-bottom: 10%;
  margin-left: -10%;
  .detailsWrapper {
    display: table;
    margin: 0 auto;
  }
  .header {
    .overlay-text-button();
    padding: 15px 0 11px 0;
    line-height: 24px;
    .detailsWrapper();
  }
  .subHeader {
    .text-logo-uploader();
    line-height: 20px;
    color: @primaryFontColor;
    text-align: center;
    .detailsWrapper();
  }
}
.errorBoundaryWrapper.noLeftMargin {
  margin-left: 0px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;