@import 'styles/variables.less';
@import 'styles/typography.less';

.loginWrapper {
  position: fixed;
  left: 50%;
  top: 30%;
  width: 400px;
  transform: translate(-50%, -50%);

  .loginHeader {
    .login-header();
    text-align: center;
  }

  .passwordField {
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 5px;
  }

  .invalidCredentials {
    color: @invalidInputColor;
    margin-bottom: 20px;
  }

  .passwordField.invalid {
    border: 1px solid @invalidInputColor;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;