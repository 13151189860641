@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.showAddTextBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    height: unset;
  }
  :global {
    .overlayWrapper {
      border-radius: 4px;
    }
  }
}

.planContentCollapse {
  :global {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 12px 20px;
      }
    }
  }
}

.hotSpot,
.tabPane {
  height: 100%;
}

.tabPanel {
  height: 100%;
  :global {
    .ant-tabs-nav {
      margin: 14px 35px 0px 38px;
    }

    .ant-tabs-content {
      height: 100%;
    }
  }
}

.content {
  padding: 20px 35px 10px 38px;
  .on-lg-down({
    padding: 0 10px;
  });
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;