@import 'styles/variables.less';
@import 'styles/typography.less';

.wrapper {
  .toolbar-header-button-text();
  background-color: inherit;
  border: none;
  border-radius: 4px;
  color: @white;
  height: 40px;

  &:hover,
  &:focus {
    background-color: lighten(@appHeaderBackgroundColor, 5);
    color: @white;
    :global {
      svg {
        path {
          stroke: @white;
        }
      }
    }
  }
  &:disabled,
  &:disabled:hover {
    background-color: @appHeaderBackgroundColor;
    color: @editableHotspotBorderColorInactive;
    :global {
      svg {
        path {
          stroke: @editableHotspotBorderColorInactive;
        }
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;