@import 'styles/variables.less';

.aiIndicatorIcon {
  margin-right: 10px;
}

.planSelectWrapper {
  margin-top: 20px;

  .formLabel {
    font-size: 14px;
    color: @defaultFontColor;
    font-weight: 600;
  }

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      border-radius: 4px;
      border: 1px solid @inputBorderColor;
      line-height: 40px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      line-height: 40px;
    }
  }
}

.optingGroupLabel {
  color: @assistantCardTitleColor;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 38px;
  text-transform: uppercase;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;