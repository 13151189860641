@import 'styles/variables';
@import 'styles/media';
@import 'styles/typography';

.contactSupportWrapper {
  width: 100%;
  border-radius: 6px;
  .on-lg-down({
    width: 96%
  });
  .contactSupportBtnWrapper {
    width: 100%;
    margin-bottom: 25px;
  }
  .contactSupportButton {
    padding: 4px 5px 4px;
    width: 150px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .on-lg-down({
      width: 100%
    });
    .previewContactIcon {
      margin: 0px 6px 2px 5px;

      :global {
        .contactMessage {
          stroke: var(--color-theme);
        }
      }
    }
    .supportButton {
      .dbg-side-bar-support();
      margin-left: 3px;
    }
    .previewSupport {
      color: var(--color-theme);
    }

    .editIcon {
      margin-bottom: 5px;
    }
  }
  .previewContactSupport {
    border: 1px solid var(--color-theme);
    border-radius: 7px;
  }
  :global {
    .editableHotspotWrapper {
      .overlayWrapper {
        border-radius: 7px;
      }
    }
  }
}

.previewBtn {
  .dbg-content-preview();
  margin-top: 0px;
}

.supportRow {
  margin-top: 25px;
  &.hidden {
    display: none;
  }
}

.actionRow {
  margin-top: 15px;
  .actionBtnCol {
    margin-left: 48%;
  }
  .actionCancel {
    width: 150px;
  }
  .done {
    margin-left: 10px;
    width: 150px;
    background-color: @defaultFontColor;
  }
  .doneBtn {
    color: @white;
  }
}

.supportModalWrapper {
  :global {
    .ant-modal-content {
      width: 900px;
      margin-left: -25%;
    }
    .ant-select {
      width: 200px;
      margin-left: 10px;
      margin-top: -5px;
    }
    .ant-switch-checked {
      background-color: @primaryLinkColor;
    }
  }
  .textEditor {
    :global {
      .tox-edit-area__iframe {
        background-color: @mdvCardBorder;
      }
    }
  }
}

.previewWrapper {
  :global {
    .ant-modal-content {
      margin-left: auto;
      margin-right: auto;
      width: 464px;
      .on-sm-down({
        width: auto;
      });
    }
    .ant-modal-title {
      text-align: center;
      flex: auto;
      .text-page-title();
    }
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
      padding-top: 24px;
      .on-sm-down({
        padding-top: 32px;
      });
    }
    .ant-modal-close-x {
      .on-sm-down({
        height: 40px;
        line-height: 40px;
        width: 40px;
      });
    }
    // .ant-modal-close {
    //   z-index: -1; // TODO: Commented out because need to check if close icon is needed or not
    // }
  }

  &.widget {
    :global {
      .ant-modal-content {
        width: 663px;
        .on-sm-down({
          width: auto;
      });
      }
    }
  }

  .preview {
    margin-top: 3px;
    font-size: 12px;
    margin-left: 8px;
    margin-bottom: 20px;
    .on-sm-down({
      margin-top: 0;
      margin-bottom: 0;
    });
  }

  .scheduleLink {
    display: flex;
  }
}

.subOverlay {
  .text-button();
}

.overlay {
  .overlay-text-button();
}
.scheduleConsultContainer {
  text-indent: 10px;
  display: inline-flex;
  flex-direction: column;

  .lblScheduleConsult {
    font-weight: 600;
    font-size: 14px;
    color: @lblScheduleConsultationColor;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      color: @primaryLinkDisabledColor;
    }
  }

  .cursorDisabled {
    cursor: not-allowed;
  }

  .lblSubScheduleConsult {
    font-weight: 400;
    font-size: 10px;
    color: @lblSubScheduleConsultationColor;
  }
}

.switch {
  margin-left: 10px;
}

.modalCloseBtn {
  :global {
    .ant-btn {
      width: 100%;
    }
  }
}
.closeBtnBlack {
  height: 48px;
  width: 400px;
  border-radius: 4px;
  color: @primaryButtonColor;
  background-color: black;
  .text-confirmation-button();

  &:hover {
    color: @primaryButtonColor;
    background-color: black;
    .text-confirmation-button();
  }
  &:focus {
    color: @primaryButtonColor;
    background-color: black;
    .text-confirmation-button();
  }
}

.closeBtn {
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;