@import 'styles/variables.less';
@import 'styles/typography.less';

.missingOuterWrapper {
  height: 29px;
  padding-left: 16px;
  .missingInfoWrapper {
    width: 120px;
    height: 24px;
    border: 2px solid @editableHotspotBorderColor;
    border-radius: 3px;
    .missingTextWrapper {
      padding-left: 10px;
      margin-top: -5px;
      .missingText {
        .missing-info-text();
        padding-left: 5px;
      }
      .iconWarning {
        width: 18px;
        height: 17px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;