@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.providerSearchWrapper {
  :global {
    .ant-modal-close {
      padding-right: 10px;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 34px;
    }
  }
  .providerHeader {
    .text-page-title();
    text-align: center;
  }
  .logoWrapper {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    .logo {
      width: 90px;
      height: 25px;
    }
  }
  .searchHeader {
    margin-top: 25px;
    margin-bottom: 2px;
    .section-title();
  }
  .instructionWrapper {
    margin-top: 30px;
    border-top: 1px solid @sectionDivider;
    .urlSectionSeparator {
      margin-bottom: 30px;
    }
    .boldTextWrapper {
      .boldText();
      display: inline;
    }
    .homeUrl {
      padding-left: 5px;
    }
    a &:hover {
      color: @primaryLinkColor;
      text-decoration: underline;
    }
  }
  .additionalInfo {
    margin-bottom: 20px;
    .instructionList {
      padding-left: 8px;
      white-space: pre-wrap;
    }
  }
  .buttonWrapper {
    margin-top: 15px;
    .searchButton {
      height: 48px;
      width: 100%;
      border-radius: 4px;
      .text-button();
      .on-lg-down({
        width: 100%;
      });
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;