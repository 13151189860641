@import 'styles/variables';
@import 'styles/media.less';

.planRecommenderHeader {
  width: 98%;
  max-width: 1100px;
  height: 80px;
  border-bottom: 1px solid #e6e7e8;
  padding-right: unset;
  padding-left: unset;
  background-color: @defaultBackgroundColor;
  position: fixed;
  z-index: 1;
  .on-sm-down({
    z-index: 200;
  });

  .headerTop {
    width: 100%;
    .backArrow {
      float: left;
      padding-top: 30px;
      min-width: 100px;
      width: 50px;
      height: 60px;
      .on-lg-down({
        margin-left: 32px;
      });
      .on-sm-down({
        margin-left: 32px;
      });

      img {
        cursor: pointer;
      }
    }

    .centerLogo {
      visibility: hidden;
      text-align: center;
      padding-top: 15px;
      img {
        height: 50px;
        transform: translateX(-25%);
      }
    }

    .completePercentage {
      float: right;
      font-weight: bold;
      margin-top: -30px;
      height: 30px;
      width: 287px;
      color: #2a3233;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 17px;
      text-align: right;
      .on-xsm-down({
        margin-top: -30px;
        });
    }
  }

  .headerBottom {
    .indicator {
      width: 0;
      height: 3px;
      background-color: var(--color-theme);
      margin-top: 12px;
      max-width: 100%;
    }
  }
}

.centerPlanRecIcon {
  display: none;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;