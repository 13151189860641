@import 'styles/variables';
@import 'styles/media';
@import 'styles/typography';

@mobile-header-height: 60px;

.containerTopBar {
  width: 100vw;
  border-top: 5px solid var(--color-theme);
  position: fixed;
  z-index: 100;
}

.layoutMask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 21;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.collapseHeader {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: @white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
  z-index: 20;
  box-shadow: 0px 4px 14px rgba(183, 183, 183, 0.25);
  .headerLogo {
    width: 160px;
    .on-sm-down({
      width: 120px
    });

    opacity: 1;
    transition: opacity @layoutTransitionTime;
    &.headerMobileCollapsed {
      opacity: 0;
    }
  }
}

.logoContainer {
  width: @appSideNavWidth;
  position: fixed;
  top: @appHeaderHeight;

  &.view {
    top: 5px;
  }
}

.sideNav {
  padding-top: 18px;
  margin-top: 63px;
  background-color: @sideMenuBackgroundColor;
  border-top: 1px solid @sectionDivider;

  .buttonContainer {
    margin-top: 34px;
  }

  &.prevSideNav {
    margin-top: 33px;
    .on-lg-down({
      margin-top: 0;
    });
  }

  &.siderBroken {
    width: 50%;
    position: fixed;
    top: 0;
    right: 0;
    left: unset;
    height: 100vh;
    :global(.ant-menu-root) {
      .closeBtnContainer {
        height: @mobile-header-height;
      }
    }
  }

  :global {
    .ant-layout-sider {
      position: fixed;
      z-index: 100;
      max-height: calc(100vh - 12%);
      padding-bottom: 135px;
      overflow-y: overlay;
      overflow-x: hidden;
      scrollbar-width: thin;

      .on-lg-down({
        height: 100%;
        max-height: 100%
      });
    }
  }

  &.mobile {
    margin-top: @mobile-header-height;
    z-index: 21;
    transition: all @layoutTransitionTime;
    height: calc(100% - @mobile-header-height);
    .contactSupport {
      transition: all @layoutTransitionTime;
    }

    :global {
      .ant-menu-root {
        transition: all @layoutTransitionTime;
        .ant-menu-item {
          height: 46px;
          transition: height @layoutTransitionTime;
        }
      }
    }
  }
  &.mobileHidden {
    height: 0;
    z-index: -1;

    .contactSupport {
      height: 0;
    }

    :global {
      .ant-menu {
        height: 0;
        .ant-menu-item {
          height: 0;
        }
      }
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    .closeBtnContainer {
      display: flex;
      justify-content: flex-end;
    }
  }

  .closeBtnContainer {
    position: sticky;
    z-index: 22;
    top: 0;
    display: flex;
    align-items: center;
    background-color: @white;
    width: 100%;
    height: 64px;
    span {
      display: flex;
      justify-content: flex-end;
    }

    .drawerCloseBtn {
      margin-left: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(151, 151, 151, 0.199);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  :global(.ant-layout-sider.ant-layout-sider-dark) {
    background-color: @sideMenuBackgroundColor;
  }

  :global {
    .ant-layout-sider-children {
      width: 100%;
      .on-lg-down({
        height: 95%;
        overflow-y: auto;
        overflow-x: hidden;
      });
      .on-sm-down({
        height: 90%;
      });
    }
    .ant-menu {
      width: auto;
      border-right: none;
      .ant-menu-item {
        display: flex;
        align-items: center;
        height: 46px;
        background-color: @sideMenuBackgroundColor;
        margin: 0;
        margin-bottom: 0;
        padding-left: 25px;
        .ant-menu-title-content {
          margin-top: 2px;
        }
        a {
          text-decoration: none;
        }
        &:hover {
          background-color: @defaultBackgroundColor;
        }
      }
      .ant-menu-item-selected {
        font-weight: 700;
        border-right: 4px solid @sideMenuSelectedColor;
        background-color: @defaultBackgroundColor;
        a {
          color: @sideMenuSelectedColor;
        }
      }
      .ant-menu-submenu-title {
        margin: 0;
        height: 46px;
      }
      .ant-menu-submenu-title:active {
        background-color: @sideMenuBackgroundColor;
      }
      .ant-menu-submenu-title:hover {
        background-color: @defaultBackgroundColor;
      }
    }
    .ant-menu-inline .ant-menu-item::after {
      border-right: none;
    }
    //caret arrow rotation and position
    .ant-menu-submenu-inline .ant-menu-submenu-arrow {
      right: 13.02px;
      color: @navigationArrow;
      .on-lg-down({
        right: 190px;
      });
      .on-sm-down({
        right: 165px;
      });
      &::before {
        transform: rotate(45deg) translateY(-2.5px);
      }
      &::after {
        transform: rotate(-45deg) translateY(2.5px);
      }
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      &::before {
        transform: rotate(-50deg) translateX(0.1px) translateY(1.5px);
      }
      &::after {
        transform: rotate(45deg) translateX(-0.7px) translateY(2.5px);
      }
    }
    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title {
      font-weight: 700;
      border-right: 4px solid @sideMenuSelectedColor;
      background-color: @defaultBackgroundColor;
      a {
        color: @sideMenuSelectedColor;
      }
    }
  }
}

.mobileHidden {
  height: 0;
}

.siderBroken {
  z-index: 21;
}
.appBasicGuideContent {
  background-color: @defaultBackgroundColor;
}
.appContent,
.appPrevContent {
  background-color: @defaultBackgroundColor;
  margin-left: @appSideNavWidth;
  margin-top: @appHeaderHeight;
}

.editableHotspotTrigger {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.contactSupport {
  position: fixed;
  bottom: 10px;
  padding: 10px 25px 0px;
  background: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  gap: 10px;

  .aiAssistantBtnWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .on-lg-down({
      width: 96%
    });

    .aiAssistantBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 5px 4px;
      width: 150px;
      height: 32px;
      border-radius: 7px;
      border-color: var(--color-theme);
      color: var(--color-theme);
      .text-submit-button();
      .on-lg-down({
        width: 100%
      });

      .aiAssistantIcon {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }

  .viewIdBtnWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-bottom: 12px;
    .on-lg-down({
    width: 96%
  });

    .viewIdIcon {
      margin: 0px 14px 2px 5px;
    }
    .viewIdIconEdit {
      color: @black;
    }
    .viewIdBtnEdit {
      background: @white;
    }

    .viewIdButton {
      padding: 4px 5px 4px;
      width: 150px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-theme);
      border-radius: 7px;
      .on-lg-down({
      width: 100%
    });

      .viewIdBtn {
        .dbg-side-bar-support();
        margin-left: 3px;
        color: @white;
      }
    }
  }

  .on-lg-down({
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
  });
  .on-sm-down({
    width: 100%;
    padding: 0px 30px;
  });
}

.appPlanRecommenderContent {
  background-color: @defaultBackgroundColor;
}
.appPrevContent {
  background-color: @defaultBackgroundColor;
  margin-left: @appSideNavWidth;
  margin-top: 0px;
}

.contentMobileTablet {
  background-color: @defaultBackgroundColor;
  margin-left: 0;
}

.badge {
  margin-left: 5px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;