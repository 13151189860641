@import 'styles/variables.less';
@import 'styles/typography.less';

.overlay {
  width: 425px;
  :global {
    .ant-popover-content {
      position: relative;
      top: 13px;
    }
    .ant-popover-inner {
      border: 1px solid @black;
      box-sizing: border-box;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .ant-popover-inner-content {
      padding: 26px 22px;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  .icon {
    height: 24px;
    min-width: 24px;
  }
  .content {
    margin-left: 15px;
  }
  .header {
    .text-banner-header();
    margin-bottom: 5px;
  }
  .description {
    .text-banner-description();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;