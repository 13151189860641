@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.container {
  min-height: 100vh;
  background-color: @basicInfoBackgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    padding: 35px 85px;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 100vh;
    background-color: @white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 65px;
    .on-xxl-up({
      width: 70%;
    });
  }

  .alert {
    position: absolute;
    top: -42px;
  }
}

.infoBanner {
  height: 150px;
}

.formContainer {
  margin-top: 60px;
  .sectionHeader {
    line-height: 40px;
    .text-section-header();
  }
  .section {
    padding-bottom: 50px;
  }
  .benefitTypeItem {
    :global {
      .ant-form-item-explain {
        position: relative;
      }
      .ant-form-item-explain-error {
        position: absolute;
        top: 10px;
      }
    }
  }
  .guideName {
    :global(.ant-form-item-explain-error) {
      float: right;
    }
  }
}

.popoverIcon {
  position: relative;
  top: 4px;
  margin-left: 5px;
}

.popoverContent {
  a {
    color: @primaryLinkColor;
  }
}

.formSection {
  width: 100%;
}

.formActions {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  :global {
    .ant-btn {
      width: 150px;
      height: 40px;
      border-radius: 4px;
      .text-button();
    }
  }
  .saveBtn {
    margin-left: 12px;
  }
}

.nhcUpdateText {
  margin-bottom: -5px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;