@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.fourOOneKCardWrapper {
  position: relative;
  background-color: @white;
  border: 1px solid @mdvCardBorder;
  border-radius: 8px;
  display: flex;
  margin-top: 45px;
  .on-lg-down({
    flex-direction: column;
    margin-top: 20px;
  });
  .summaryWrapper {
    width: clamp(250px, 30%, 350px);
    border-right: 1px solid @defaultLightBorderColor;
    flex-shrink: 0;
    .on-lg-down({
      width: 100%
    });

    :global(.editableHotspotWrapper) {
      height: 100%;
    }
  }

  .detailsHeader {
    margin-bottom: 10px;
    .on-lg-down ({
      margin-left: -20px;
    });;
    .on-sm-down ({
      margin-left: -10px;
    });;
  }

  .contributionFields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div:not(.disclaimer) {
      .on-lg-down({
        width: 50%
      });
    }
    .infoField {
      .label {
        .on-lg-down({
          font-size: 14px;
        });
      }
      .value {
        .on-lg-down({
          color: @costTextColor;
          font-size: 20px;
        });
      }
    }
  }

  .summary {
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: nowrap;
    padding: 35px;
    .on-lg-down({
      padding: 20px 35px 10px
    });
    .on-sm-down({
      padding: 15px 25px 5px
    });

    .carrierLogo {
      margin-bottom: 5px;
      img {
        height: 20px;
      }
    }
    .planName {
      border-bottom: 0.75px dashed @borderColor;
      margin-bottom: 18px;
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 230px;
      .plan-name();
    }
    .detailsLink {
      margin-top: 20px;
    }

    .infoField {
      margin-bottom: 8px;
    }
    .label {
      font-size: 11px;
      color: @planCardTextColor;
    }
    .value {
      font-size: 15px;
      font-weight: bold;
      height: 24px;
    }

    .documentLink {
      font-size: 13px;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  .tabsArea {
    color: @planCardTextColor;
    flex-direction: column;
    flex-grow: 1;
    .tabPanel {
      height: 100%;
      :global {
        .ant-tabs-nav {
          margin: 14px 35px 0px 38px;
        }

        .ant-tabs-content {
          height: 100%;
        }
      }
    }
    .richMediaHotSpot,
    .tabPane {
      height: 100%;
    }

    .content {
      padding: 20px 35px 10px 38px;
      word-break: break-word;
    }

    .addTextWrapper {
      position: relative;
      background-color: @white;
      mix-blend-mode: normal;
      opacity: 0.7;
      border: 1px solid @defaultLightBorderColor;
      box-sizing: border-box;
      border-left: none;
      border-radius: 0px 8px 8px 0px;
      width: 100%;
      .addTextHotspot {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 250%);
      }
    }
  }
}

.viewDetailsLink {
  width: 230px;
  color: @primaryLinkColor;
  padding-top: 10px;
  cursor: pointer;
  .plan-view-details();
  border-top: 1px solid @defaultLightBorderColor;
  .on-lg-down({
    width: auto;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  });
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;