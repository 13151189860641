@import 'styles/variables.less';
@import 'styles/typography.less';

.tabsWrapper {
  :global {
    .ant-tabs-nav::before {
      border-bottom: 1px dashed @defaultLightBorderColor;
    }

    .ant-tabs-ink-bar {
      background: var(--color-theme);
      min-height: 3px;
      max-height: 3px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;