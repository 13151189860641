@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.resourceCardWrapper {
  margin-top: 28px;
  .on-sm-down({
    margin-top: 0;
  });

  .resourceCard {
    min-height: 90px;
    border-radius: 6px 6px;
    background-color: @white;
    .planColumn {
      min-height: 66px;
      width: 50%;
      display: inline-grid;
      .on-sm-down({
        width: 100%
      });

      .planName {
        .text-submit-button();
        margin-bottom: 5px;
        color: @defaultFontColor;
        padding-top: 10px;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .on-xxl-up( {
          width: 340px;
        });
      }
      .planResourceWrapper {
        padding-bottom: 10px;
        .planDocLinks {
          text-decoration: none;
        }
        .viewResourceLink {
          .text-banner-description();
          color: @resourceLinkColor;
          cursor: pointer;
        }
      }
      :global {
        .overlayWrapper {
          width: 90%;
          margin-left: -28px;
        }
      }
    }
  }
}

.resCardFooter {
  margin: 40px 0 20px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;