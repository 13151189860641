.on-xs-down(@rules) {
  @media only screen and (max-width: 576px) {
    @rules();
  }
}

.on-sm-down(@rules) {
  @media only screen and (max-width: 576px) {
    @rules();
  }
}

.on-md-down(@rules) {
  @media only screen and (max-width: 768px) {
    @rules();
  }
}

.on-lg-down(@rules) {
  @media only screen and (max-width: 1025px) {
    @rules();
  }
}

.on-xl-down(@rules) {
  @media only screen and (max-width: 1200px) {
    @rules();
  }
}

.on-xxl-down(@rules) {
  @media only screen and (max-width: 1600px) {
    @rules();
  }
}

.on-sm-up(@rules) {
  @media only screen and (min-width: 576px) {
    @rules();
  }
}

.on-md-up(@rules) {
  @media only screen and (min-width: 768px) {
    @rules();
  }
}

.on-lg-up(@rules) {
  @media only screen and (min-width: 1025px) {
    @rules();
  }
}

.on-xl-up(@rules) {
  @media only screen and (min-width: 1200px) {
    @rules();
  }
}

.on-xxl-up(@rules) {
  @media only screen and (min-width: 1600px) {
    @rules();
  }
}

.on-xsm-down(@rules) {
  @media only screen and (max-width: 400px) {
    @rules();
  }
}

.on-xxsm-down(@rules) {
  @media only screen and (max-width: 360px) {
    @rules();
  }
}
