@import 'styles/variables.less';
@import 'styles/typography.less';

.planDocumentsWrapper {
  margin-top: 30px;
  .docType {
    .text-submit-button();
    margin-top: 11px;
  }

  :global {
    .ant-row {
      margin-bottom: 27px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;