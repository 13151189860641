@import 'styles/typography.less';
@import 'styles/variables.less';

.button {
  width: 400px;
  border-radius: 4px;
  height: 42px;
  .text-button();
  &:global(.ant-btn-link) {
    color: @primaryLinkColor;
  }

  &:not(:global(.ant-btn-primary)):not(:global(.ant-btn-ghost)):not(:global(.ant-btn-link)) {
    border-color: @black;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;