@import 'styles/variables';
@import 'styles/typography';

.planDocumentText {
  font-size: 16px;
  font-weight: bold;
  white-space: break-spaces;
  color: @black;
  position: relative;
  top: 2px;
  margin-left: 2px;
  margin-right: 2px;
}

.addAdditionalResource {
  margin-left: -15px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;