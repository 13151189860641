@import 'styles/variables.less';
@import 'styles/media';

.planRecommenderPlansWrapper {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 100px;
  background: @defaultBackgroundColor;
  .on-sm-down({
    margin-left: 32px;
    margin-right: 32px;
  });

  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title {
      margin-top: 10px;
      margin-bottom: 20px;
      height: auto;
      color: @defaultFontColor;
      font-size: 26px;
      font-weight: bold;
      line-height: 1.2;
      word-wrap: break-word;
      text-align: center;
      .on-sm-down({
        margin-bottom: 100px;
        
      });
    }

    .subTitle {
      width: 80%;
      max-width: 704px;
      margin: 0 auto 20px auto;
      color: @defaultFontColor;
      font-size: 15px;
      line-height: 1.5;
      word-wrap: break-word;
      text-align: center;
      .on-sm-down({
        width: 100%;
      });

      .editLink {
        background: var(--color-theme);
        height: 21px;
        border-radius: 4px;
        color: white;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
        cursor: pointer;
        padding: 1px;
        padding: 1px 5px;
        font-weight: 600;
      }
    }
  }

  .plansWrapper {
    width: 100%;
    margin-top: 50px;
    display: inline-flex;
    margin-bottom: 120px;
    .on-lg-down({
      padding-left: 20px;
    });
    .on-sm-down({
      padding-left: 0px;
    });
    .plansTitleWrapper {
      width: 154px;
      height: 100%;
      padding-top: 167px;

      .titleField {
        height: 28px;
        text-align: left;
        font-weight: bold;
        font-size: 12px;
        padding-top: 5px;
      }

      .titleField.hsa {
        padding-top: 5px;
      }

      .monthlyPremium {
        height: 70px;
      }

      .annualCost {
        height: 185px;
        padding-top: 55px;
      }

      .annualCostBreakdown {
        height: 100px;
        padding-top: 50px;
      }
    }

    .plansDetailsWrapper {
      width: calc(252px * 3);

      .planCardsContainer {
        width: 100%;
        display: inline-flex;
        height: 100%;
        overflow: hidden;

        .planEmpty {
          display: none;
        }
      }

      .breakdownWrapper {
        border-top: 1px solid #e6e7e8;
        border-bottom: 1px solid #e6e7e8;
        padding-top: 25px;
        margin-top: -90px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        position: absolute;
      }

      .planEmptyMsgWrapper {
        display: none;
      }
    }

    .plansMoreWrapper {
      height: 100%;
      width: calc(100% - 154px - 252px * 3);
      padding-top: 260px;
      color: #3970b0;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      text-decoration: underline;
    }
    .morePlansPointer {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .footerLink {
    margin-top: 30px;
    margin-bottom: 8px;
    button {
      width: 240px;
    }

    .downloadResultButton {
      background-color: @black;
    }
    a.scheduleMeetingLinkBtn {
      color: #3970b0;
      text-decoration: underline;
    }
    a.scheduleMeetingLinkBtn:hover {
      color: #3970b0;
      text-decoration: underline;
    }
  }
}

.planRecommenderPlansWrapperEmpty {
  .downloadResultButton {
    display: none;
  }
  .plansWrapper {
    margin-bottom: 40px;
    position: relative;
    .plansDetailsWrapper {
      .planCardsContainer {
        .plan {
          background: url(/images/plan-recommender/card-blank.png);
          background-repeat: no-repeat;
          padding-top: 10px;
          background-size: auto 530px;
          opacity: 1;
          pointer-events: none;
          * {
            visibility: hidden;
          }
        }
        .planEmpty {
          display: block;
        }
      }

      .breakdownWrapper {
        display: none;
      }
      .planEmptyMsgWrapper {
        position: absolute;
        margin-top: 100px;
        text-align: center;
        border: #e1464e 1px solid;
        background-color: #fff5f6;
        height: 134px;
        padding: 25px;
        z-index: 1;
        left: 196px;
        display: block;
        .on-lg-down({
          left: 0px;
          width: 90%;
          margin-right: 0;
          margin-left: 5%;
        });
        .on-sm-down({
          left: 0px;
          height: 170px;
          margin-top: 0px;
        });
        .on-xsm-down({
          left: 0px;
          height: 170px;
          margin-top: 0px;
          margin-right: 0;
          margin-left: 0;
          width: 300px;
        });

        button {
          margin-top: 20px;
          .on-sm-down({
            width: 157px;
            height: 41px;
            border-radius: 7px;
          });
        }
      }
    }

    .plansTitleWrapper {
      display: none;
      .annualCostBreakdown {
        display: none;
      }
    }
  }

  .footerLink {
    & button {
      pointer-events: none;
      background-color: #d6d8d9;
      color: white;
    }
    & a {
      pointer-events: none;
      color: #d6d8d9;
    }
  }
}
.planRecommenderLoaderOverlay {
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  &.show {
    display: flex;
  }
  &.hidden {
    display: none;
  }

  .loader {
    color: var(--color-theme);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;