@import 'styles/typography.less';
@import 'styles/media.less';

.container > div {
  margin-bottom: 15px;
  .on-lg-down({
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  });
}
.container > div:not(.empContribution):not(.frequency) {
  .on-lg-down({
    width: 49%

    });
}
.container {
  .on-lg-down({
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
  });
}
.frequency {
  width: 100%;
  word-break: break-all;
  width: 100%;
  .plan-card-left-panel-detail-value();
}

.empContribution {
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;