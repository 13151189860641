@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.container {
  position: relative;
  margin-top: 45px;
  padding-right: 60px;
  .on-sm-down({
    margin-top: 30px;
  });
}

.hotSpot {
  width: 100%;
  height: 100%;
}

.summaryWrapper {
  width: clamp(250px, 30%, 350px);
  border-right: 1px solid @defaultLightBorderColor;
  flex-shrink: 0;
  .on-lg-down({
    width: auto;
    border-right: none;
  });
}

.summary {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding: 35px;
  .on-lg-down({
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid @defaultLightBorderColor;
    padding: 20px;
  });

  .carrierLogo {
    margin-bottom: 5px;
    img {
      height: 20px;
    }
  }
  .planName {
    border-bottom: 0.75px dashed @borderColor;
    .plan-name();
  }
  .detailsLink {
    margin-top: 20px;
    .on-lg-down({
      margin-top: 0;
      min-width: 90px;
    });
  }
}

.detailsSection {
  color: @planCardTextColor;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .content {
    padding: 35px 70px 17px 35px;
    word-break: break-word;
    .on-lg-down({
      padding: 20px 20px 10px;
    });
  }
}

.detailLabel {
  font-weight: bold;
  margin-bottom: 8px;
  color: @defaultFontColor;
  .on-sm-down({
    margin-bottom: 0px;
  });
}

.planDetails {
  padding: 17px 15px 40px 35px;
  display: flex;
  justify-content: space-around;
  .on-lg-down({
    padding: 20px;
  });
  .on-sm-down({
    padding-top: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  });
}

.detailSection {
  margin-right: auto;
  .on-sm-down({
    width: 45%;
    margin-bottom: 10px;
    margin-right: 0;
  });
}

.volPlanDetailsTable {
  padding: 17px 70px 40px 35px;
  :global {
    .ant-row:first-child {
      border-bottom: 1px solid @defaultLightBorderColor;
      font-weight: bold;
      color: @defaultFontColor;
      .ant-col {
        padding-bottom: 14px;
      }
      .ant-col:first-child {
        border-right: none;
      }
    }
    .ant-col:first-child {
      border-right: 1px solid @defaultLightBorderColor;
      font-weight: bold;
      color: @defaultFontColor;
    }
    .ant-row:not(:first-child) {
      .ant-col {
        padding: 20px 0px 20px;
      }
    }
  }
}

.ltdMaxDuration {
  max-width: 30%;
  .on-sm-down({
    max-width: unset;
  });
}

.volRowHeader {
  font-weight: bold;
  color: @defaultFontColor;
}
.volColHeader {
  color: @subHeaderColor;
}

.volPlanDetailsMobile {
  padding: 0 20px 20px;

  .benefitSection {
    padding-top: 5px;
    border-top: 1px solid @defaultLightBorderColor;
  }

  :global {
    .ant-row {
      padding-bottom: 12px;
    }
  }
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;