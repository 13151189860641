@import 'styles/variables.less';
@import 'styles/typography.less';

.appHeader {
  display: flex;
  color: @white;
  background-color: @appHeaderBackgroundColor;
  height: @appHeaderHeight;
  z-index: 200;
  border: 1px solid @appHeaderBorderColor;
  padding: 0 10px 0 20px;
  justify-content: flex-end;
  position: fixed;
  width: 100%;

  &.displayBottomBar {
    border-bottom: 5px solid var(--color-theme);
  }

  :global {
    .ant-menu-horizontal {
      border-bottom: none;
    }
  }

  .backSection {
    position: relative;
    width: 215px;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 17px;
      height: 30px;
      width: 50%;
      border-right: 1px solid @appHeaderBorderColor;
    }
  }

  .navigation {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-right: auto;
  }

  .statusIndicator {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 30px;
  }

  .headerActions {
    position: relative;
    width: auto;
    padding-left: 35px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 17px;
      height: 30px;
      border-right: 1px solid @appHeaderBorderColor;
    }

    .publishButton {
      margin-left: 10px;
      .greyButton();
    }
  }
}
.btnIcon {
  height: 10px;
  margin-right: 13px;
}

.greyButton {
  .text-button();
  border-radius: 4px;
}
.nextIcon {
  height: 10px;
  margin-left: 13px;
  transform: rotate(180deg);
}

.navMenu {
  padding: 0px 10px;
}

.nextButtonDivider {
  height: 30px;
  border-left: 1px solid @appHeaderBorderColor;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;