@import 'styles/typography';
@import 'styles/variables';

.inputWrapper {
  height: 45px !important;
}

.formInput {
  max-width: 400px;
}

.wrapperContainer {
  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      content: unset;
    }
  }
}

.submitPwdButton {
  margin-top: 20px;
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

.resetPwdButton {
  margin-top: 4px;
  padding: 0;
  text-align: center;
  height: 48px;
  .text-link-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;