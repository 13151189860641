@import 'styles/variables.less';

.chatBubbleWrapper {
  padding: 14px 40px 14px 50px;

  .userIcon {
    width: 26px;
    height: 26px;
  }

  .aiAssistantIcon {
    width: 26px;
    height: 26px;
  }

  .textBody {
    text-wrap: wrap;
  }
}

.chatBubbleQuestion {
  background-color: @assistantQuestionBackgroundColor;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;