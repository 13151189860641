@import 'styles/variables.less';

.renamedText {
  color: @optionalTextColor;
  font-size: 14px;
  font-style: italic;
}

.popoverTitle {
  font-weight: 600;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;