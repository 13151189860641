@import 'styles/variables';
@import 'styles/typography';
@import 'styles/media';

.uploadWrapper {
  display: flex;
}

.nonVisibleUploader {
  display: none;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 450px;
  background-color: @imgCropperBackgroundColor;
  .uploadNewContainer {
    cursor: pointer;
    background: @defaultBackgroundColor;
    height: 100%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    .uploadNewImgText {
      margin-top: 17px;
    }
    .uploadNewInner {
      display: flex;
      flex-direction: column;
      img {
        height: 23px;
      }
    }
    :global {
      .ant-upload,
      .ant-upload-select {
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}

.uploadButton {
  margin-top: 8px;
  color: @primaryLinkColor;
  font-weight: 400;
}
.title {
  .center();
  .text-page-title();
}
.center {
  text-align: center;
  flex: auto;
}
.cropperHelperText {
  margin-top: 12px;
  color: @defaultFontColor;
  .text-logo-uploader();
}
.descriptionContainer {
  width: 450px;
  margin: auto;
}

.saveControl {
  text-align: center;
  flex: auto;

  height: 48px;
  .text-button();
}
.saveButton {
  .saveControl();
  width: 93%;
}

.smallSaveBtn {
  .saveControl();
  width: 86%;
}

.controls {
  display: flex;
  align-items: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    margin-bottom: -8px;
  }

  button + div:only-of-type {
    flex: 1;
    margin: 0 8px;
  }
}

.operations {
  margin-left: 15px;
  .operationControls();
}

.operationsHandle {
  margin-left: 27px;
  .operationControls();
}

.operationControls {
  display: flex;
  .removeBtn {
    .text-button();
    span {
      color: @warningTextColor;
    }
  }
  .uploadNewContainer {
    display: flex;
    align-items: center;
    .uploadNewBtn {
      .text-button();
      span {
        color: @primaryLinkColor;
      }
    }
  }
  .uploadNewImgWrapper {
    margin-left: 2%;
    margin-bottom: 13px;
  }
  .resetImgWrapper {
    margin-left: 12px;
    margin-bottom: 13px;
  }
  .actionControl {
    border-color: @black;
    height: 48px;
    border-radius: 4px;
    .text-submit-button();
  }
  .actionButton {
    .actionControl();
    width: 365px;
  }
  .smallActionBtn {
    .actionControl();
    width: 200px;
  }
}

.uploadBtnWrapper {
  text-align: center;
  color: @primaryLinkColor;
  float: left;

  .uploadBtnSquare {
    color: @primaryLinkColor;
    height: 80px;
    width: 124px;
    border-radius: 4px;
    border-color: @uploadBtnBorderColor;
    border-width: medium;
    .text-bold-logo-uploader();
  }

  .uploadBtnCircle {
    border-radius: 50%;
    color: @primaryLinkColor;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
    height: 5vw;
    border-color: @uploadBtnBorderColor;
    border-width: medium;
    .text-bold-logo-uploader();
  }
  text-align: center;
  color: @primaryLinkColor;
  float: left;

  .uploadBtn {
    color: @primaryLinkColor;
    height: 50px;
    width: 200px;
    border-radius: 4px;
    border-color: @uploadBtnBorderColor;
    .text-logo-uploader();
  }
}

.outline {
  color: @primaryLinkColor;
}

.imageStyleRect {
  width: 124px;
  height: 80px;
  border-radius: 4px;
}

.imageVerticalStreched {
  .on-xl-up( {
    width: 190px;
    height: 261px;
  });
  .on-xxl-up( {
    width: 256px;
    height: 353px;
  });
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
}

.logoWrapper {
  width: @appSideNavWidth;
  height: 80px;
  object-fit: contain;
}

.imageStyleCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.imgModalWrapper {
  :global {
    .ant-modal-content {
      border-radius: 10px;
      overflow: auto;
    }
    .ant-modal-body {
      background-color: @white;
      padding: 0px 32px 24px;
    }
    .ant-modal-header {
      border-bottom: none;
      padding: 40px 14px 0px;
    }
    .ant-modal-footer {
      padding: 0px 16px 30px;
      border-top: none;
    }
    .ant-typography {
      font-weight: 400;
    }
    .ant-modal-close {
      top: 30px;
      right: 10px;
    }
  }
}

.slider {
  color: @primaryLinkColor;
  :global {
    .ant-slider {
      .ant-slider-track {
        background-color: @primaryLinkColor;
      }
      .ant-slider-handle {
        border-color: @primaryLinkColor;
      }
      .ant-slider-handle:focus {
        border-color: @primaryLinkColor;
      }
    }
  }
}

.helpText {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-helper();
}

.primaryUploader {
  display: flex;
}

.alertMessageWrapper {
  margin-bottom: 20px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;