@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.importantDates {
  margin-top: 15px;
  :global {
    .on-xl-down({
    .ant-row {
      display: flex;
      flex-flow: inherit;
  }});
  }
  .on-lg-down({
    flex-flow: nowrap;
  });
}
.oeIcon {
  .on-sm-down({
    display: none;
  });
}
.effectiveDate {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 20px;
  .on-sm-down({
    margin-left: 0px;
    top: unset;
    left: unset;

  });
}
.eligibilitySections {
  margin-right: 5px;
  margin-top: 10px;
}
.effectiveLabel {
  line-height: 28px;
}
.planYear {
  line-height: 28px;
  font-weight: bold;
  font-size: 16px;
  color: @defaultFontColor;
}
.content {
  .on-lg-down({
    margin-top: 20px;
  });
}

.dateModalWrapper {
  :global {
    .ant-modal-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      color: @defaultFontColor;
    }
  }
}

.addContent {
  width: 150px;
  border-radius: 3px;
  border-color: @editableHotspotBorderColor;
  margin-top: 15px;
}
.addContentText {
  .dbg-side-bar-support();
}

.contentWrapper {
  :global {
    .ant-row {
      width: 100%;
    }

    .ant-row > * {
      flex: 1 1 10px;
    }
  }
}

.openEnrolmentDate {
  display: flex;
  :global {
    .ant-row {
      width: 100%;
    }

    .ant-row > * {
      flex: 1 1 10px;
    }
  }
}

.waiverCreditTextWrapper {
  margin-bottom: 2rem;
  font-size: 16px;
}

.waiverCreditAmountText {
  font-weight: bold;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;