@import 'styles/variables';
@import 'styles/media';
@import 'styles/typography';

.on-xl-up({
    .modalWrapper {
    :global {
      .ant-modal-body {
        padding: 0 24px;
      }
    }
  }
});

.modalWrapper {
  :global {
    .ant-modal-body {
      background-color: @white;
      height: auto;
      min-height: 100px;
      width: auto;
    }
    .ant-modal-header {
      border-bottom: none;
      border-top-right-radius: 6px;
    }
    .ant-modal-title {
      .text-modal-header();
    }
    .ant-modal-content {
      border-left: 5px solid @defaultFontColor;
      border-top: 1px solid @black;
      border-bottom: 1px solid @black;
      border-right: 1px solid @black;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      box-shadow: none;
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-footer {
      margin-top: 30px;
      padding: 0px 24px 30px 8px;
      border-top: none;
    }
  }
  .modalContent {
    top: 0px;
    max-width: 99.2vw;
    padding-bottom: 0;
  }

  .editableTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    .home-text-wrapper-title();
    padding-left: 20px;
    margin-left: -20px;

    input {
      border: none;
      outline: none;
      width: auto;
      .home-text-wrapper-title();
    }

    img {
      cursor: pointer;
      margin-left: 10px;
    }

    label {
      display: none;
    }
  }

  .editableTitle.invalid.active {
    input {
      border-bottom: @invalidInputColor 1px solid;
    }

    label {
      display: block;
      .form-error-text();
    }
  }

  .editableTitle.active {
    input {
      border-bottom: 1px solid @defaultLightBorderColor;
    }
  }
}

.footerWrapper {
  .acceptBtn {
    height: 40px;
    width: 150px;
    border-radius: 4px;
    .text-submit-button();
  }
  .okButton {
    .acceptBtn();
  }
  .resetButton {
    .text-button();
    &.disabledReset {
      color: @defaultDisabledColor;
    }
    &.incPadding {
      padding-left: 20px;
    }
  }
  .cancelBtnWrapper {
    margin-left: auto;
    margin-right: 12px;
    .cancelButton {
      .acceptBtn();
      border-color: @black;
    }
  }
  .removeButton {
    padding-left: 20px;
    color: @removeFileTextColor;
    .removeIcon {
      margin-right: 5px;
      :global {
        .path {
          fill: @removeFileTextColor;
        }
      }
    }
  }

  .disabledRemoveButton {
    padding-left: 20px;
    color: @primaryLinkDisabledColor;
    .removeIcon {
      margin-right: 5px;
      :global {
        .path {
          fill: @primaryLinkDisabledColor;
        }
      }
    }
  }
}
.cancelResetBody {
  margin-bottom: -22px;
  p {
    margin-top: 8px;
    .text-popconfirm-secondary-text();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;