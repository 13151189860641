@import 'styles/media';

.planRecommenderSlider {
  text-align: center;
  max-width: 700px;
  margin: auto;
  .on-sm-down({
    max-width: 350px;
  });
  .on-xsm-down({
    max-width: 300px;
  });

  .content {
    .titleSlider {
      height: 30px;
      color: #2a3233;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin-top: 50px;
      .on-sm-down({
        font-size: 15px;
        text-align: left;
      });

      .infoTooltipIcon {
        cursor: pointer;
        padding-left: 4px;
        .on-sm-down({
          display: none;
        });
      }
    }

    .subTitle {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
    }

    .sliderInfoTooltip {
      width: 200px;
      background-color: #fff;
      color: #2a3233;
    }

    .border.placeRight:before {
      border-right: 8px solid #cccbca;
    }

    .sliderWrapper {
      text-align: center;
      margin-top: 145px;
      position: relative;
      .on-sm-down({
        margin-top: 60px;
      });

      .sliderHeader {
        .minLabel {
          margin-top: -35px;
          float: left;
          color: #2a3233;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;
        }

        .maxLabel {
          margin-top: -35px;
          float: right;
          color: #2a3233;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;
        }
      }

      .slector {
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        margin-top: -12px;
        box-shadow: 1px 0px 3px 1px #88888880;
        cursor: pointer;
        z-index: 50;
        top: 0;
        transition: left 0.5s;

        .slectorInner {
          height: 6px;
          width: 6px;
          background-color: var(--color-theme);
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .selectorClickable {
          position: absolute;
          width: 30px;
          height: 30px;
          z-index: 100;
          background-color: transparent;
        }
      }

      .sliderIndicator {
        width: 100%;
        height: 7px;
        border-radius: 5px;
        background-color: #d8d8d8;
        border: 1px solid #d7d7d9;
      }

      .sliderIndicatorClickable {
        width: 100%;
        height: 25px;
        background-color: transparent;
        position: absolute;
        margin-top: -15px;
        z-index: 49;
        cursor: pointer;
      }

      .sliderIndicatorHighlited {
        width: 50%;
        height: 7px;
        background-color: var(--color-theme);
        position: absolute;
        margin-top: -7px;
        z-index: 49;
        cursor: pointer;
        border-radius: 5px;
        transition: width 0.5s;
      }

      .sliderSteps {
        width: 100%;
        position: relative;
        height: 100px;
        .step {
          margin-top: -15px;
          width: 1px;
          height: 22px;
          background-color: #d8d8d8;
          position: absolute;
          cursor: pointer;

          .label {
            position: absolute;
            width: 50px;
            top: 30px;
            left: -25px;
            color: #2a3233;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            .on-sm-down({
              font-size: 15px;
            });
          }

          .sliderHandleLabel {
            position: absolute;
            width: 200px;
            left: -92px;
            top: -119px;
            color: #2a3233;
            font-size: 13px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            .on-sm-down({
              display: none;
            });
          }

          .image {
            top: -90px;
            position: absolute;
            width: 53px;
            height: 60px;
            left: -26px;
            .on-sm-down({
              display: none;
            });

            img {
              width: 100%;
              max-height: 50px;
              .on-sm-down({
                display: none;
              });
            }
          }
        }
      }
    }
  }

  .nextBtnWrapper {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;