.wrapper {
  margin-top: 12px;
  h1 {
    margin-bottom: 24px;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .planYearLogo {
    height: auto;
    width: 140px;
    margin-bottom: 32px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;