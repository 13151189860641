@import 'styles/media.less';

.container > {
  .on-lg-down({
    display: flex;
    flex-direction: column;
    width: 48%;
  });
}
.container {
  .on-lg-down({
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
  });
}

.subContainer {
  display: block;
  .on-lg-down({
    width: 100%;
  });
  & > div {
    .on-lg-down({
      display: flex;
      flex-direction: column;
      width: 48%;
    });
  }
}

.subContainer > div {
  margin-bottom: 15px;
  .on-lg-down({
    width: 49%
  });
}

.employerContributions {
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;