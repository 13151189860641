@import 'styles/typography';
@import 'styles/variables';

.submitPwdButton {
  margin-top: 20px;
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

.scrollContainer {
  padding: 12px 12px 12px 0px;
  margin-bottom: 12px;
  overflow-y: scroll;
  height: 300px;
  width: 400px;
  min-height: 300px;
}

.cancelButton {
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 0;
  text-align: center;
  height: 48px;
  .text-link-button();
}
.termsHeader {
  margin-top: -35px;
}
.alertMessage {
  width: 400px;
  height: 65px;
  margin-left: -2px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;