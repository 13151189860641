@import 'styles/media.less';
@import 'styles/variables';

.stateSelectorWrapper {
  padding-top: 80px;
  margin: auto;
  text-align: center;
  width: 100%;
  .on-sm-down({
    padding-top: 293px;
    margin: auto;
    text-align: center;
    width: 100%;
    margin-bottom: 45px;
    max-width: 350px
});
  .title {
    height: 32px;
    color: #2a3233;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    .on-sm-down({
      font-size: 24px;
  });
  }

  .stateDropdown {
    height: 45px;
    width: 450px;
    text-align: center;
    position: relative;
    margin: auto;
    margin-top: 80px;
    background-color: @white;
    .on-sm-down({
      width: 350px;
  });
    select {
      height: 100%;
      width: 100%;
      background-color: white;
      padding-left: 10px;
      user-select: none;
      outline: none;
      border: solid 1px #dadfe8;
      text-align-last: center;
      color: #2a3233;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
      .on-sm-down({
        max-width: 350px;
        width: 100%;
        padding-left: 0px;
    });
      .on-xsm-down({
        max-width: 350px;
        width: 100%;
        });
    }

    img {
      position: absolute;
      right: 20px;
      top: 17px;
      width: 10px;
      .on-xsm-down({
        margin-left: 20%;
      });
    }

    option {
      background-color: white;
      height: 30px;
    }
  }

  .stateErrorWrapper {
    width: 100%;
    margin: auto;

    .stateError {
      height: 90px;
      width: 451px;
      color: #2a3233;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin: auto;
      margin-top: 50px;
      background-color: #fff5f6;
      border: solid @invalidInputColor 1px;
      padding: 16px;
      text-align: left;
      .on-sm-down({
        width: 100%;
    });
    }
  }

  .nextButtonWrapper {
    margin-top: 60px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;