@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}
.wrapper {
  margin: 0 164px 0 62px;
  position: relative;
  .on-sm-down({
    margin: 0 !important;
    padding: 24px;
  });
  .on-xl-down({
    margin: 0 31px;
  });
  .on-md-down({
    margin: 0 31px;
  });
  .darkButton {
    .add-new-plan-button ();
    background: @defaultFontColor;
    color: @white;
    width: 164px;
    height: 40px;
    border-radius: 4px;
  }
}
.headerIcon {
  svg {
    margin-top: 2px;
    height: 22px;
    width: 24px;
    margin-right: 5.55px;
    path {
      fill: @pageHeaderIconColor;
    }
  }
}

.planCardWrapper {
  margin-top: 45px;
  .on-sm-down({
    margin-top: 0px;
    margin-bottom: 20px;
  });
}
.noPlanView {
  margin-left: unset;
}

.disclaimer {
  .medical-wrapper-bottom-text();
  margin-top: 35px;
  line-height: 20px;
  letter-spacing: 0.11px;
  text-align: left;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;