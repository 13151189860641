@import 'styles/variables.less';
@import 'styles/typography.less';

.documentUploaderWrapper {
  input[type='file'] {
    display: none;
  }

  .fileNameArea {
    .text-link-button();
    margin: 6px 0px 0px 10px;
    cursor: pointer;
    height: 18px;
    overflow: hidden;
    &.expandingArea {
      width: auto;
      margin-right: 10px;
      max-width: 115px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .removeFile {
    margin: 4px 0 0 7px;
    cursor: pointer;
    .text-logo-uploader();
    color: @warningTextColor;
  }

  .placeholderText {
    .text-helper();
    margin-top: 6px;
  }

  .fileUploadBtn {
    height: 32px;
    border-radius: 4px;
    border: 1px solid @black;
    .text-submit-button();
    &.disabled {
      border: 1px solid @primaryLinkDisabledColor;
    }
    &.warning {
      border: 1px solid @invalidInputColor;
      color: @invalidInputColor;
    }
  }

  .loadingIcon {
    font-size: 14px;
    margin: 6px 8px 0px 8px;
  }

  .invalidError {
    .form-error-text();
    margin-top: 8px;
  }
}

.cancelUploadBody {
  margin-bottom: -20px;
  p {
    .text-popconfirm-secondary-text();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;