@import 'styles/variables.less';
@import 'styles/typography.less';

.wrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  height: 100%;
  width: fit-content;
  min-width: 190px;
  padding: 0 10px;

  .toolbarHeader {
    .toolbar-menu-header-text();
    text-align: center;
    margin: 5px 0 3px 0;
  }
  .selectedBtn {
    .toolbar-header-button-text();
    border-radius: 5px;
    height: 22px;
    padding: 0;
    span {
      line-height: normal;
    }
    &:disabled {
      color: @black;
    }
  }
}

.overlay {
  top: 47px !important;
  margin-top: 0px !important;

  :global {
    .ant-dropdown {
      top: 0px !important;
      margin-top: 0px !important;
    }

    .ant-dropdown-menu {
      width: fit-content;
      min-width: 230px;
      background-color: @appHeaderBackgroundColor;
    }
    .ant-dropdown-menu-item {
      color: @white;
      padding: 6px 25px;
      &:hover {
        background-color: lighten(@appHeaderBackgroundColor, 5);
        color: @white;
      }
    }
  }
  .dropdownLink {
    text-decoration: none;
  }
  .active {
    border-left: 4px solid @white;
  }
}

.dropdownOverlay {
  :global {
    @media only screen and (max-height: 673px) {
      .ant-dropdown-menu {
        margin-top: 48px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;