@import 'styles/media';
@import 'styles/variables';

.calculatedFields {
  box-sizing: border-box;
  display: inline-flex;
  text-align: center;

  :global {
    .__react_component_tooltip {
      text-align: left;
      & label {
        font-weight: 900px;
      }
    }
  }

  .breakdownField {
    box-sizing: border-box;
    height: 90px;
    width: 174px;
    border: 1px solid rgba(237, 238, 240, 0.8);
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 #e8eef7;
    padding: 9px;
    padding-left: 18px;
    text-align: left;
    .on-sm-down({
      height: 100px;
      min-width: 50px;
      width: 70px;
      padding-left: 0;
      padding: 5px;
  });

    .title {
      color: #212326;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
      .on-sm-down({
        line-height: 15px;
        text-align: center;
        font-size: 10px;
        margin-top: 10px;
    });

      .infoTooltipIcon {
        width: 12px;
        margin-top: -3px;
        margin-left: 5px;
        .on-sm-down({
         display: none;
        });
      }
    }

    .subTitle {
      color: @planCardTextColor;
      font-size: 9px;
      letter-spacing: 0;
      line-height: 11px;
      text-align: center;
    }

    .lowestValue {
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.56px;
      text-align: left;
      .on-sm-down({
      font-size: 16px;
      text-align: center;
  });
    }

    .lowValue {
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.56px;
      text-align: left;
      .on-sm-down({
      font-size: 14px;
      text-align: center;  
  });
    }

    .mediumValue {
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.56px;
      text-align: left;
      .on-sm-down({
      font-size: 12px;
      text-align: center;
  });
    }

    .highValue {
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.56px;
      text-align: left;
      .on-sm-down({
      font-size: 11px;
      text-align: center;
  });
    }

    .greenText {
      color: #28b667 !important;
    }

    .description {
      color: #4e5259;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 29px;
    }
  }

  .sign {
    width: 30px;
    padding-top: 40px;
    color: #2a3233;
    font-size: 17px;
    font-weight: 900;
    letter-spacing: -0.47px;
    line-height: 21px;
    text-align: center;
    .on-sm-down({
      width: 10px;
      font-size: 13px;
  });
  }

  .total {
    box-sizing: border-box;
    border: 3px solid var(--color-theme);
    .value {
      color: black;
    }
    width: max-content;
    .on-sm-down({
      max-width: 75px;
  });
  }

  .breakdownTooltip {
    opacity: unset;
    background-color: white;
    font-size: 12px;
    font-weight: normal;
    max-width: 300px;
    z-index: 100;
  }

  :global {
    .med-cost-tooltip-content {
      display: flex;
      flex-direction: row;
      .tooltip-values {
        display: flex;
        flex-direction: column;
      }
      .tooltip-currency-values {
        margin-left: 50px;
      }
      .currency-section {
        text-align: right;
      }
    }
    .tooltip-plan-cost-section {
      margin-top: 10px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;