@import 'styles/media.less';

.mdvPlansWrapper {
  max-width: 1150px;
  .on-lg-down({
    width: auto;
  });
}
.networkSelector {
  &:first-child {
    .on-lg-down({
      width: 50%      
    });
  }
  .on-lg-down({
    height: 40px;
    width: 60%;
    margin-left: 33%;
  });
  .on-sm-down({
    margin-left: 0;
    height: 45px;
    width: 100%;
    margin-bottom: 5px;
  });
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;