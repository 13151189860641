.popover {
  :global {
    .ant-popover-inner {
      outline: black solid 1px;
      border-radius: 8px;
    }

    .ant-popover-arrow-content {
      border: black solid 1px;
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }
  }
}

.popoverTop {
  :global {
    .ant-popover-arrow {
      bottom: 0.71249px;
      height: 9px;
      width: 22px;
    }

    .ant-popover-arrow-content {
      bottom: 2px;
    }
  }
}

.popoverLeft {
  :global {
    .ant-popover-arrow {
      right: -3.6px;
      height: 22px;
      width: 14px;
    }

    .ant-popover-arrow-content {
      left: -8px;
    }
  }
}

.popoverRight {
  :global {
    .ant-popover-arrow {
      left: -3.6px;
      height: 22px;
      width: 14px;
    }

    .ant-popover-arrow-content {
      right: -8px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;