@import 'styles/media.less';
.planDetailsPopup {
  & .planDetailsHeader {
    margin-left: 68.5px;
    margin-right: 68.5px;
    margin-top: 30px;
    .on-sm-down({
      margin-left: 20px;
      margin-top: 0px;
  });

    .title {
      height: 29px;
      color: #2a3233;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
      .on-sm-down({
        width: 300px;
    });
    }
    .backArrow {
      margin-right: 20px;
      .on-sm-down({
        margin-top: 5px;
    });
    }
    .hrTag {
      .on-sm-down({
        display: none
    });
    }
  }

  :global(.rs-drawer-header-close) {
    display: none;
  }
  :global {
    .rs-drawer-body {
      .on-sm-down({
        overflow-x: hidden !important;
        overflow-y: auto !important;
    });
    }
  }
}

.planDetailsPopupWrapper {
  margin-left: 68.5px;
  margin-right: 68.5px;
  margin-top: -35px;
  .on-sm-down({
    margin-left: 30px;
});
  .headerSection {
    display: none;
  }

  .mobilePlanDetailCardBody {
    display: none;
  }

  .inOutNetworkPlanCard {
    position: absolute;
    left: 150px;
    top: -30px;
    margin-left: 17px;
    width: 425px;
    height: 50px;
  }

  .planCardWrapper {
    display: none;
  }

  .inNetworkOutNetworkWrapper {
    margin-top: 0;
    position: relative;
    left: 10px;
    top: 0;
    background-color: white;

    .titleContainerWrapper {
      .titleContainer {
        .title-item:first-of-type {
          padding-top: 32px;
          height: 62px;
        }
      }
    }

    .inOutNetworkPlanCard {
      .cardBodyRowCaontainer {
        .cardBodyDark {
          background-color: #7c828e17;
        }
      }
    }
  }

  .planDetailCardBody {
    background-color: white;
  }

  .pageContent {
    min-width: unset;
  }

  @media only screen and (min-width: 575px) {
    .inNetworkOutNetworkWrapper {
      position: relative;
    }
  }
}

.drawerBody {
  .on-sm-down({
    height: 82% !important;
});
}
.planName {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 100px;
  .on-sm-down({
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: -5px;
    left: 60px;
});
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;