@import 'styles/variables.less';
@import 'styles/typography.less';

.configureBCWrapper {
  .bcTooltip {
    position: absolute;
    right: 35px;
    top: 18px;
  }

  .toggleWrapper {
    margin-top: 22px;

    .toggle {
      display: flex;

      .label {
        .body-h3();
        width: 350px;
      }

      .labelMuted {
        .body-h3();
        width: 350px;
        color: @primaryLinkDisabledColor;
      }
    }

    .labelLink {
      .line-item-link();
      cursor: pointer;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;