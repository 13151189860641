@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.mdvPlanTitleWrapper {
  width: 360px;
  height: 100%;
  display: flex;
  padding-top: 16px;

  .icon {
    width: 24px;
    margin: 1.5px 13.55px 0 0;

    .greyIcon {
      width: 24px;
      height: 23.5px;
      :global {
        .path {
          fill: @pageHeaderIconColor;
        }
      }
    }
  }
  .on-lg-down({
    width: 100%;
  });

  .title {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden; /* Hides the overflow content */
    .dbg-page-title();
    span {
      font-weight: 300;
    }

    .on-lg-down({
      font-size: 32px;
    });

    .on-md-down({
      font-size: 32px;
    });

    .on-sm-down({
      font-size: 22px;
    });

    .on-xxsm-down({
      font-size: 21px;
    });
  }

  .mdvCount {
    padding-left: 5px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;