@import 'styles/variables.less';

.plan {
  height: 100%;
  margin-right: 5px;
  opacity: 0.5;
  position: relative;
  cursor: pointer;

  .rankWrapper {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    color: @planRecommenderTitleColor;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 13px;
    position: relative;

    :global {
      span {
        margin-left: 5px;
        vertical-align: super;
      }
    }

    .rankText {
      color: @white;
      margin-top: -21px;
    }
  }

  .headerBorder {
    height: 5px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: transparent;
  }

  .upper {
    box-sizing: border-box;
    height: 700px;
    border: 1px solid rgba(237, 238, 240, 0.8);
    border-radius: 7px;
    background-color: @white;
    box-shadow: 0 2px 20px 0 @boxShadow;
    z-index: 200;
    :global {
      .noPremium {
        height: 330px;
      }

      .carrierLogo {
        width: 100%;
        height: 25px;
        text-align: center;
        margin-top: 10px;
        padding-left: 5px;
        img {
          text-align: center;
          width: 100%;
        }
      }
    }

    .planName {
      width: 100%;
      color: @defaultFontColor;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      margin-top: 4px;
      margin-left: 0;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .infoTooltipIcon {
        height: 13px;
        margin-right: 4px;
        margin-top: 4px;
        display: none;
      }

      .infoTooltip {
        font-size: 12px;
        font-weight: normal;
        max-width: 300px;
        z-index: 100;
      }

      .border.placeRight:before {
        border-right: 8px solid @borderline;
      }
      .planNameContent {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0px 5px 0px 5px;
      }
    }
    .carrierLogo {
      width: 100%;
      height: 25px;
      text-align: center;
      margin-top: 10px;
      padding-left: 5px;
      object-fit: contain;
    }
    .links {
      width: 100%;
      height: 40px;
      position: relative;
      color: @mdvButtonColor;
      cursor: pointer;
      border-bottom: 0.75px solid @borderColor;

      .infoLink {
        position: absolute;
        left: 10px;
        top: 10px;
        text-decoration: underline;
        font-size: 11px;
        img {
          margin-right: 5px;
          height: 13px;
          margin-top: -4px;
          opacity: 0.5;
        }
      }

      .detailsLink {
        position: absolute;
        right: 10px;
        top: 10px;
        text-decoration: underline;
        font-size: 11px;
      }
    }

    .valueField {
      height: 58px;
      text-align: center;
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      font-weight: bold;
    }

    .hsaHraField {
      padding-top: 4px;
    }

    .hsa.valueField {
      padding-top: 20px;
      height: unset;
    }
    .annualCost.valueField {
      font-weight: bold;
      font-size: 24px;
    }

    .darkBg {
      background-color: @darkBackground;
    }

    .monthlyPremium {
      height: 80px;

      .value {
        height: 27px;
        color: @checkmarkColor;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 22px;
        text-align: center;
      }
      .cover {
        height: 15px;
        color: @checkboxTextColor;
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        font-weight: normal;
      }
    }

    .planNotes {
      height: 114px;
      padding-top: 20px;

      .note {
        height: 62px;
        width: 207px;
        color: @planNotes;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        font-weight: normal;
        margin: auto;
        margin-bottom: 5px;
      }
    }

    .annualCost {
      height: 85px;
      left: 50%;
      transform: translate(-50%);
      position: absolute;
      z-index: 100;
    }

    .annualCostBreakdown {
      height: 100px;
    }
  }

  .planFooter {
    z-index: 0;
    box-sizing: border-box;
    height: 95px;
    max-width: 350px;
    width: 100%;
    border: 1px solid rgba(237, 238, 240, 0.8);
    border-radius: 7px;
    background-color: @white;
    margin-top: -80px;
    position: absolute;
    background-image: unset;
  }
}
.activePlan {
  opacity: 1;

  .headerBorder {
    background-color: var(--color-theme);
  }

  .rankWrapper {
    :global {
      span {
        color: var(--color-theme);
        vertical-align: super;
      }
    }
    .rankIcon {
      :global(#badge-1) {
        fill: var(--color-theme);
      }
    }
  }
}
.monthlyPremium {
  color: @black;
  height: 85px;
}
.EstCost {
  margin-top: 30px;
  font-size: 12px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;