@import 'styles/variables';
@import 'styles/media.less';

.otherPlansSummaryWrapper {
  .planRecommenderHeader {
    position: relative;
    border-bottom: none;
  }
  .planRecommenderPlansWrapper {
    text-align: left;
  }
  .overlay {
    background-color: white;
  }

  .closed {
    display: none;
  }

  .closeIcon {
    position: fixed;
    right: 74px;
    margin-top: 50px;
    z-index: 1000;
    cursor: pointer;
    .on-xl-down({
      position: absolute;
      right: 35px;
      margin-top: 0px;
    });

    .closeText {
      color: #2a3233;
      font-family: Lato;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  .summaryBody {
    margin-right: 130px;
    .on-xl-down({
      margin-right: 0px;
    });
  }
  .contentWrapper {
    width: 100%;
    padding: 5%;
  }

  .summaryTitle {
    font-weight: bold;
    color: #2a3233;
    font-size: 31px;
    margin-top: -50px;
  }
  .summaryCaption {
    margin-top: 10px;
    color: #2a3233;
    font-size: 15px;
  }
  .table {
    border-radius: 6px;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .table,
  th,
  td {
    border-radius: 6px;
  }
  .td {
    padding: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .th {
    padding: 15px;
    text-align: left;
  }
  .subTitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 50px;
  }
  .sectorSubtitle {
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px;
  }
  .separator {
    margin-bottom: 30px;
  }

  .planSubTitle {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .premiumValue {
    font-weight: 700;
    font-size: 27px;
  }

  .recommendation {
    font-weight: 700;
    color: var(--color-theme);
    font-size: 11px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .tableStyle {
    text-align: center;
    width: 100%;
  }
  .tableContainer {
    display: inline-block;
    margin-bottom: 46px;
    margin-top: 35px;
  }
  .tableContainerOuter {
    display: inline-block;
  }
  .tableHeaderSpacing {
    height: 12px;
  }
  .tableContainerInner {
    width: 150%;
  }

  .tableFooterOap {
    margin-bottom: 40px;
  }
  .compareTableSubTitle {
    font-weight: 400;
    font-size: 11px;
  }
  .compareTableHeader {
    border-bottom: 1px solid #e6e7e8;
  }
  .compareTableHeaderRight {
    border-right: 1px solid #e6e7e8;
  }
  .compareTableDataItem {
    font-size: 13px;
    font-weight: bold;
    border-right: 1px solid #e6e7e8;
    :global {
      .borderless {
        border-right: none;
      }
    }
    text-align: left;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .compareTableDataRow {
    border-right: 1px solid #e6e7e8;
    :global {
      .borderless {
        border-right: none;
      }
    }
  }
  :global {
    .dot {
      height: 8px;
      width: 8px;
      background-color: var(--color-theme);
      border-radius: 50%;
      display: inline-block;
    }
  }
  .filled {
    background-color: #fafafa;
  }
  .planOther {
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #dadfe8;
    height: 288px;
    width: 100%;
  }
  .resultsButton {
    background-color: @planRecommenderTitleColor;
    width: 237px;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    user-select: none;
    outline: none;
    margin-bottom: 7px;

    &:hover {
      background-color: lighten(@planRecommenderTitleColor, 5%);
      cursor: pointer;
    }
  }
  .footer {
    display: inline-block;
    width: 100%;
    background-image: unset;
    position: unset;
    font-size: unset;
  }
  .footerInner {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
  .footerClose {
    margin-top: 7px;
    color: #3970b0;
    text-align: center;
  }
  .footerCloseCenter {
    margin: 0 auto;
    width: 50%;
    cursor: pointer;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;