@import 'styles/variables.less';

.linkBtnWrapper {
  display: flex;
  align-items: center;
  text-align: left;

  .linkBtn {
    color: @primaryLinkColor;
    width: auto;
    display: flex;
    align-items: center;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;