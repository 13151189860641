@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.consultantTypeSelectWrapper {
  width: auto;
  height: auto;
  background: @white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 2px solid @primaryLinkColor;
  margin-bottom: 20px;
  padding: 15px;

  .on-sm-down({
    width: 100%;
    height: unset;
  });

  p {
    margin-bottom: 0;
  }
}
.getStartedLink {
  margin-top: 5px;
  color: @primaryLinkColor;
  cursor: pointer;
  font-weight: 600;
}

.getStartedArrow {
  margin-left: 5px;
}
.labelText {
  cursor: pointer;
  font-weight: 600;
}
.consultantDescription {
  margin-top: 10px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;