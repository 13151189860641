@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.planRecommenderModal {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
  .on-sm-down({
      background-color:@planRecommenderPopupBackground;
    });

  .modalContentWrapper {
    width: 1050px;
    height: 320px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    margin-top: 140px;
    text-align: center;
    padding-top: 64px;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    .on-lg-down({
      width: 95%;
    });

    .closeIcon {
      position: absolute;
      right: 25px;
      top: 25px;
      opacity: 0.2;
      cursor: pointer;
    }

    .titleWrapper {
      text-align: center;
      width: 712px;
      margin: auto;

      .on-sm-down({
        width: 90%;
      });
      .on-lg-down({
        padding-right: 35px;
        padding-left: 20px;
      });

      .title {
        height: 48px;
        width: 100%;
        color: @planRecommenderTitleColor;
        font-size: 32px;
        font-weight: 900;
        letter-spacing: -0.65px;
        line-height: 48px;
        text-align: center;
        .on-sm-down({
          font-size: 20px;
          height: unset;
          line-height: unset;
          margin-bottom: 20px
        });
      }

      .subTitle {
        height: 44px;
        width: 100%;
        color: @defaultFontColor;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        vertical-align: middle;
      }
    }

    .getStartedBtn {
      background-color: var(--color-theme);
      height: 40px;
      width: 200px;
      border-radius: 5px;
      border: none;
      color: white;
      font-weight: bold;
      margin-top: 50px;
      user-select: none;
      outline: none;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      .on-sm-down({
        margin-top: 70px;
      });
    }

    .planRecomIcon {
      margin-top: 50px;
    }
  }
  @media only screen and (max-height: 768px) {
    .modalContentWrapper {
      margin-top: 110px;
    }
  }
  @media only screen and (max-width: 768px) {
    .modalContentWrapper {
      width: 90%;
      margin-left: 5%;
    }
  }
}

.plan-recommender-modal-close {
  display: none;
}

@media only screen and (max-width: 992px) {
  .planRecommenderModal {
    left: 0;
    width: 100%;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;