@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.container {
  border-bottom: 0.75px dashed @borderColor;
  margin-bottom: 15px;
  .on-lg-down({
    display: flex;
    margin-bottom: 0px;
  });
  .on-sm-down({
    display: flex;
    flex-direction: column;
  });
}
.carrierLogo {
  margin-bottom: 5px;
  img {
    height: 20px;
  }
  .on-lg-down({
    margin-right: 10px;
  });
  .on-sm-down({
    display: flex;
    margin-bottom: 10px;
  });
}
.planName {
  .plan-card-heading();
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;