@import 'styles/variables.less';
@import 'styles/typography.less';

.modalMain {
  z-index: 9999;

  :global {
    .ant-modal-content {
      overflow: hidden;
    }
    .ant-modal-header {
      background: @modalBackgroundColor;
    }
    .ant-modal-body {
      padding: 32px 32px 0px 32px;
    }
    .ant-modal-content {
      min-height: 600px;
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid rgba(237, 238, 240, 0.8);
      background: @modalBackgroundColor;
    }
    .ant-modal-header {
      padding: 8px 32px 0px 24px;
      border-bottom: none;
    }

    .ant-modal-footer {
      margin-top: -30px;
      padding: 0px 25px 0px 25px;
      border-top: none;
    }
  }
}

.linkBtn {
  padding: 0;
  text-decoration: none;
}

.authWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .altertWrapper {
    position: absolute;
    top: -0.5%;
    padding: 0px 150px;
  }

  .idCardAlertWrapper {
    padding: 0px 70px !important;
  }

  .authContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
  }

  .commonContainer {
    display: flex;
    margin-top: 16px;
    padding: 32px 117px;
    flex-direction: column;
    min-height: 500px;
    .heading {
      font-size: 24px;
      color: @primaryHeaderColor;
      font-weight: 900;
      margin-bottom: 12px;
    }
  }

  .cardViewPadding {
    padding: 24px 0px !important;
    margin-top: 0px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;