@import 'styles/variables.less';
@import 'styles/typography.less';

.planDropdownMenu {
  :global {
    .ant-dropdown-menu {
      background-color: @defaultFontColor;
      border-radius: 5px;
    }
    .ant-dropdown-menu-item {
      .text-dropdown-menu-item();
    }
    .ant-dropdown-menu-item:hover {
      background-color: transparent;
    }
  }
}

.dropdownButton {
  height: 48px;
  width: 300px;
  border-radius: 4px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;