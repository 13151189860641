@import 'styles/variables';

.voluntaryPlanTagWrapper {
  border-radius: 4px;
  border: 1px solid @voluntaryPlanTagColor;
  color: @voluntaryPlanTagColor;
  width: 87px;
  font-weight: bold;
  font-size: 10px;
  line-height: 17px !important;
  align-items: center;
  height: 17px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.voluntaryIcon {
  margin-top: 2px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;