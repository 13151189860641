@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.planDetailsWrapper {
  width: clamp(320px, 30%, 370px);
  flex-shrink: 0;
  .on-lg-down({
    width: 100%;
  });
}

.planDetailsContainer {
  height: 100%;
  .planDetails {
    height: 100%;
    padding: 35px;
    .on-lg-down({
      padding: 20px 20px 0;
    });

    .planInfoValues {
      .on-lg-down({
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1%;
        margin: 0 10px;
      });
    }
  }

  .maxContributionIndividual {
    margin-bottom: 15px;
  }

  .employerContributions {
    margin-bottom: 15px;
    .on-lg-down({
      margin-bottom: 0;
    });

    .frequency {
      margin-bottom: 15px;
    }
    :global {
      .ant-row:first-child {
        padding-bottom: 15px;
      }
    }
  }
  .frequency {
    .plan-card-left-panel-detail-value();
  }
  .otherContribution {
    .plan-card-left-panel-detail-value();
    margin-bottom: 15px;
  }
  .viewDetailsLink {
    width: 230px;
    color: @primaryLinkColor;
    padding-top: 10px;
    cursor: pointer;
    .plan-view-details();
    border-top: 1px solid @defaultLightBorderColor;
    .on-lg-down({
      width: auto;
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    });
  }
}

.tabsArea {
  color: @planCardTextColor;
  flex-direction: column;
  flex-grow: 1;
}

.hotSpot {
  height: 100%;
}

.documentLink {
  .on-lg-down({
    margin-left: 10px
  });
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;