@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}
.telehealthWrapper {
  margin: 0 164px 0 62px;
  .on-lg-down({
    margin: 0 31px
  });
  .on-sm-down({
    margin: 0 24px
  });

  .telehealthContainer {
    .on-lg-down({
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    });
    .on-sm-down({
      display: block;
    });
  }

  .planHeading {
    margin-top: 47px;
    margin-bottom: 13px;
    .plan-card-heading();
    .on-lg-down({
      .mob-plan-card-heading();
    });
  }
  .telehealthIcon {
    margin-top: 2.5px;
    margin-right: 10px;
  }
  .addOrRemovePlansWrapper {
    :global(.overlayWrapper) {
      border-radius: 7px;
      width: 174px;
      left: -4px;
    }
    position: absolute;
    margin-top: -65px;
    margin-left: 620px;
    width: 165px;
    height: 28px;
    left: 0px;

    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 10px;
    }
  }
  .noPlanView {
    margin-left: unset;
  }

  .darkButton {
    .add-new-plan-button ();
    background: @defaultFontColor;
    color: @white;
    width: 164px;
    height: 40px;
    border-radius: 4px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;