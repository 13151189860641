@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.mdvCardTitlesWrapper {
  min-width: 158px;
  margin-right: 11px;
  .on-lg-down({
    width: 30%
  });
  .on-sm-down({
    display: none
  });

  .addOrRemovePlansWrapper {
    :global(.overlayWrapper) {
      border-radius: 7px;
      width: 187px;
      left: -4px;
    }
    position: absolute;
    width: 178px;
    height: 28px;
    left: -40px;

    button {
      .mdv-filter-button();
      border: none;
      min-width: 130px;
      padding-left: 12px;
      font-size: 14px;
    }
  }

  .titleContainerWrapper {
    margin-left: -4px;
    margin-right: -10px;

    .titleContainer {
      width: 172px;
    }

    .planNameContainer {
      padding-top: 63px;
    }

    .titleItem {
      &:first-of-type {
        height: 72px;
        .titleTop {
          margin-top: -6px;
          &.planName {
            margin-top: -10px;
          }
        }
        .popover {
          top: -20px;
        }
      }
    }
  }

  .titleTop {
    .mdv-card-title();
    height: 55px;
    &.medicalCost {
      margin-top: 10px;
      height: 51px;
    }
    .popover {
      top: -22px;
    }
  }

  .title {
    height: 29px;
    line-height: 29px;
    padding-left: 4px;
    .mdv-card-title();
    .popover {
      top: -16px;
    }
  }
  .tittleTopWrapper {
    position: relative;
    right: 10px;
    left: 0px;
  }
  .title,
  .titleTop {
    position: relative;
    padding-left: 4px;
    &.monthlyCost {
      height: 68px;
      padding-top: 10px;
    }

    &:hover {
      .popover {
        display: block;
      }
    }
  }

  .titleSeperator {
    height: 32px;
    line-height: 29px;
  }

  .titleValue {
    display: flex;
    align-items: center;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 5px;
  }
  .monthlyCostDisabled {
    opacity: 0.4;
  }

  .popoverIcon {
    margin-left: +5px;
  }

  .flexicon {
    display: flex;
  }

  .popoverTextContent {
    margin-left: 29px;
  }

  .popoverTitle {
    font-weight: bold;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;