@import 'styles/typography.less';
@import 'styles/variables.less';

.wrapper {
  :global {
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-header {
      display: flex;
      justify-content: center;
      border-radius: 8px;
    }
    .ant-modal-title {
      .text-page-title();
    }
    .ant-modal-header {
      padding: 28px 30px 0px;
      border-bottom: none;
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      padding: 24px 30px;
    }
    .ant-modal-footer {
      padding: 0 30px 24px;
      border-top: none;
      text-align: center;
    }
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0;
    }
  }
}

.footer {
  height: 93px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.confirmBtn {
  &:disabled {
    background: @primaryLinkDisabledColor;
    color: @white;
  }
}

.fullWidth {
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;