@import 'styles/variables';
@import 'styles/typography';

.imageCustomizerWrapper {
  .backgroundImgWrapper {
    margin-bottom: 35px;
  }
}

.imgHeaderWrapper {
  margin-top: 20px;
  .backgroundHeader {
    margin-right: 20px;
  }
  p {
    .text-helper();
  }
  span {
    color: @primaryLinkDisabledColor;
    .text-button();
  }
  .imgNameWrapper {
    cursor: pointer;
    margin-top: 8px;
    .imgName {
      max-width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
      color: @primaryLinkColor;
      text-decoration: none;
      .text-bold-logo-uploader();
    }
    .removeImg {
      color: @removeFileTextColor;
      .text-logo-uploader();
    }
  }
}

.subHeaderWrapper {
  margin-top: 30px;
  p {
    .text-helper();
  }
  span {
    color: @primaryLinkDisabledColor;
    .text-button();
  }
}
.footerColorCustomizer {
  padding: 0px 0px 6px 15px;
}

.backgroundColorPickerWrapper {
  margin-bottom: 20px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;