@import 'styles/variables.less';
.colorPicker {
  height: 30px;
  width: 124px;
  position: relative;
  .swatch {
    top: 8px;
    left: 8px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  .disableBackground {
    background-color: @disableBackgroundColor;
  }
  .textBox {
    padding-left: 37px;
    border: 1px solid @lineBorderColor;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }
  .popover {
    margin-left: 133px;
    position: absolute;
    z-index: 4;
  }
  .cover {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: fixed;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;