@import 'styles/variables.less';
@import 'styles/typography.less';

.container {
  background-color: @white;
  .expandIcon {
    transition: linear 0.2s;
  }
  :global {
    .ant-collapse-header {
      .styled-collapse-header();
    }
  }
  :global(.ant-collapse-item-active) {
    .expandIcon {
      transition: linear 0.2s;
      &.active {
        transition: linear 0.2s;
        transform: rotate(-90deg) translateX(50%);
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;