@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}

.visionWrapper {
  padding-left: 62px;
  position: relative;
  padding-bottom: 50px;
  margin-top: 5px;
  .on-lg-down({
    padding: 0 31px; 
  });
  .on-sm-down({
    padding: 0 24px; 
  });
  .bottomText {
    .medical-wrapper-bottom-text();
    margin-top: 23px;
    line-height: 20px;
    letter-spacing: 0.1090909093618393px;
    text-align: left;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;