@import 'styles/variables';
@import 'styles/typography';

.resWrapperForm {
  margin-top: 18px;

  .resourceName {
    .text-additional-resource-title();
    :global {
      .ant-form-item-required {
        color: @primaryHeaderColor;
      }
    }
  }

  .maxLabel {
    .text-form();
    text-align: right;
  }

  .maxCountLabel {
    .maxLabel();
    margin: -23px 0 20px 0;
  }

  .leftLabel {
    .maxLabel();
    margin: -40px 0 20px 0;
  }

  .errorMsg {
    .form-error-text();
    text-align: left;
    margin: -25px 0 20px 130px;
  }

  .resNameWrapper {
    :global {
      .ant-input {
        border: 1px solid @invalidInputColor;
      }
      .ant-form-item-required {
        color: @invalidInputColor;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        color: @invalidInputColor;
      }
    }
  }

  :global {
    .ant-form-item-has-error .ant-form-item-explain-error {
      font-weight: 400;
    }
  }

  .invalidError {
    .form-error-text();
    margin: 8px 0 0 138px;
  }

  .header {
    padding-bottom: 15px;
    border-bottom: 1px solid @black;
    margin-bottom: 20px;
    .text-additional-resource-header();
  }

  .rowContainer {
    align-items: center;
    margin-bottom: 30px;
    .radioBtnError {
      :global {
        .ant-radio-inner {
          border-color: @invalidInputColor;
        }
        .ant-radio-inner::after {
          background-color: @invalidInputColor;
        }
      }
    }
  }

  .errorOption {
    width: 135px;
    display: flex;
    align-items: center;
    .text-additional-resource-title();
    color: @invalidInputColor;
  }

  .optionalTitle {
    width: 135px;
    display: flex;
    align-items: center;
    .text-additional-resource-title();
    color: @primaryFontColor;
  }

  .fileUploader {
    width: 350px;
    margin-bottom: 0px;
  }

  .weblink {
    display: flex;
    margin-bottom: 0px;
  }

  .weblinkInput {
    .text-weblink-input();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;