@import 'styles/variables';
@import 'styles/typography';

.wrapperForm {
  margin-top: 18px;
  border: 1px solid @defaultBorderColor;
  border-radius: 8px;
  padding: 20px;

  .rowContainer {
    align-items: center;
  }
  .weblinkContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
  .weblink {
    width: 100%;
  }

  .errorOption {
    width: 135px;
    display: flex;
    align-items: center;
    .text-additional-resource-title();
    color: @errorMessageColor;
  }

  .optionalTitle {
    width: 135px;
    display: flex;
    align-items: center;
    .text-additional-resource-title();
  }

  .fileUploader {
    display: flex;
    height: 50px;
    justify-content: flex-start;
    margin-left: -17px;
  }
  .weblinkInput {
    display: flex;
    height: 50px;
    justify-content: flex-end;
    margin-left: 10px;
    margin-right: -60px;
  }
}
.docBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 22px;
  button {
    width: 150px;
    height: 40px;
    border-radius: 5px;
  }
  .saveButtonWrapper {
    margin-left: 10px;
  }
  .cancelButtonWrapper {
    margin-left: 10px;
  }
}
.docWrapper {
  display: flex;
  flex-flow: row;
}

.planNameWrapper {
  align-items: center;
  width: 100%;
}

.errorOptionWeb {
  width: 135px;
  display: flex;
  align-items: center;
  .text-additional-resource-title();
  color: @errorMessageColor;
  margin-top: -48px;
}

.optionalTitleWeb {
  width: 135px;
  display: flex;
  align-items: center;
  .text-additional-resource-title();
  margin-top: -48px;
}

.fileUploaderWrapper {
  display: block;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;