@import 'styles/variables.less';
@import 'styles/typography.less';

.oeHeaderContent {
  display: flex;
  .inProgressSticker {
    border: 0.5px solid @editableHotspotBorderColor;
    border-radius: 4px;
    .label-sticker();
    height: 17px;
    width: fit-content;
    padding-right: 7px;
    margin: 3px 0px 0px 12px;
    .hourglass {
      margin: -4px 2px 0px 2px;
    }
  }
}

.openEnrollmentWrapper {
  color: @primaryFontColor;
  .dateTimePicker {
    border-radius: 4px;
    width: 100%;
  }

  .itemContainer {
    margin-right: 10px;
  }

  .meetingTypeHeader {
    margin: 52px 0px 15px 0px;
  }

  .radioGroupSelection {
    margin: 6px 0px 4px 0px;
    width: 102%;

    &.recordingSection {
      :global {
        .ant-space {
          width: 100%;
        }

        .ant-radio-wrapper {
          width: 100%;
        }

        span.ant-radio + * {
          width: 100%;
        }
      }
    }
  }

  .durationContainer {
    display: flex;

    .selectionContent {
      width: 70px;
    }

    .selectionLabel {
      margin: 5px 24px 5px 8px;
    }
  }

  .recordingContainer {
    display: flex;
    width: 100%;
    margin: 28px 0px 4px 0px;

    .selectionLabel {
      width: 86px;
      &.invalidLabel {
        color: @invalidInputColor;
      }
    }

    .selectionContent {
      width: 100%;
      margin: -4px 12px 0px 0px;
    }
  }

  .tooltipIcon {
    position: absolute;
    left: 72px;
    cursor: pointer;

    &.disabled {
      :global {
        .question {
          stroke: @primaryLinkDisabledColor;
        }
      }
    }
  }

  .alertWrapper {
    margin: 12px 0px -52px -30px;
  }

  .notificationAlertWrapper {
    margin: 13px 0px -40px -1px;
  }

  :global {
    .ant-form-item-label {
      font-weight: bold;
    }
  }

  .disabledLabel {
    color: @primaryLinkDisabledColor;
  }

  .calenderIcon {
    height: 14px;
    width: 14px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;