@import 'styles/variables.less';

.flipCard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
}

.linkText {
  color: @primaryLinkColor;
  font-size: 13px;
  font-style: normal;
  text-decoration: none;
  font-weight: 350;
  line-height: 20px;
  cursor: pointer;
}

.editcontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  .editIcon {
    color: @primaryLinkColor;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  .editInput {
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 4px;
    line-height: normal;
  }
}

.waivedContainer {
  width: 100%;
  height: 290px;
  min-height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  color: @pageHeaderIconColor;
  border-radius: 13px;
  box-shadow: 2px 2px 10px @idCardBoxShadow;
}

.flipCardInner {
  width: 100%;
  height: 290px;
  min-height: 290px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flipCard.flipped .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 13px;
  box-shadow: 2px 2px 10px @idCardBoxShadow;
}

.flipCardFront,
.flipCardBack {
  .employerLogo {
    width: 150px;
    height: auto;
  }
  .cardTopContainer {
    padding: 20px 32px 0px 32px;
    .heading {
      margin-top: 12px;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
    }

    .subHeading {
      margin: 4px 0px 8px 0px;
      color: @pageHeaderIconColor;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .cardBottomContainer {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    padding: 12px 32px 0px 32px;
    background: @idCardBodyBackgroundColor;
    font-size: 13px;
    line-height: 20px;
    height: 100%;
    border-radius: 0 0 13px 13px;

    .elipsisLabel {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .labelText {
      color: @subHeaderColor;
    }
    .containerDivider {
      margin: 12px 0px !important;
    }
  }

  .cardBackBottomContainer {
    padding: 18px 32px 0px 32px;
    .backHeader {
      margin: 7px 0px;
      color: @primaryButtonColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .subHeading {
      margin: 12px 0px;
      color: @pageHeaderIconColor;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 19px;
    }

    .containerDivider {
      margin: 16px 0px !important;
    }
  }
}

.flipCardFront {
  display: flex;
  flex-direction: column;
  transform: rotate(0deg);
}

.flipCardBack {
  display: flex;
  flex-direction: column;
  transform: rotateY(180deg);
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;