@import 'styles/variables.less';

.alert {
  width: 100%;
}

.fixedAlertWrapper {
  margin: 0 3px 20px 0;
  width: 100%;
  :global {
    .ant-alert {
      align-items: flex-start;
    }
    .ant-alert-info {
      background-color: @alertInfoBackgroundColor;
      border-color: @alertInfoBorderColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;