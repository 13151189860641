@import 'styles/variables.less';

.linkTextWrapper {
  margin-bottom: 24px;
  .linkText {
    color: @primaryLinkColor;
    font-size: 13px;
    font-style: normal;
    text-decoration: none;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }
}

.fallBackContainer {
  position: absolute;
  bottom: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
}

.linkBtn {
  padding: 0;
  text-decoration: none;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;