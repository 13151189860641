@import 'styles/media.less';

.planRecommenderOptions {
  text-align: center;
  .content {
    min-height: 400px;
    .title {
      height: unset;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-top: 80px;
      color: black;
      .on-sm-down({
        padding-right: 10%;
        padding-left: 10%;
     });
    }

    .subTitle {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
    }

    .optionsWrapper {
      display: inline-flex;
      text-align: center;
      margin-top: 80px;
      .on-sm-down({
       flex-direction: column;
       margin-top: 50px;
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       flex-wrap: wrap;
    });
      .option {
        border: 1px solid #dadfe8;
        width: 200px;
        height: 200px;
        border-radius: 10px;
        margin-left: 10px;
        background-color: #ffffff;
        position: relative;
        cursor: pointer;
        .on-lg-down({
          width: 170px;
          height: 170px;
          margin-left: 5px;
          margin-right: 5px;
       });
        .on-sm-down({
          width: 350px;
          height: 77px;
          margin-bottom: 10px;
          margin-left: 0px;
       });
        .on-xsm-down({
        margin-left: 10%;
        margin-right: 10%;
     });

        img {
          position: absolute;
          left: 50%;
          top: calc(50% - 15px);
          transform: translate(-50%, -50%);
          user-select: none;
          .on-sm-down({
            margin-left: -130px;
            height: 40%;
            margin-top: 15px;
         });
          .on-xsm-down({
          padding-left: 30px;
       });
        }

        .labelWrapper {
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: 30px;
          .label {
            margin: auto;
            width: 162px;
            transform: translateY(25%);
            color: #2a3233;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            white-space: unset;
            display: revert;
            font-weight: unset;
            padding: unset;
            .on-sm-down({
              width: 230px;
              margin-left: 70px;
              text-align: left;
              font-size: 14px;
           });
          }
        }
      }

      .optionSelected {
        border: 3px solid var(--color-theme);
        font-weight: bold;
        .label-wrapper {
          bottom: 28px;
        }
      }
    }

    .footerText {
      margin-top: 20px;
      height: 16px;
      color: #2a3233;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
    }
  }

  .nextBtnWrapper {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;