@import 'styles/typography';
@import 'styles/variables';

.inputWrapper {
  height: 45px !important;
}

.formInput {
  max-width: 400px;
}

.resetPwdWrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.disabledText {
  font-size: 14px;
  color: @primaryLinkDisabledColor;
  font-weight: 400;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapperContainer {
  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      content: unset;
    }
  }
}

.codeWrapper {
  display: flex;
  flex-direction: column;
  .textWrapper {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 405px;
    margin-bottom: 24px;
  }
}

.submitPwdButton {
  margin-top: 20px;
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

.resetPwdButton {
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 0;
  text-align: center;
  height: 48px;
  .text-link-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;