@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.homeWrapper {
  .on-sm-down({padding: 24px;});

  padding-top: 56px;
  padding-left: 64px;
  padding-right: 76px;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: 100%;
  padding-bottom: 2%;
  overflow-y: auto;
  .on-lg-down({
    padding-left: 31px;
    padding-right: 31px;
  });
  .on-sm-down({
    padding-left: 24px;
    padding-right: 24px;
  });
  .home-text();
  .benguideTitle {
    .home-title();
    color: var(--home-text-color);
    .on-lg-down({
      line-height: normal;
      font-size: 32px;
    });
    .on-sm-down({
      line-height: normal;
      font-size: 22px;
    });
  }

  .enrollmentDateText {
    .enrollment-text();
    color: var(--home-text-color);
    .on-lg-down({
      font-weight: 400;
      line-height: normal;
      font-size: 16px;
    });
    .on-sm-down({
      font-size: 14px;
    });
  }

  .customizerWrapper {
    margin-top: 20px;
    .customizerOverlayWrapper {
      cursor: pointer;
      padding: 20px;
    }

    .customizerHotspot {
      border-radius: 3px;
      color: @primaryFontColor;
      background-color: @white;
      padding: 5px 5px 5px 12px;
      .text-button();
      img {
        margin: -5px 10px 0 30px;
      }
    }

    &.oeMeeting {
      margin: 42px 0px 0px -10px;
    }

    &.oeMeetingAdd {
      width: 305px;
    }

    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          border-radius: 3px;
        }
      }
    }
  }

  .oeMeetingWrapper {
    &.viewMode {
      cursor: pointer;
    }
    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          border-radius: 4px;

          .textWrapper {
            right: 40px;
            top: 20px;
          }
        }
      }
    }
  }

  .oeMeetingSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 3px solid var(--color-theme);
    border-radius: 4px;
    padding: 15px 19px;
    background-color: @white;
    margin: 32px 0px -25px 0px;
    height: 60px;
    .area-text();

    .on-lg-down({
      font-weight: 400;
      line-height: normal;
      font-size: 16px;
      border-width: 1px;
      border-radius: 7px;
    });
    .on-sm-down({
      font-size: 12px;
    });
    .oeMeetingIcon {
      height: 22px;
      margin: 0px 20.5px 0px 0px;
      .on-sm-down({
        height: 28px
      });

      :global {
        .oeMeeting {
          fill: var(--color-theme);
        }
      }
    }
  }
}

.contactSupportContainer {
  display: flex;
  justify-content: center;
  .supportBtnWrapper {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    width: 100%;
    .on-sm-up({
      width: 50%;
    });
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;