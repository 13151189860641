@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.medicalCardValuesWrapper {
  width: 244px;
  margin-left: 3px;
  border: 1px solid @mdvCardBorder;
  line-height: 29px;
  border-radius: 8px 8px 8px 8px;
  border-radius: 8px;
  position: relative;

  @media only screen and (max-width: 1496px) {
    width: 15vw;
  }

  .on-lg-down({
    width: 30%
  });
  .on-sm-down({
    width: 100%;
    margin-bottom: 20px;
    line-height: 20px;
    background-color: @white;
    });

  .moveWrapper {
    width: 100%;
    height: 40px;
    top: -40px;
    position: absolute;

    .moveArea {
      width: 100%;
      height: 40px;
    }

    .moveOverlay {
      display: flex;
      height: 40px;

      .left {
        padding-top: 2px;
        cursor: pointer;
        img {
          margin-left: 10px;
          margin-right: 5px;
        }
      }
      .right {
        padding-top: 2px;
        cursor: pointer;
        margin-left: auto;
        img {
          margin-left: 5px;
          margin-right: 10px;
        }
      }

      label {
        .mdv-card-value();
        cursor: pointer;
      }
    }
  }

  .selectedCardShadow {
    width: 206px;
    height: 5px;
    background: var(--color-theme);
    border-radius: 10px 10px 0px 0px;
  }

  .benefitDetail {
    padding-left: 16px;
    min-height: 29px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .on-sm-down({
      font-size: 16px;
      font-weight: 700;
    });

    .viewSbc {
      .view-sbc-link();
      cursor: pointer;
      padding-top: 15px;
      height: 45px;
    }
    .networkValue {
      padding-right: 5px;
    }
  }

  .basicInfo,
  .monthlyCost {
    padding: 0 16px;
    min-height: 29px;
    background-color: @white;
  }

  .taxAdvantageTags {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .detailSection {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid @separatorColor;
    border-top: 5px solid @planCardTopShadowColor;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    .details {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .taxAdvantageBr {
        display: block;
        margin-bottom: 12px;
      }
    }

    .compare {
      position: absolute;
      top: 5px;
      right: 15px;
      .small-checkbox-text();
      color: @checkboxTextColor;
      .on-sm-down({
        display: none;
      });

      :global {
        .ant-checkbox {
          right: -6px;
        }
        .ant-checkbox-inner {
          width: 14px;
          height: 14px;
          border: 1px solid @smallCheckboxBorderColor;
        }
        .ant-checkbox-inner:after {
          border-color: @white;
          transform: rotate(45deg) scale(0.9) translate(-70%, -50%);
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: var(--color-theme);
          }
        }
        .ant-checkbox-checked::after {
          border: 1px solid var(--color-theme);
        }
      }
    }

    .logoWrapper {
      display: flex;
      width: 100.43px;

      .logo {
        height: 20px;
        object-fit: contain;
      }
      .on-sm-down({
        margin-top: 16px;
      });
    }

    .planName {
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      margin-top: 10px;
      overflow: hidden;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      .on-sm-down({
        width: 100%;
      });
    }
    .oneLineEllipsis {
      white-space: nowrap;
    }

    .hsaContribution {
      display: flex;
      padding-left: 8px;
      align-items: center;
      width: max-content;
      padding-right: 10px;
      height: 18px;
      border-radius: 3px;
      cursor: pointer;
      background-color: var(--color-theme);

      .hsaValue {
        .hsa-value();
      }
    }
    .hsa {
      margin-top: 2%;
    }
    .hra {
      margin-top: 2%;
    }
    .hsaConflict,
    .hraConflict {
      margin-top: 0px;
      margin-left: 0px;
      display: inline-block;
      white-space: nowrap;
      :global {
        .missingOuterWrapper {
          height: unset;
          padding-left: 0px;
          .missingInfoWrapper {
            width: 176px;
            height: unset;
            .missingTextWrapper {
              display: flex;
              align-items: center;
              height: 20px;
              margin-top: 0;
              padding-left: 5px;
              .missingText {
                font-size: 11px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
    .hraConflict {
      margin-top: 2px;
    }

    .medicalPlanInfo {
      max-width: 100%;
      border-color: @borderColor;
      margin-bottom: 10px;

      .link {
        margin-top: -3px;
        display: flex;
        .planTypeButton {
          padding-left: 5px;
          text-decoration-line: underline;
          color: @mdvButtonColor;
          cursor: pointer;
        }
        .on-sm-down({
          height: 24px
        });
      }

      .providerSearch {
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: @mdvButtonColor;
        margin-left: 5px;
        margin-top: 2px;
        cursor: pointer;
      }
    }

    .links {
      width: 100%;
      padding-top: 5px;
      margin-bottom: 5px;
      border-top: 1px solid @mdvCardBorder;
      .on-sm-down({
        padding-top: 10px;
        margin-top: 10px;
      });

      .link {
        display: flex;
        .planTypeButton {
          padding-left: 5px;
          text-decoration-line: underline;
          color: @mdvButtonColor;
          cursor: pointer;
        }
      }

      .providerSearch {
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: @mdvButtonColor;
        margin-left: 5px;
        margin-top: 2px;
        cursor: pointer;
      }
    }
  }

  .monthlyCostMissingInfoWrapper {
    padding-top: 15px;
    padding-left: 0px;
  }
  .monthlyCostDisabled {
    opacity: 0.4;
  }
  .monthlyCost {
    height: 68px;
    padding-top: 10px;
    .on-sm-down({
      border-bottom: 1px solid @mdvCardBorder;
      height: auto;
      padding-bottom: 20px;
      margin-bottom: 10px;
    });

    .monthlyCostValue {
      .home-text-wrapper-title();
      display: flex;
      align-items: center;
      color: @costTextColor;
    }

    .monthlyCoversYou {
      .medical-wrapper-bottom-text();
      line-height: 14px;
    }
  }
  .titleSeperator {
    height: 32px;
    line-height: 29px;
    background-color: @white;
  }
  .emptyValue {
    padding-left: 16px;
  }
  .emptyCustom {
    height: 35px;
  }
}

.rowWrapper:nth-child(even) {
  background-color: rgba(#7c828e, 0.06);
  .on-sm-down({ background-color: @white});
}
.rowWrapper:nth-child(odd) {
  background-color: @white;
}

.missingContribution {
  display: flex;
  :global {
    .missingOuterWrapper {
      height: 29px;
      padding-left: 0px;
      .missingInfoWrapper {
        width: 80px;
        height: 24px;
        border: 2px solid @editableHotspotBorderColor;
        border-radius: 3px;
        .missingTextWrapper {
          padding-left: 3px;
          margin-top: -5px;
          .missingText {
            .missing-info-text-small();
            padding-left: 1px;
          }
          .iconWarning {
            width: 14px;
            height: 13px;
          }
        }
      }
    }
  }
  .contributionText {
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .valWrapper {
    display: flex;
  }
  .valueSeparator {
    padding: 0 5px;
  }
}

.commonMissingTag {
  :global {
    .missingOuterWrapper {
      padding-left: 0px;
    }
  }
}

.serviceHeaderMobile {
  padding-left: 16px;
}

.singleRowEdit {
  top: -2px !important;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;