@import 'styles/variables.less';

.richTextEditorWrapper {
  width: 100%;
  .maxCountLabel {
    display: flex;
    justify-content: flex-end;
    color: @primaryLinkDisabledColor;
    font-size: 12px;
    margin: 10px 0px -10px 0px;
  }

  .richMediaUploadIndicatorWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: @white;
    opacity: 0.5;
    left: 0;
    top: 0;
    z-index: 100;

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;