@import './variables.less';

:global(h1) {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: @primaryFontColor;
  margin-bottom: unset;
}

:global(h2) {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: @primaryFontColor;
  margin-bottom: unset;
}

:global(h3) {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: @primaryFontColor;
  margin-bottom: unset;
}

:global(h4) {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: @primaryFontColor;
}

:global(a) {
  color: @primaryLinkColor;
  text-decoration: underline;
}

.dbg-page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: @defaultFontColor;
}

.mob-dbg-page-title {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: @defaultFontColor;
}

.tab-dbg-page-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 28px;
  color: @defaultFontColor;
}

.mdv-filter-button {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}

.mdv-selected-network {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.mdv-card-title {
  font-weight: bold;
  font-size: 13px;
}

.mdv-card-value {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.mdv-whos-cover-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 60px;
}

.view-sbc-link {
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: @primaryLinkColor;
}

.home-text {
  font-style: normal;
}

.home-title {
  height: 60px;
  font-size: 50px;
  font-weight: 900;
  letter-spacing: 0.05px;
  line-height: 60px;
}

.enrollment-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.home-text-wrapper-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.area-text {
  font-weight: bold;
  font-size: 16px;
}
.text-banner-header {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
}
.text-banner-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.text-section-header {
  font-size: 14px;
  font-weight: bold;
}

.text-button {
  font-weight: bold;
  font-size: 14px;
}

.overlay-text-button {
  font-weight: bold;
  font-size: 18px;
}

.text-submit-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

.text-page-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: @defaultFontColor;
}

.text-bold-logo-uploader {
  font-size: 14px;
  font-weight: 600;
}

.text-logo-uploader {
  font-size: 14px;
  font-weight: 400;
}

.text-modal-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  color: @defaultFontColor;
}

.text-helper {
  font-size: 12px;
  line-height: 18px;
  color: @primaryLinkDisabledColor;
}

.form-error-text {
  font-size: 12px;
  color: @invalidInputColor;
}

.form-input-item-explain {
  font-size: 11px;
  font-weight: 400;
}

.befefit-header {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

.text-popconfirm-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  color: @primaryFontColor;
}

.text-popconfirm-secondary-text {
  font-size: 14px;
  line-height: 22px;
  color: @primaryFontColor;
  font-weight: normal;
}

.label-sticker {
  font-size: 10px;
  color: @editableHotspotBorderColor;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.add-and-remove-plans-icon {
  font-size: 35px;
}

.add-and-remove-plans-title {
  font-size: 18px;
  font-weight: 600;
}

.add-and-remove-plans-secondary-text {
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
}

.add-new-plan-button {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
}

.dbg-edit-modal-title {
  font-size: 18px;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0px;
  font-weight: 600;
}

.dbg-edit-modal-button {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}

.mdv-filter-options {
  font-size: 14px;
}

.mdv-filter-options-arrow {
  font-size: 10px;
}

.mdv-filter-header {
  font-size: 9px;
  font-weight: 700;
}

.small-checkbox-text {
  font-size: 9px;
  line-height: 11px;
}

.mdv-filter-items {
  font-weight: normal;
  * {
    font-size: 12px;
  }
}

.toolbar-header-button-text {
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}

.toolbar-menu-header-text {
  font-size: 10px;
  font-weight: bold;
  color: @benefitTextColor;
}

.dbg-side-bar-support {
  font-weight: bold;
  margin-right: 3px;
  font-size: 12px;
}

.dbg-content-preview {
  margin-left: 10px;
  margin-top: -5px;
  font-size: 12px;
}

.text-link-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: @primaryLinkColor;
}

.mdv-whos-cover-dropdown {
  .selectedTypeLabel {
    font-size: 13px;
  }
  .tabs {
    .tab {
      font-size: 13px;
    }
    .tab.active {
      font-weight: 700;
    }
  }
  .item {
    font-size: 13px;
  }
}

.section-title {
  font-weight: bold;
  font-size: 17px;
  color: @defaultFontColor;
}

.medical-wrapper-bottom-text {
  font-size: 12px;
  font-style: italic;
}

.toolbar-status-indicator-header {
  font-size: 15px;
  font-weight: 900;
}

.toolbar-status-indicator-value {
  font-size: 15px;
  font-weight: 700;
}

.toolbar-status-indicator-label {
  font-weight: 400;
}

.document-remove-popover {
  font-size: 14px;
}

.document-remove-popover-title {
  font-weight: 800;
}

.text-dropdown-menu-item {
  font-weight: bold;
  color: @white;
  font-size: 14px;
}

.telehealth-plan-name {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

.select-label-section-editor {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: @defaultFontColor;
}

.hiw-section-title {
  font-weight: bold;
  font-size: 16px;
  color: @defaultFontColor;
}

.body-h3 {
  font-weight: bold;
  font-size: 16px;
  color: @defaultFontColor;
  line-height: 20px;
}

.line-item-link {
  color: @primaryLinkColor;
  font-size: 13px;
  font-weight: bold;
}

.plan-name {
  font-size: 17px;
  font-weight: 800;
  color: @black;
}

.plan-view-details {
  text-decoration: underline;
  font-size: 13px;
}

.plan-card-heading {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

.mob-plan-card-heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.plan-card-left-panel-detail-label {
  font-size: 11px;
  font-weight: 400;
}

.plan-card-left-panel-detail-value {
  font-size: 15px;
  font-weight: 700;
}

.missing-info-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  color: @primaryFontColor;
}

.missing-info-text-small {
  font-size: 9px;
  font-weight: bold;
  line-height: 10px;
  color: @primaryFontColor;
}

.login-header {
  font-size: 24px;
  font-weight: 600;
}

.dates-heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.heading {
  margin-left: -4px;
  font-size: 16px;
  font-weight: bold;
}

.hsa-value {
  color: @white;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.text-weblink-input {
  font-size: 14px;
  line-height: 17px;
}

.text-additional-resource-header {
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}

.text-additional-resource-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.text-card-sub-header {
  font-size: 9px;
  line-height: 18px;
}

.text-card-header {
  font-size: 20px;
  line-height: 24px;
}

.text-form {
  font-size: 12px;
  font-weight: bold;
  color: @primaryLinkDisabledColor;
}

.no-plans-text {
  font-size: 13px;
  font-weight: 700;
  line-height: 29px;
  color: @defaultFontColor;
}

.styled-collapse-header {
  font-size: 16px;
  font-weight: 700;
}
.boldText {
  font-weight: bold;
}
.text-confirmation-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: @white;
}

.text-tab-header {
  font-weight: bold;
  font-size: 14px;
}

.text-file-upload-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: @cancelButton;
}

.text-action-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: @white;
}
