@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.mdvNetworkSelectorWrapper {
  margin-left: 10px;
  width: 260px;
  height: 100%;
  display: flex;
  * {
    cursor: pointer;
  }

  .networkSection {
    width: 130px;
    position: relative;
    .on-lg-down({
      width: 50%;    
    });

    label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      word-wrap: break-word;
      white-space: nowrap;
    }

    .selectedBorder {
      display: none;
      position: absolute;
      bottom: 5px;
      width: 130px;
      height: 3px;
      background: var(--color-theme);
      border-radius: 2.5px;
      .on-lg-down({
        width: 100%;    
      });
    }
  }
  .networkSection.selected {
    .mdv-selected-network ();

    .selectedBorder {
      display: block;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;