@import 'styles/typography';
@import 'styles/variables';

.codeWrapper {
  display: flex;
  flex-direction: column;
  .textWrapper {
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    font-weight: 100;
    width: 405px;
    margin-bottom: 12px;
  }
}

.submitPwdButton {
  margin-top: 20px;
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

.linkBtn {
  padding: 0;
  text-decoration: none;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;