@import 'styles/variables';

.feedbackRow {
  margin-left: -0.75rem !important;
  .feedbackButton {
    border: none;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
  }

  .feedbackButton:hover {
    background: @feedbackButtonHoverColor;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;