@import 'styles/variables.less';

.switchWrapper {
  :global {
    // Have to use !important to override the checked color, always ant-switch-checked class enables with the .ant-switch
    .ant-switch-checked {
      background-color: @primaryLinkColor !important;
    }

    .ant-switch:focus,
    .ant-switch-checked:focus {
      box-shadow: none;
    }

    .ant-switch {
      min-width: 40px;
      height: 23.08px;
      background-color: @primaryLinkDisabledColor;
    }

    .ant-switch-handle {
      width: 17px;
      height: 17px;
      top: 3.08px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;