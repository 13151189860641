@import 'styles/variables.less';
@import 'styles/typography.less';

.planRecommenderModalWrapper {
  .planRecommenderTooltip {
    position: absolute;
    right: 22px;
  }

  .loader {
    margin: auto;
    padding-left: 200px;
  }

  .errorMessage {
    font-size: 13px;
  }

  .messageWrapper {
    padding-top: 15px;
  }

  .displayText {
    color: @primaryLinkDisabledColor;
  }

  .viewLink {
    color: @primaryLinkColor;
    font-weight: bold;
    text-decoration: none;
    font-size: 13px;
  }

  :global(.ant-modal-body) {
    padding: 13px 30px;
    height: auto;
    border-left: 5px solid @primaryHeaderColor;
    overflow: hidden;
  }

  .title {
    .dbg-edit-modal-title();
    margin-bottom: 15px;
  }

  .toggleWrapper {
    label {
      font-weight: 600;
    }

    button {
      margin-left: 48px;
    }

    :global(.ant-switch-checked) {
      background: @primaryLinkColor;
    }
  }

  .buttonWrapper {
    float: right;
    margin-top: 40px;

    button {
      height: 40px;
      width: 150px;
      .dbg-edit-modal-button();
    }

    .cancel {
      color: @primaryHeaderColor;
      border: 1px solid @primaryHeaderColor;
    }

    .done {
      margin-left: 12px;
      background-color: @primaryHeaderColor;
      color: @white;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;