@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.noPlansWrapper {
  .on-sm-down({
    margin-left: unset;
  });

  .detailWrapper {
    display: table;
    margin: 0 auto;
  }
  .header {
    .overlay-text-button();
    padding-top: 15px;
    line-height: 24px;
    .detailWrapper();
  }
  .subHeader {
    .text-logo-uploader();
    line-height: 40px;
    color: @primaryFontColor;
    text-align: center;
    .detailWrapper();
  }
}

.containedDiv {
  display: flex;
  padding-top: 150px;
  padding-left: 350px;
  height: 100vh;
  .on-lg-down({
    padding-left: 200px;
    padding-right: 200px
  });
  .on-sm-down({
    padding-left: 0px;
  });
  .on-xs-down({
    padding-left: 0px;
    padding-right: 0px
  });
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;