@import 'styles/media.less';

.editDrawer {
  :global(.rs-drawer-header-close) {
    display: none;
  }

  :global {
    .rs-drawer-body {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    .rs-drawer-backdrop.in {
      opacity: 0.5;
    }
    .rs-drawer-right.rs-drawer-md {
      width: 100%;
    }

    .rs-drawer-backdrop.in,
    .rs-drawer-backdrop.fade {
      background-color: white;
    }
  }
}

.editModeHeaderWrapper {
  padding-top: 30px;
  margin-left: 68.5px;
  margin-right: 68.5px;

  .editYourAnswers {
    height: 29px;
    color: #2a3233;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    float: left;
  }
  .backArrow {
    margin-right: 20px;
  }

  .updateButton {
    float: right;
    height: 41px;
    width: 157px;
    color: #ffffff;
    border-radius: 7px;
    text-align: center;
    background-color: var(--color-theme);
    user-select: none;
    outline: none;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    border: none;
    cursor: pointer;
  }

  & .updateButton:disabled {
    background-color: #e0e0e0;
  }

  .splitter {
    margin-top: 60px;
    margin-bottom: 0px;
  }
}

.editModeWrapper {
  margin-left: 68.5px;
  margin-right: 68.5px;
  .on-sm-down({
    margin-left: 10px;
    margin-right: 68.5px;
  });

  :global {
    .content {
      min-height: unset;
    }

    .planRecommenderOptions {
      margin-top: -30px;
      text-align: left;
      border-bottom: 1px solid #e6e7e8;

      .content {
        .options-wrapper {
          margin-top: 35px;
        }
      }

      .title {
        text-align: left;
        font-size: 17px;
        color: #2a3233;
      }
      .subTitle {
        text-align: left;
        font-size: 12px;
        color: #2a3233;
        margin-left: 17px;
        .on-sm-down({
          margin-left: 0px;
       });
      }
      .optionsWrapper {
        margin-left: 17px;

        .option {
          width: 140px;
          height: 140px;

          img {
            max-width: 90%;
          }

          .labelWrapper {
            .label {
              width: unset;
              font-size: 11px;
              line-height: 13px;
            }
          }
        }
      }
    }

    .stateSelectorWrapper {
      text-align: left;
      border-bottom: 1px solid #e6e7e8;
      padding-top: 0;
      padding-bottom: 0;
      .title {
        text-align: left;
        color: #2a3233;
        font-size: 17px;
      }
      .stateDropdown {
        margin-left: 17px;
        margin-top: 30px;
        width: 282px;
        margin-bottom: -15px;
      }
      .stateErrorWrapper {
        margin-left: 15px;

        .stateError {
          margin-left: 0;
        }
      }
      .nextButtonWrapper {
        margin-top: 60px;
      }
    }

    .nextButton {
      display: none;
    }

    .planRecommenderPlansWrapper {
      display: none;
    }

    .planRecommenderResultsWrapper {
      display: none;
    }

    .emailSuccessWrapper {
      display: none;
    }

    .planRecommenderCombinedSlider {
      text-align: left;
      .planRecommenderSlider {
        border-bottom: none;
      }

      .planRecommenderSlider:nth-of-type(even) {
        border-bottom: 1px solid #e6e7e8;
      }

      hr {
        display: none;
      }

      .content {
        .sliderWrapper {
          margin-left: 17px;
        }
        .title {
          text-align: left;
          font-size: 17px;
          color: #2a3233;
          margin-top: 50px;
        }
        .titleSlider {
          text-align: left;
          font-size: 17px;
          color: #2a3233;
          margin-left: 17px;
        }
        .subTitle {
          text-align: left;
          font-size: 12px;
          color: #2a3233;
          margin-left: 17px;
        }
      }
    }

    .planRecommenderSlider {
      margin-top: -30px;
      text-align: left;
      border-bottom: 1px solid #e6e7e8;

      .sliderWrapper {
        margin-left: 17px;
      }
      .title {
        text-align: left;
        font-size: 17px;
        color: #2a3233;
      }
      .subTitle {
        text-align: left;
        font-size: 12px;
        color: #2a3233;
        margin-left: 17px;
      }
    }

    .planRecommenderIncrementer {
      text-align: left;

      .skipText {
        display: none;
      }

      .title {
        text-align: left;
        font-size: 17px;
        color: #2a3233;
      }

      .subTitle {
        text-align: left;
        font-size: 12px;
        color: #2a3233;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;