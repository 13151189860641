@import 'styles/variables.less';

.container {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overlayTop {
  :global {
    .ant-popover-arrow {
      overflow: visible;
      height: 13px;
      width: 13px;
      background-color: transparent;
    }

    .ant-popover-arrow-content {
      left: calc(50% - 13.2px / 2 - 6.5px);
      width: 13px;
      height: 13px;
      border-top: 1px solid transparent;
      border-right: 0.5px solid @black;
      border-bottom: 0.5px solid @black;
      border-left: 1px solid transparent;
      border-radius: 0px 0px 0px 5px;
    }

    .ant-popover-inner {
      border-radius: 8px;
    }

    .ant-popover-inner-content {
      border: 1px solid;
      border-radius: 8px;
    }
  }
}

.overlayRight {
  :global {
    .ant-popover-arrow {
      overflow: visible;
      height: 13px;
      width: 13px;
      background-color: transparent;
    }

    .ant-popover-arrow-content {
      left: calc(50% - 13.2px / 3 - 6.5px);
      width: 13px;
      height: 13px;
      border-left: 0.5px solid @black;
      border-bottom: 0.5px solid @black;
      border-radius: 0px 0px 0px 5px;
    }

    .ant-popover-inner {
      border-radius: 8px;
    }

    .ant-popover-inner-content {
      border: 1px solid;
      border-radius: 8px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;