@import 'styles/variables.less';
@import 'styles/typography.less';

.additionalResWrapper {
  width: 215px;
  .linkHotspot {
    border-radius: 3px;
    color: @primaryFontColor;
    padding: 6px 10px 4px;
    .text-button();
    img {
      margin: 0 0 4px 5px;
    }
  }

  :global(.overlayWrapper) {
    border-radius: 5px;
  }
}

.additionalResEditWrapper {
  width: 275px;
  margin-left: -5px;
  .editHotspot {
    padding: 3px 10px;
  }
  :global {
    .editableHotspotWrapper {
      .overlayWrapper {
        .textWrapper {
          font-weight: 500;
        }
      }
    }
  }
}

:global(.removeButton) {
  color: @removeFileTextColor;
  img {
    color: @removeFileTextColor;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;