@import 'styles/media.less';

.planRecommenderCombinedSlider {
  text-align: center;
  max-width: 700px;
  margin: auto;

  .content {
    .title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-top: 80px;
      color: black;
      .on-sm-down({
        margin-right: 10%;
        margin-left: 10%;
    });
    }

    .subTitle {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
      .on-sm-down({
        margin-right: 5%;
        margin-left: 5%;
    });
    }
  }

  .nextBtnWrapper {
    padding-bottom: 50px;
    .on-sm-down({
      padding-bottom: 0px;
  });
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;