@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.container {
  .on-lg-down({
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  });
  .label {
    .plan-card-left-panel-detail-label();
    .on-sm-down({
      width: 80%;
  });
  }
  .value {
    .plan-card-left-panel-detail-value();
  }
}

.employerContribution {
  .value {
    font-size: 18px;
    color: @costTextColor;
  }
}

.valueEOYP {
  height: 50px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;