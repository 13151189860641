@import 'styles/variables.less';
@import 'styles/media.less';

.container {
  border-radius: 6px;
  position: relative;
  background-color: @white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 0px 1px @defaultLightBorderColor inset;
  .on-lg-down({
    flex-direction: column;
  });
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;