@import 'styles/variables';

.closeIcon {
  :global {
    rect {
      transition: all @layoutTransitionTime;
    }
    #middle-bar {
      // transform: (0);
      width: 0;
    }
    #top-bar {
      transform: rotate(-45deg) translateX(-7.9px) translateY(10.7px);
    }
    #bottom-bar {
      transform: rotate(45deg) translateX(4px) translateY(-15px);
    }
  }
}
.hamburgerMenu {
  :global {
    rect {
      transition: all @layoutTransitionTime;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;