@import 'styles/typography';
@import 'styles/variables';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  .displayImage {
    margin: 32px 0px;
    min-height: 300px;
  }

  .submitPwdButton {
    margin-top: 20px;
    margin-bottom: 24px;
    border-color: @black;
    height: 48px;
    width: 400px;
    border-radius: 4px;
    .text-submit-button();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;