@import 'styles/variables.less';

.wrapperContainer {
  width: 100%;
  padding: 32px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: flex-start;

  .heading {
    font-size: 24px;
    font-weight: 400;
  }

  .subHeading {
    font-size: 20px;
    font-weight: 400;
  }

  .linkText {
    color: @primaryLinkColor;
    font-size: 13px;
    font-style: normal;
    text-decoration: none;
    font-weight: 350;
    line-height: 20px;
    cursor: pointer;
  }

  .flipCardFront,
  .flipCardBack {
    top: 50px;
    width: 504px;
    min-height: 290px;
    border-radius: 13px;
    display: flex;
    margin-bottom: 32px;
    flex-direction: column;
    border: 1px solid @disabledButton;
  }

  .flipCardFront,
  .flipCardBack {
    .employerLogo {
      width: 150px;
      height: auto;
    }
    .cardTopContainer {
      padding: 20px 32px 0px 32px;
      .heading {
        margin-top: 12px;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 15.64px;
      }

      .subHeading {
        margin: 6px 0px 8px 0px;
        color: @pageHeaderIconColor;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .cardBottomContainer {
      margin-top: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px 32px 15px 32px;
      background: @idCardBodyBackgroundColor;
      font-size: 13px;
      line-height: 20px;
      height: 100%;
      border-radius: 0 0 13px 13px;
      min-height: 170px;

      .elipsisLabel {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .labelText {
        color: @subHeaderColor;
      }
      .containerDivider {
        margin: 12px 0px !important;
      }
    }

    .cardBackBottomContainer {
      padding: 18px 32px 0px 32px;
      .backHeader {
        margin: 7px 0px;
        color: @primaryButtonColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .subHeading {
        margin: 12px 0px;
        color: @pageHeaderIconColor;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 19px;
      }

      .containerDivider {
        margin: 16px 0px !important;
      }
    }

    .printableData {
      word-break: break-all;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;