@import 'styles/variables.less';

.bodyWrapper {
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: @customScrollbarColour;
  }
}

.selectionHeight {
  height: 550px;
}

.chatHeight {
  height: 450px;
}

.chatPadding {
  margin-right: 10px;
}

.selectionSectionWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .headerText {
      color: @assistantHeaderTextColor;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: -0.5px;
    }
  }

  .selectionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;