@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';
.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}
.fourOOneKWrapper {
  margin: 0 164px 0 62px;
  position: relative;
  padding-bottom: 50px;
  .on-lg-down({
    margin: 0 4%;
  });
  .icon {
    margin-right: 11.6px;
  }
  .noPlanView {
    margin-left: unset;
  }
  .disclaimer {
    .medical-wrapper-bottom-text();
    margin-top: 35px;
    line-height: 20px;
    letter-spacing: 0.1090909093618393px;
    text-align: left;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;