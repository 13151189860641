@import 'styles/variables.less';

.planNameHeader {
  text-align: start;

  .planNameWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.disabledText {
  color: @primaryLinkDisabledColor;
}

.inputSectionWrapper {
  margin-top: 10px;
  .inputWrapper {
    position: relative;

    .textArea {
      padding: 17px 40px 17px 19px;
      border-radius: 8px;
      border: 1px solid @assistantTextAreaBorderColor;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
      font-size: 16px;
      text-align: start;
      text-rendering: optimizeSpeed;
    }
    .submitBtn {
      position: absolute;
      right: 12px;
      bottom: 14px;
      background-color: transparent;

      &::before {
        background-color: transparent;
      }
    }
    .submitIcon {
      width: 24px;
      height: 24px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;