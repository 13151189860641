@import 'styles/variables.less';

.bouncingLoaderWrapper {
  display: flex;
  margin-top: 10px;
}
.bouncingLoaderWrapper > div {
  width: 9px;
  height: 9px;
  margin: 2px 2px;
  border-radius: 50%;
  background-color: @bouncingDotColor;
  opacity: 1;
  animation: bouncingLoaderWrapper 0.6s infinite alternate;
}

@keyframes bouncingLoaderWrapper {
  to {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

.bouncingLoaderWrapper > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncingLoaderWrapper > div:nth-child(3) {
  animation-delay: 0.4s;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;