@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.container {
  .on-lg-down({
    padding: 10px 0 0;
    });

  .contributionsGrid {
    margin-bottom: 15px;
    .on-lg-down({
      margin-bottom: 0;
    });
    .on-sm-down({
      margin-bottom: 10px;
    });

    .frequency {
      .plan-card-left-panel-detail-value();
      margin-bottom: 15px;
    }
    :global {
      .ant-row:first-child {
        padding-bottom: 15px;
      }
    }

    .nTierRow {
      padding-bottom: 15px;
    }
  }
  .otherContribution {
    word-break: normal;
    .plan-card-left-panel-detail-value();
    margin-bottom: 15px;
  }
  .maxContributionIndividual {
    margin-bottom: 15px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;