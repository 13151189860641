@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.nhcWrapper {
  margin: 0 168px 0 62px;
  .on-lg-down({
    margin: 0 31px;
  });
  .on-sm-down({
    margin: 0 24px;
  });

  .textArea {
    margin-top: 30px;
  }

  .nhcMainImage {
    width: 100%;
  }

  .nhcContent {
    padding-top: 4px;
    iframe,
    img {
      max-width: 100%;
    }
  }

  .nhcUploadWrapper {
    display: inherit;
  }

  a {
    &:hover {
      text-decoration: underline;
      color: @primaryLinkColor;
    }
  }

  .nhcIcon {
    width: 30px;
    height: 26px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;