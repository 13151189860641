@import 'styles/variables';
@import 'styles/typography';
.befefitsWrapper {
  margin-top: 43px;
  padding-bottom: 30px;
  .benefitTitle {
    .befefit-header();
    margin-bottom: 22px;
  }
  .benefit {
    margin-left: 21px;
    :global {
      .ant-checkbox + span {
        padding-left: 4px;
        .bold-text();
      }
    }
  }
  .benefitDescription {
    margin-top: -5px;
    margin-left: 23px;
    color: @benefitTextColor;
    margin-bottom: 30px;
  }
}
.footerBenefitEditor {
  padding: 0px 0px 6px 15px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;