@import 'styles/typography';
@import 'styles/variables';

.textWrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 350px;
  margin-bottom: 24px;
}

.submitPwdButton {
  margin-top: 20px;
  border-color: @black;
  height: 48px;
  width: 400px;
  border-radius: 4px;
  .text-submit-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;