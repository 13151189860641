@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.datesWrapper {
  margin: 0 160px 40px 60px;
  position: relative;
  .on-lg-down({
    margin: 0 31px;
    width: auto;
  });
  .on-sm-down({
    margin: 0 24px;
  });
  .importantDates {
    margin: 3px 16px 0px 2px;
  }
  .openEnrollmentIcon {
    margin-top: 20px;
    .oeIcon {
      .on-sm-down({
        display: none;
      });
    }
  }
  .effectiveDate {
    height: 56px;
    margin-left: 20px;
    .on-sm-down({
      margin-left: 0px;
    });
  }
  .effectiveLabel {
    line-height: 28px;
  }
  .planYear {
    line-height: 28px;
    font-weight: bold;
    font-size: 16px;
    color: @defaultFontColor;
  }
  .editableBorder {
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid @defaultLightBorderColor;
    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          margin-left: -5px;
        }
      }
    }
  }

  .datesHeadingRow {
    width: 100%;
    border-bottom: 1px solid @defaultLightBorderColor;
    padding-bottom: 10px;
    padding-top: 18px;

    .datesHeading {
      .dates-heading();
    }
  }

  .eligibility {
    .heading {
      .heading();
      margin-left: 0px;
    }
  }
  .hotspotHidden {
    width: 150px;
    :global {
      .editableHotspotWrapper {
        .overlayWrapper {
          height: 32px;
          margin-top: 15px;
        }
      }
    }
  }
  .datesIcon {
    width: 22px;
    height: 22px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;