@import 'styles/variables.less';

.modalMain {
  :global {
    .ant-modal-content {
      min-height: 650px;
      background-color: @assistantBackgroundColor;
      border-radius: 10px;

      .ant-modal-header {
        background-color: @assistantBackgroundColor;
        border-bottom: none;
        border-radius: 10px;
      }

      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        border-top: none;
      }
    }
  }
}

.minimizeIcon {
  width: 18px;
  height: 13px;
  cursor: pointer;
}

.closeIcon {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;