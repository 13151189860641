@import 'styles/variables.less';

.footerWrapper {
  text-align: unset;
  padding: 0 58px 10px 58px;
  .newChatSectionWrapper {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    .warningText {
      color: @primaryLinkDisabledColor;
      font-size: 12px;
    }

    .newChatBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: @primaryLinkColor;

      .newChatIcon {
        margin-left: 5px;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;