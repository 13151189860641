@import 'styles/variables.less';
@import 'styles/typography.less';

.benefitSwitchWrapper {
  margin-top: 15px;
  .subHeader {
    margin-right: 12px;
    .benefitsHeader();
  }
}

.benefitsLinkWrapper {
  margin: 30px 10px 30px 0;
  :global {
    .ant-form-item-label > label {
      .benefitsHeader();
    }
  }
}

.benefitsHeader {
  color: @primaryHeaderColor;
  .text-submit-button();
}

.benefitsEnrollFooter {
  margin-right: 10px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;