@import 'styles/variables.less';

.editIcon {
  display: none;
  cursor: pointer;
}
.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  position: relative;
  &:hover {
    .editIcon {
      display: inline-block;
    }
  }
}
.editIconSideNav {
  display: none;
  cursor: pointer;
}
.menuLabelWrapper {
  display: flex;
  &:hover {
    .editIconSideNav {
      display: inline-block;
      margin-right: 5px;
    }
  }
  :global {
    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
}
.displayContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 5px;
  width: 100%;
}
.textContainer {
  width: 160px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  .labelWrapper:hover & {
    width: 140px;
  }
}
.tooltipWrapper{
  :global {
    .ant-tooltip-inner {
      background-color: @white;
      color: @black;
      border: 1px solid @black;
    }
  }
}
@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;