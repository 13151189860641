@import 'styles/variables.less';
@import 'styles/typography.less';

.tooltipOverlay {
  max-width: 427px;
  :global {
    .ant-popover-arrow {
      overflow: visible;
      height: 13px;
      width: 18px;
      background-color: transparent;
      transform: translateY(-50%) rotate(90deg);
    }

    .ant-popover-arrow-content {
      left: calc(50% - 13.2px / 2 - 6.5px);
      width: 11px;
      height: 11px;
      border-right: 0.5px solid @black;
      border-bottom: 0.5px solid @black;
      border-radius: 0px 0px 4px 0px;
    }

    .ant-popover-inner {
      border-radius: 8px;
    }
    .ant-popover-inner-content {
      border: 1px solid;
      border-radius: 8px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;