@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.hiwWrapper {
  margin: 0 168px 0 62px;

  .textArea {
    margin-top: -3px;
  }

  .on-lg-down({
    margin: 0 31px;
  });
  .on-sm-down({
    margin: 0 24px;
  });

  .hiwContent {
    padding: 4px 3px;
  }

  .hiwIcon {
    width: 30px;
    height: 26px;
  }

  .on-lg-down({
    img, iframe {
      width: 100%;
    }
  });

  :global {
    .ant-collapse-borderless {
      background-color: @defaultBackgroundColor;
    }

    .ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header {
      padding-bottom: 22px;
      padding-top: 22px;
      padding-left: 0px;
      .hiw-section-title();
    }

    .ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 0px;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;