@import 'styles/variables.less';

.datePickerWrapper {
  :global {
    .ant-picker {
      width: 100%;
      border-radius: 4px;
    }
    .ant-picker:hover,
    .ant-picker-focused {
      border-color: @lineBorderColor;
    }
    .ant-picker-focused {
      box-shadow: none;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;