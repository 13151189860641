@import 'styles/variables';
@import 'styles/typography';

.confirmModalWrapper {
  :global {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner-content {
      width: 574px;
      padding: 31px 32px;
    }

    .ant-popover-message-title {
      margin-left: 28px;
      margin-top: -37px;
      padding-left: 22px;
      .text-popconfirm-title();
    }

    img {
      width: 45px;
      height: 45px;
    }

    .ant-popover-buttons {
      margin-top: 20px;
      text-align: center;
      margin-left: -45px;
    }

    .ant-btn-sm {
      height: 40px;
      width: 180px;
      border: 1px solid @black;
      border-radius: 4px;
      .text-submit-button();
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;