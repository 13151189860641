@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

@compareBtnTabletWidth: 33%;

.mdvPlansFilters {
  display: flex;
  flex-wrap: wrap;
  .on-lg-down({
    align-items: center;
    row-gap: 15px;
    margin-bottom: 15px
  });
  .on-sm-down({
    justify-content: space-between;
    flex-wrap: wrap; 
  });

  .filterOptions {
    .mdv-filter-options();
    width: 170px;
    user-select: none;
    cursor: pointer;

    .arrowIcon {
      position: absolute;
      left: 154px;
      top: 85px;
      .mdv-filter-options-arrow();
    }
  }

  .filterOptions.active {
    color: var(--color-theme);
  }

  .planProviders {
    width: 200px;
    margin-right: 70px;
  }

  .planProviders,
  .planType {
    .mdv-filter-header();

    .items {
      margin-top: 5px;
      .mdv-filter-items();
      :global {
        .ant-checkbox-checked .ant-checkbox-inner:after {
          border-color: var(--color-theme);
        }
        .ant-checkbox-inner {
          border: 2px solid var(--color-theme);
        }
        .ant-checkbox-checked::after {
          border: 2px solid var(--color-theme);
        }
      }
    }
  }

  .right {
    display: flex;
    margin-left: auto;
    .on-lg-down({
      width: 100%;
      column-gap: 10px;
    });
    & > div.right {
      .on-lg-down({
        width: 66%;
        margin-left: 0;
      });
      .on-sm-down({
        width: 100%;
      });
    }

    .benefitConsultBtnContainer {
      .on-lg-down({
        width: 50%
      });
    }

    :global(.overlayWrapper) {
      border-radius: 7px;
    }

    button {
      .mdv-filter-button();
      height: 34px;
      box-sizing: border-box;
      border-radius: 7px;
      color: @primaryFontColor;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 5px;
        margin-bottom: 4px;
      }
      .on-sm-down({

        font-size: 12px;
        padding: 2px 5px;
      });
    }

    .featureEnabled {
      border: 2px solid var(--color-theme);
      color: var(--color-theme);
      .benefitBtnText {
        color: var(--color-theme);
      }
    }

    .linkButtonOffset,
    .recommenderBtnOverlay {
      background-color: fade(@defaultBackgroundColor, 75%);
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      color: @primaryFontColor;
      width: 184px;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      img {
        margin: -4px 0 0 4px;
      }

      .benefitIcon {
        margin: 0 6px 0 9px;
        :global {
          .path {
            stroke: @primaryLinkDisabledColor;
          }
        }
      }
    }

    .recommenderBtnOverlay {
      color: @primaryFontColor;
      width: 169px;
      .starIcon {
        path {
          fill: @primaryLinkDisabledColor;
        }
      }
      img {
        margin-left: 5px;
        background-color: fade(@defaultBackgroundColor, 10%);
        margin-left: 4px;
      }
    }

    .addBenefitConsultButton {
      width: 190px;
      display: flex;
      .on-lg-down({
        width: 100%;
        justify-content: center
      });

      &.spacing {
        margin-right: 15px;
      }

      &.edit {
        width: 170px;
      }

      .benefitWrapper {
        .benefitIcon {
          margin-right: 5px;
          margin-top: 3px;

          :global {
            .path {
              stroke: var(--color-theme);
            }
          }
        }
      }
    }

    .planRecommenderButton {
      width: 180px;
      border: 2px solid var(--color-theme);
      .on-lg-down({
        width: 100%;
      });
      span {
        display: flex;
      }
      .starIcon {
        margin-top: 1px;
      }

      img {
        margin-left: 2px;
      }
    }

    .compareButton {
      border-radius: 7px;
      margin-left: 17px;
      height: 34px;
      width: 130px;
      height: 34px;
      background: #e8e9eb;
      border-radius: 7px;
      color: white;
      border: none;
      .on-lg-down({
        width: @compareBtnTabletWidth;
        margin-left: 0;
      });
    }

    .compareButton.active {
      background-color: var(--color-theme);
      width: 130px;
      .on-lg-down({
        width: @compareBtnTabletWidth;
      });
    }

    .compareButton.clearComparison {
      width: 150px;
      .on-lg-down({
        width: @compareBtnTabletWidth;
      });
    }

    .bcButtonEditWrapper {
      &.spacing {
        margin-right: 12px;
      }
    }

    .planRecommenderButtonWrapper {
      .on-lg-down({
        width: 50%;
      });
    }

    .planRecommenderButtonWrapper.disabled {
      * {
        display: none;
        border-radius: 7px;
        background-color: @white;
        border-color: @disabledButton;
        color: @disabledButton;
        opacity: 0.7;
      }

      :global(.overlayWrapper) {
        display: none;
      }
    }
  }
}

.hide {
  display: none;
  * {
    display: none;
  }
}

.starIcon {
  margin-right: 4px;
  margin-top: -2px;
  :global {
    .path {
      fill: var(--color-theme);
    }
  }
}
.lineBreak {
  border: 0.5px solid @defaultLightBorderColor;
  margin-top: 15px;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 0.5px;
  .on-lg-down({
    display: none;
  });
}

.filterSelect {
  height: 40px;
  &:global(.ant-select-single) {
    :global {
      .ant-select-selector {
        height: 40px;
        align-items: center;
      }
    }
  }
}

.dropdownFilterMenu {
  max-width: 100%;
  .filterSection {
    padding: 5%;
  }
  .title {
    font-weight: bold;
  }
  .filterItem {
    padding: 3px 0;
  }
  :global {
    .ant-dropdown-menu-item {
      padding: 5px 18px 5px 12px;
      &:hover {
        background-color: unset;
      }
    }
  }
}

.filterToggleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 100%;
  padding: 8px 10px 8px 15px;
  width: 45%;
  &:hover,
  :focus,
  :active {
    border-color: @inputBorderColor;
  }
}

// Handle Dental and Vision specific styles
.dvFilters {
  .right {
    & > div.right {
      width: 100%;
    }
    .benefitConsultBtnContainer {
      width: 100%;
    }
  }
}
.btnRightMain {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;