@import 'styles/typography';
@import 'styles/variables';

.planContainer {
  .submitPwdButton {
    margin-top: 20px;
    border-color: @black;
    height: 48px;
    width: 400px;
    border-radius: 4px;
    .text-submit-button();
  }
  .inputWrapper {
    height: 45px !important;
  }
  .disabledText {
    margin-top: 12px;
    color: @primaryLinkDisabledColor;
    font-size: 12px;
  }
  .backButton {
    margin-top: 12px;
  }
}

.disabledList {
  margin-top: 12px;
  color: @primaryLinkDisabledColor !important;
  font-size: 12px;
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}

.headingRow {
  display: flex;
  align-items: center;
  gap: 6px;
}

.planListWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .planHeading {
    color: @subHeaderColor;
    font-size: 16px;
    font-weight: 600;
  }
  .removeCursor {
    cursor: default !important;
    margin: 112px 0px;
  }

  .planItemWrapper {
    display: flex;
    flex-direction: column;

    .itemDivider {
      margin: 6px 0px !important;
    }

    .flexRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      .listText {
        color: @primaryButtonColor;
        font-size: 14px;
        font-weight: 400;
        margin: 12px 0px;
      }
      .listChecked {
        color: @primaryLinkColor !important;
      }
    }
  }
}

.resetPwdButton {
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 0;
  text-align: center;
  height: 48px;
  .text-link-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;