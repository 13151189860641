@import 'styles/variables.less';

.modalWrapper {
  :global {
    .ant-modal-body {
      margin-top: 16px;
      min-height: 0;
    }
  }
}
.btn {
  width: 164px;
  height: 40px;
  border-radius: 4px;
}

.cancel {
  border: 1px solid @black;
  color: @primaryHeaderColor;
  font-weight: 600;
}
.resetBtn {
  color: @primaryLinkColor;
  font-weight: 600;
  margin-right: 25px;
  &:hover {
    color: @primaryLinkColor;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  div {
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: @primaryHeaderColor;
  }
}

.input {
  border-radius: 4px;
}

.errorInput {
  border: 1px solid @invalidInputColor;
}

.charLimit {
  margin-top: 4px;
  color: @optionalTextColor;
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
}
.errorText {
  color: @errorMessageColor;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;