@import 'styles/variables.less';
@import 'styles/typography.less';

.sectionEditorWrapper {
  .selectLabel {
    .select-label-section-editor();
    margin: 30px 10px 40px 0px;
  }

  .selectDropdown {
    margin: 13px 0px 28px 0px;
    height: 40px;
    width: 400px;
  }

  .sectionSwitch {
    height: fit-content;
    text-align: center;
  }

  .overlay {
    margin-left: 10px;
    .text-button();
  }

  .toggle {
    margin-left: 10px;
    :global {
      .ant-switch-checked {
        background-color: @primaryLinkColor;
      }
    }
  }
  .datePickerCol {
    margin-top: -30px;
  }
  .datePicker {
    margin-top: 20px;
  }
  .datePickerDate {
    margin-left: 10px;
  }
  .datePickerMargin {
    margin-top: -3px;
    margin-left: 10px;
  }
  .richText {
    :global {
      .tox-edit-area__iframe {
        background-color: @mdvCardBorder;
      }
    }
  }
}

.extraSectionWrapper {
  margin-bottom: 1rem;
}

.waiverCreditLabel {
  text-align: center;
  .select-label-section-editor();
  margin-right: 0.2rem;
}

.waiverCreditInput {
  width: 7rem;
}

.waiverCreditSelect {
  width: 12rem;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;