@import 'styles/media.less';

.planSummaryBoxWrapper {
  .planRecommended {
    box-sizing: border-box;
    border-radius: 6px;
    border: 3px solid var(--color-theme);
    height: 360px;
    width: 100%;
    margin-bottom: 22px;
  }

  .false {
    border: 3px solid #1f273e;
  }

  .planRating {
    display: inline-block;
    margin-left: 14px;
    position: relative;
    text-align: center;
    color: var(--color-theme);
    .rankText {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -3px;
      font-size: 11px;
      padding-right: 2px;
    }
  }
  .planRatingIcon {
    margin-top: -10px;
    & #badge-1 {
      fill: var(--color-theme);
    }
  }
  .planName {
    font-weight: 900;
    font-size: 24px;
    display: inline-block;
    margin-left: 16px;
    margin-top: 20px;
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -10px;
  }
  .noRatesDash {
    text-align: center;
  }

  .planType {
    display: inline-block;
    margin-left: 8px;
  }

  .planTypeText {
    font-weight: normal;
  }

  .premiumBlock {
    display: inline-block;
    vertical-align: top;
    margin-left: 32px;
    margin-top: 30px;
    width: 30%;
  }

  .deductibleBlock {
    display: inline-block;
    vertical-align: top;
    margin-left: -60px;
    margin-top: 30px;
    width: 10%;
    .on-xl-down({
      width: 12%;
    });
  }

  .otherNamesBlock {
    display: inline-block;
    vertical-align: top;
    margin-left: 60px;
    margin-top: 30px;
    width: 16%;
  }

  .otherValuesBlock {
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 30px;
    font-weight: bold;
    width: 37%;
    .on-xl-down({
      margin-left: 0px;
    });
  }
  .valueFooter {
    font-style: italic;
    font-size: 12px;
    margin-top: 5px;
  }

  .deductibleValue,
  .premiumValue {
    font-weight: 400;
    font-size: 27px;
  }
  .lineSpacingData {
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .lineSpacingDataFirst {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .separatorPlan {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #e6e7e8;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 30px;
    width: 93%;
  }
  .carrierLogo {
    font-weight: 700;
    font-size: 10px;
    margin-top: 12px;
    float: right;
    height: 30px;
    width: 160px;
    object-fit: contain;
  }
  .planFooterTitleCol {
    width: 16%;
    display: inline-block;
    margin-top: 21px;
    margin-left: 32px;
    float: left;
  }
  .planFooterValuesCol {
    font-size: 11px;
    float: left;
    width: 16%;
    display: inline-block;
    margin-top: 21px;
  }
  .planFooterValuesRed {
    font-size: 22px;
    font-weight: 700;
    float: left;
  }
  .planFooterValuesGreen {
    font-size: 22px;
    font-weight: 700;
    color: #28b667;
    float: left;
  }
  .planFooterValuesBlack {
    font-size: 22px;
    font-weight: 700;
    float: left;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;