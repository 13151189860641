@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.benefitDetail {
  padding-left: 16px;
  min-height: 29px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .viewSbc {
    .view-sbc-link();
    cursor: pointer;
    padding-top: 15px;
    height: 45px;
  }
  .networkValue {
    padding-right: 5px;
    .on-sm-down({
      font-size: 16px;
      font-weight: 700;
    });
  }
}

.evenRow {
  background-color: @white;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;