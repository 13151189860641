@import 'styles/variables.less';
@import 'styles/typography.less';

.multiSelectPlanWrapper {
  :global {
    .anticon {
      stroke-width: 50px;
      stroke: @black;
      opacity: 0.3;
      font-size: 12px;
    }

    .ant-input {
      height: 18px;
    }

    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      border: none;
      outline: none;
      caret-color: transparent;
      cursor: pointer;
    }
    .ant-input-suffix {
      position: absolute;
      right: 10px;
      top: 9px;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus {
      border-color: @inputBorderColor;
    }
    .ant-input-affix-wrapper-focused {
      border-color: @inputBorderColor;
    }
  }
}
.dropdownInput {
  :global {
    input.ant-input {
      position: absolute;
      background: transparent;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 10;
      text-indent: 9px;
      padding-top: 0;
    }
  }
}

.horizontalDropdown {
  :global {
    min-width: 0px;
    .ant-dropdown-menu {
      display: flex;
      width: 29.7%;
      flex-wrap: wrap;
      position: fixed;
      max-height: 200px;
      overflow: auto;
      flex-direction: column;
    }

    .ant-dropdown-menu-item {
      width: 33%;
    }
    .ant-checkbox-wrapper {
      width: 100%;
    }
  }
}

.normalDropdown {
  :global {
    .ant-checkbox-wrapper {
      width: 100%;
    }
    .ant-checkbox-group {
      width: 100%;
    }
    .ant-dropdown-menu {
      max-height: 350px;
      overflow-y: scroll;
    }
    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: @lineBorderColor;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: @primaryLinkDisabledColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;