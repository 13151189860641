@import 'styles/variables.less';

.wrapper {
  :global {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      background-color: @white;
      border: 2px solid @defaultFontColor;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: @defaultFontColor;
    }
  }
  &.invalid {
    :global {
      .ant-checkbox-inner {
        border: 2px solid @invalidInputColor;
      }
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;