@import 'styles/variables';
@import 'styles/media';

.planRecommenderLayout {
  position: relative;
  background-color: @defaultBackgroundColor;
  height: 100vh;
  overflow: auto;
  padding-bottom: 200px;
  .on-sm-down({
    padding-bottom: 0px;
  });
  .on-xsm-down({
    padding-bottom: 0px;
  });

  .content {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 1100px;
    background-color: @defaultBackgroundColor;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;