@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.planTypeInfoWrapper {
  margin-left: 68.5px;
  margin-right: 68.5px;
  margin-bottom: 68.5px;
  .on-sm-down({
    margin: 0;
  });

  .titleCol {
    text-align: left;
    border-right: 1px solid @defaultLightBorderColor;
    &.border-none {
      border-right: none;
    }
    height: 37px;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    padding-top: 5px;
    background-color: @white;
  }
  .typeHeader {
    padding-top: 30px;

    .sectionHeader {
      color: @defaultFontColor;
      font-family: Lato;
      font-size: 24px;
      font-weight: bold;
      float: left;
    }

    .splitter {
      margin-top: 45px;
      margin-bottom: 27px;
    }
  }
  .planTypeSection {
    font-size: 16px;
  }
  .paraSpacing {
    margin-top: 25px;
  }
  .sectionInfo {
    color: @defaultFontColor;
    font-weight: bold;
    margin-top: 30px;
  }
  .th1 {
    border-bottom: 1px solid @defaultLightBorderColor;
  }

  .td-border {
    border-right: 1px solid @defaultLightBorderColor;
  }
  .tmiddle {
    border-right: 1px solid @defaultLightBorderColor;
    border-left: 1px solid @defaultLightBorderColor;
    border-bottom: 1px solid @defaultLightBorderColor;
  }
  .heading {
    font-family: Lato;
    font-size: 15px;
    font-weight: 900;
  }
  .description {
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .descriptionPOS {
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 26px;
  }
  .oapDescription {
    margin-top: 20px;
  }
  .darkData {
    background-color: @planTypeDrawerColor;

    &.bordered {
      border-right: 1px solid @defaultLightBorderColor;
    }
  }
  .light {
    background-color: @planTypeDrawerColor;
    &.borderless {
      border-right: none;
    }
    opacity: 0.8;
    border-right: 1px solid @defaultLightBorderColor;
  }
  .greenDot {
    height: 8px;
    width: 8px;
    background-color: var(--color-theme);
    border-radius: 50%;
    display: inline-block;
  }
  .otherPlansDescription {
    font-size: 16px;
  }

  .tablePlanInfo {
    display: block;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    overflow-x: auto;
  }

  .highlightCol {
    background-color: rgba(0, 255, 113, 0.1);
    width: 20%;
  }
  .normalCol {
    width: 20%;
  }
  .highlightHeader {
    background-color: rgba(0, 255, 113, 0.1);
    margin-top: -17px;
    height: 16px;
  }
  .typeSubHeader {
    padding-top: 30px;

    .sectionHeader {
      color: @defaultFontColor;
      font-family: Lato;
      font-size: 24px;
      font-weight: bold;
      float: left;
    }

    .splitter {
      margin-top: 45px;
      margin-bottom: 27px;
    }
  }
}
.backArrow {
  margin-right: 20px;
}
.drawer {
  :global {
    .ant-drawer-header-title {
      .on-lg-down({
        flex: 1 1;
        align-items: center;
        justify-content: space-between;
        display: none;
      });
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;