@import 'styles/variables.less';
@import 'styles/typography.less';

.wrapper {
  .toolbar-header-button-text();
  cursor: pointer;
}
.status {
  margin-left: 5px;
  color: @editableHotspotBorderColor;
}

.dropdownWrapper {
  :global {
    .ant-dropdown-menu {
      max-width: 850px;
      border-radius: 4px;
      background-color: @appHeaderBackgroundColor;
      color: @white;
      &:hover {
        background-color: @appHeaderBackgroundColor;
        color: @white;
      }
    }
    .ant-dropdown-menu-item {
      cursor: default;
      background-color: unset;
    }
  }
}

.dropdownContent {
  display: flex;
  padding: 15px 40px 20px 22px;
  color: @white;
  white-space: normal;
  word-break: break-word;
  max-width: 800px;
  min-width: 550px;
  box-sizing: unset;

  svg {
    height: 50px;
    width: 50px;
    stroke: @white;
  }
  path {
    stroke: @white;
  }
  path:last-child {
    fill: white;
  }

  .infoSection {
    display: flex;
    margin-left: 30px;
    flex-direction: column;
  }

  .infoRow {
    display: flex;
    margin-top: 10px;
  }

  .statusHeader {
    color: @white;
    margin-top: 8px;
    .toolbar-status-indicator-header();
  }

  .infoLabel {
    min-width: 150px;
    .toolbar-status-indicator-label();
  }

  .infoValue {
    .toolbar-status-indicator-value();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;