@import 'styles/variables.less';
@import 'styles/typography.less';

.wrapper {
  position: relative;
  .loadingOverlay {
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @disabledOverLayColor;
  }

  &.disabledMode {
    :global {
      .ant-form-item-label {
        label {
          color: @primaryLinkDisabledColor;
        }
      }

      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        color: @primaryLinkDisabledColor;
      }
    }
  }

  :global {
    .ant-input {
      border: 1px solid @inputBorderColor;
      border-radius: 4px;
      font-size: 13px;
      height: 30px;
    }
    .ant-input:focus {
      box-shadow: 0 0 0 1px @inputBorderColor;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid @inputBorderColor;
      border-radius: 4px;
      height: 30px;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border: 1px solid @inputBorderColor;
      box-shadow: 0 0 0 1px @inputBorderColor;
    }

    .ant-form-item-label {
      label {
        color: @primaryFontColor;
      }
    }

    .ant-form-item-has-error {
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        color: @invalidInputColor;
      }
      .ant-form-item-label {
        label {
          color: @invalidInputColor;
        }
      }
      .ant-checkbox-inner {
        border-color: @invalidInputColor;
      }
      .ant-form-item-explain-error {
        .form-error-text();
      }
    }
    //removes asterisk in front of label
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    //show asterisk after the required label
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-left: 4px;
      color: @defaultFontColor;
      font-size: 14px;
      line-height: 1;
      content: '*';
    }

    .ant-picker {
      height: 30px;
    }
    .ant-picker-input > input {
      font-size: 13px;
    }
    .ant-picker-focused {
      border: 1px solid @inputBorderColor;
      box-shadow: 0 0 0 1px @inputBorderColor;
    }
    .ant-picker:hover {
      border: 1px solid @inputBorderColor;
    }
    .ant-form-item-explain-warning {
      text-align: right;
      .form-input-item-explain();
      color: @defaultFontColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;