@import 'styles/variables.less';

.editableHotspotWrapper {
  position: relative;

  .overlayWrapper {
    display: none;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    border: @editableHotspotBorderColor 3px solid;
    background-color: @editableHotspotBackgroundColor;

    .textWrapper {
      position: absolute;
      right: 10px;
      top: 5px;
      font-weight: bold;
      font-size: 12px;
      z-index: 100;

      img {
        margin-left: 5px;
        margin-top: -4px;
      }
    }
  }
}

:global {
  .ant-dropdown-menu-title-content {
    .overlayWrapper {
      box-shadow: none;
    }
  }
}

.editableHotspotWrapper.active,
.editableHotspotWrapper.hotspotted {
  cursor: pointer;

  .overlayWrapper {
    display: block;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.25s;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.editableHotspotWrapper.noPointer {
  cursor: unset;
}

.editableHotspotWrapper.noHotspotted.alwaysVisible {
  .overlayWrapper {
    background-color: unset;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;