.badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.small {
  width: 13.5px;
  height: 13.5px;
}

.medium {
  width: 16px;
  height: 16px;
}

.large {
  width: 20px;
  height: 20px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;