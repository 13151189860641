@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/media.less';

.customRowWrapper {
  .fixedAlertMessage {
    color: @warningTextColor;
    margin-bottom: 16px;
  }

  .customRow {
    margin-bottom: 16px;
  }

  .charLimit {
    display: block;
    margin-top: 4px;
    color: @optionalTextColor;
    font-size: 12px;
  }

  .addRow {
    margin-top: 16px;
  }

  .actions {
    margin-top: 24px;
    text-align: right;
  }
}
.linkHotspot {
  color: @primaryLinkColor;
  font-weight: bold;
  margin-left: -3px;
}

.btn {
  width: 164px;
  height: 40px;
  border-radius: 4px;
}

.cancel {
  border: 1px solid @black;
  color: @primaryHeaderColor;
  font-weight: 600;
}

.inputField {
  margin-left: 8px;
  width: 100%;
  border-radius: 4px;
}

.addIcon {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 6px;
}

.iconDefault {
  filter: none;
  color: gray;
  font-size: 18px;
  cursor: default;
  margin-right: 8px;
}

.iconClickable {
  filter: invert(14%) sepia(95%) saturate(7143%) hue-rotate(347deg)
    brightness(95%) contrast(113%);
  color: red;
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;