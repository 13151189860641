@import 'styles/variables.less';
@import 'styles/typography.less';

.acceptBtnWrapper {
  text-align: center;

  .acceptBtn {
    border-color: @black;
    height: 48px;
    width: 400px;
    border-radius: 4px;
    .text-submit-button();
  }

  :global {
    .ant-btn-primary[disabled] {
      color: @white;
      border-radius: 4px;
      background: @primaryLinkDisabledColor;
      border-color: @primaryLinkDisabledColor;
    }
  }
}

.pageAdditionalActionBtnWrapper {
  text-align: start;
}
.additionalPageActionBtn {
  border-radius: 4px;
  .text-action-button();
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;