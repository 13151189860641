@import 'styles/variables.less';
@import 'styles/media.less';
@import 'styles/typography.less';

.perksCardWrapper {
  min-height: 187px;
  margin-top: 41px;
  display: flex;
  position: relative;
  .on-lg-down({
    flex-direction: column;
    width: 100%;
  });

  .summaryWrapper {
    width: 285px;
    height: 100%;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    .on-lg-down({
      border-radius: 8px 8px 0 0;
      border: none;
      border-top: 5px solid @defaultLightBorderColor;
      border-bottom: 1px solid @defaultLightBorderColor;
      width: 100%;
    });

    :global(.editableHotspotWrapper) {
      height: 100%;
    }

    .summary {
      padding-top: 20px;
      padding-left: 25px;
      min-height: 187px;

      .on-lg-down({
        display: flex;
        justify-content: space-between;
        padding: 20px;
        min-height: unset;
      });

      .icon {
        img {
          width: 98px;
          height: 26px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }

      .planName {
        margin-bottom: 20px;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        color: @black;
        display: inline-block;
        .plan-name();
        .on-lg-down({
          max-width: unset;
          word-break: normal;
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
        });
      }
    }
  }
  .viewDetailsLink {
    width: 230px;
    color: @primaryLinkColor;
    padding-top: 10px;
    cursor: pointer;
    .plan-view-details();
    border-top: 1px solid @defaultLightBorderColor;
    .on-lg-down({
      border-top: none;
      padding-top: 0;
      display: flex;
      flex-shrink: 0; 
      width: auto;
    });
  }

  .description {
    width: 100%;
    min-height: 187px;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    word-break: break-word;
    .on-lg-down({
      border-radius: 0 0 8px 8px;
      width: 100%;
      border: none;
    });

    :global(.editableHotspotWrapper) {
      height: 100%;
    }

    .content {
      padding: 30px;
      .on-lg-down({
        padding: 20px
      });
    }
  }

  .addTextWrapper {
    position: relative;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    width: 100%;
    .addTextHotspot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;