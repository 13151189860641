@import 'styles/variables.less';
@import 'styles/media';

.planRecommenderPlansWrapper {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 100px;
  background: @defaultBackgroundColor;
  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title {
      margin-top: 10px;
      margin-bottom: 0px;
      height: auto;
      color: @defaultFontColor;
      font-size: 26px;
      font-weight: bold;
      line-height: 1.2;
      word-wrap: break-word;
      text-align: center;
      .on-sm-down({
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
      });
    }

    .subTitle {
      width: 80%;
      max-width: 704px;
      margin: 0 auto 20px auto;
      color: @defaultFontColor;
      font-size: 15px;
      line-height: 1.5;
      word-wrap: break-word;
      text-align: center;
      .on-sm-down({
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
      });

      .editLink {
        background: var(--color-theme);
        height: 21px;
        border-radius: 4px;
        color: @white;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
        cursor: pointer;
        padding: 1px;
        padding: 1px 5px;
        font-weight: 600;
      }
    }
  }

  .plansWrapper {
    margin-bottom: 120px;

    .plansTitleWrapper {
      height: 100%;

      .titleField {
        height: 29px;
        text-align: left;
        font-weight: bold;
        font-size: 12px;
        padding-top: 5px;
        .on-sm-down({
          display: none;
        });
      }

      .titleField.hsa {
        padding-top: 15px;
      }

      .monthlyPremium {
        height: 70px;
        .on-sm-down({
          display: none;
        });
      }

      .annualCost {
        height: 85px;
        padding-top: 55px;
      }

      .annualCostBreakdown {
        height: 100px;
        padding-top: 100px;
      }
    }

    .plansDetailsWrapper {
      .planCardsContainer {
        max-width: 50vw;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-left: -100px;
        .planEmpty {
          display: none;
        }
      }

      .planCardsContainer1 {
        max-width: 50vw;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-left: 20px;
        margin-right: 20px;

        .planEmpty {
          display: none;
        }
      }
      .planCardsContainer2 {
        max-width: 20vw;
        display: inline-flex;
        height: 100%;
        overflow: hidden;

        .planEmpty {
          display: none;
        }
      }
      .breakdownWrapper {
        border-top: 1px solid @defaultLightBorderColor;
        border-bottom: 1px solid @defaultLightBorderColor;
        margin-top: 20px;
        padding-top: 25px;
        margin-top: -70px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        position: absolute;
        .on-sm-down({
          margin-top: 70px;
          right: 30px;
          left: 30px;
        });
      }

      .planEmptyMsgWrapper {
        display: none;
      }
    }

    .plansMoreWrapper {
      height: 100%;
      width: calc(100% - 154px - 252px * 3);
      padding-top: 260px;
      color: @mdvButtonColor;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      text-decoration: underline;
    }
    .morePlansPointer {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .footerLink {
    margin-bottom: 8px;
    button {
      width: 240px;
    }

    .downloadResultButton {
      background-color: @black;
      margin-bottom: -700px;
      height: 65px;
      width: 100%;
    }
    a.scheduleMeetingLinkBtn {
      color: @mdvButtonColor;
      text-decoration: underline;
    }
    a.scheduleMeetingLinkBtn:hover {
      color: @mdvButtonColor;
      text-decoration: underline;
    }
  }
}

.planRecommenderPlansWrapperEmpty {
  .downloadResultButton {
    display: none;
  }
  .plansWrapper {
    margin-bottom: 40px;
    position: relative;
    .plansDetailsWrapper {
      .planCardsContainer {
        .plan {
          background: url(/images/plan-recommender/card-blank.png);
          background-repeat: no-repeat;
          padding-top: 10px;
          background-size: auto 530px;
          opacity: 1;
          pointer-events: none;
          * {
            visibility: hidden;
          }
        }
        .planEmpty {
          display: block;
        }
      }

      .breakdownWrapper {
        display: none;
      }
      .planEmptyMsgWrapper {
        position: absolute;
        margin-top: 100px;
        text-align: center;
        border: @invalidInputColor 1px solid;
        background-color: @planEmptyMsg;
        height: 134px;
        padding: 25px;
        z-index: 1;
        left: 196px;
        display: block;
        .on-lg-down({
          left: 0px;
          width: 90%;
          margin-right: 0;
          margin-left: 5%;
        });
        .on-sm-down({
          left: 0px;
          height: 170px;
          margin-top: 0px;
        });
        .on-xsm-down({
          height: 170px;
          margin-top: 0px;
          margin-left: 10%;
          width: 300px;
          margin-top: 41px;
        });

        button {
          margin-top: 20px;
          .on-xsm-down({
            width: 157px;
            height: 41px;
            border-radius: 7px;
            left: 140px;
            margin-bottom: 30px;
          });
          .on-sm-down({
            width: 157px;
            height: 41px;
            border-radius: 7px;
            left: 70px;
            margin-bottom: 30px;
          });
        }
      }
    }

    .plansTitleWrapper {
      display: none;
      .annualCostBreakdown {
        display: none;
      }
    }
  }

  .footerLink {
    & button {
      pointer-events: none;
      background-color: @lineBorderColor;
      color: @white;
    }
    & a {
      pointer-events: none;
      color: @lineBorderColor;
    }
  }
}
.planRecommenderLoaderOverlay {
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  &.show {
    display: flex;
  }
  &.hidden {
    display: none;
  }

  .loader {
    color: var(--color-theme);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.alignRight {
  position: absolute;
  right: 25px;
  top: 800px;
  z-index: 100;
}
.alignLeft {
  position: absolute;
  top: 800px;
  left: 25px;
  z-index: 100;
}
.planRecoMainWrapper {
  display: flex;
  overflow: hidden;
  left: 50px;
}
.planRecommenderImageLeft {
  top: 463px;
  left: -180px;
  z-index: 10;
  height: 700px;
  position: absolute;
}
.planRecommenderImageRight {
  top: 463px;
  left: 320px;
  z-index: 10;
  height: 700px;
  position: absolute;
}
.annualCost {
  margin-top: 50px;
  margin-bottom: -50px;
  font-size: 11px;
  font-weight: bold;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;