@import 'styles/variables.less';
@import 'styles/media.less';
@import 'styles/typography.less';
.planAlertWrapper {
  position: absolute;
  top: 136px;
  left: 450px;
  width: 25%;
}
.wrapper {
  margin: 0 165px 0 62px;
  position: relative;
  .on-sm-down({
    padding: 0 24px 5% 24px;
    margin: 0 !important;
  });
  .on-lg-down({
    margin: 0 31px 0 31px;
  });
  > div:first-child {
    .on-sm-down({
      margin-bottom: 0;
    });
  }

  .disclaimer {
    .medical-wrapper-bottom-text();
    margin-top: 35px;
    line-height: 20px;
    letter-spacing: 0.1090909093618393px;
    text-align: left;
  }
}
.headerIcon {
  margin-top: 2px;
  height: 23px;
  width: 26px;
  margin-right: 3.8px;
  path {
    fill: @pageHeaderIconColor;
  }
}
.menuItemHeader {
  margin-top: 10px;

  :global {
    .ant-dropdown-menu-title-content {
      font-size: 12px;
      color: @primaryLinkDisabledColor;
    }
  }
}

.darkButton {
  .add-new-plan-button ();
  background: @defaultFontColor;
  color: @white;
  width: 164px;
  height: 40px;
  border-radius: 4px;
}

.noPlanView {
  margin-left: unset;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;