@import 'styles/variables';

.card {
  border-radius: 10px;
  border: 5px solid @assistantBorderColor;
  margin: 12px;
  width: 280px;
  height: 150px;
  background: @white;
  text-shadow: none;
  box-shadow: none;
  white-space: normal;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 18px;

  .cardIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardTitle {
    color: @assistantCardTitleColor;
    font-size: 13px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
  }

  .cardText {
    color: @optionalTextColor;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}

.card:hover {
  border: 5px solid @assistantBorderColor;
}

.card:focus {
  border: 5px solid @assistantBorderColor;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;