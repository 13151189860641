@import 'styles/variables.less';
@import 'styles/typography.less';

.linkHotspot {
  color: @primaryFontColor;
  padding: 4px 4px 4px 10px;
  .text-button();
  width: 140px;
  border: @editableHotspotBorderColor 3px solid;
  border-radius: 4px;
  strong {
    margin-right: 3px;
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;