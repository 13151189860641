@import 'styles/variables.less';
@import 'styles/typography.less';

.documentUploaderWrapper {
  position: absolute;
  display: flex;
  min-width: max-content;
  input[type='file'] {
    display: none;
  }

  .fileLabel {
    display: flex;
    margin-left: 30px;
    margin-top: 12px;
    .text-link-button();
  }
  .additionalDocFileLabel {
    display: flex;
    margin-top: 12px;
    .text-link-button();
  }

  .fileLabel.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
  }

  .fileNameArea {
    margin-left: 8px;
    cursor: pointer;
    overflow: auto;
    width: 125px;
  }

  .removeFile {
    margin-left: 7px;
    color: @warningColor;
    cursor: pointer;
  }

  :global {
    .ant-btn {
      width: 150px;
      height: 50px;
      border: 1px solid @black;
      margin-top: 0px;
      .text-file-upload-button();
    }
  }
}

.cancelUploadBody {
  margin-bottom: -20px;
  p {
    .text-popconfirm-secondary-text();
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;