@import 'styles/variables.less';
@import 'styles/media.less';
@import 'styles/typography.less';

.wellbeingCardWrapper {
  min-height: 187px;
  margin-top: 45px;
  position: relative;
  padding-right: 50px;
  display: flex;

  .on-lg-down({
    flex-direction: column;
    min-height: auto;
    flex-basis: 48%;
  });

  .on-sm-down({
    margin-top: 30px;
  });

  .topShadow {
    height: 5px;
    background: @planCardTopShadowColor;
    border-radius: 10px 10px 0px 0px;
  }

  .summaryWrapper {
    width: 285px;
    height: 100%;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;

    .on-lg-down({
      width: auto;
      border-radius: 0;
      border-bottom: none;
    });

    :global(.editableHotspotWrapper) {
      height: 100%;
    }

    .summary {
      padding-top: 20px;
      padding-left: 25px;
      min-height: 187px;
      .on-lg-down({
        display: flex;
        min-height: auto;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
      });
      .planHeaderWrapper {
        .on-lg-down({
          margin-bottom: 0px;
        });
        .on-sm-down({
          display: flex;
          flex-direction: column;
        });
      }
      .logoWrapper {
        margin-bottom: 5px;
        height: 20px;
        width: 100.43px;
        .logo {
          height: 100%;
          object-fit: contain;
        }
      }

      .planName {
        margin-bottom: 20px;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        color: @black;
        display: inline-block;
        .plan-name();
        .on-lg-down({
          margin-bottom: 0;
        });
      }

      .viewDetailsLink {
        width: 230px;
        color: @primaryLinkColor;
        padding-top: 10px;
        cursor: pointer;
        .plan-view-details();
        border-top: 1px solid @defaultLightBorderColor;
        .on-lg-down({
          width: auto;
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        });
      }
    }
  }

  .description {
    width: 100%;
    min-height: 187px;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    word-break: break-word;
    :global(.editableHotspotWrapper) {
      height: 100%;
    }

    .on-lg-down({
      border-radius: 0 0 8px 8px;
      border-left: 1px solid @defaultLightBorderColor;
    });

    .content {
      padding: 30px;
    }
  }

  .addTextWrapper {
    position: relative;
    background-color: @white;
    mix-blend-mode: normal;
    border: 1px solid @defaultLightBorderColor;
    box-sizing: border-box;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    width: 100%;
    .addTextHotspot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.moveWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  .hotSpotHoverContainer {
    height: 100% !important;
  }
}

.moveOverlay {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;
  height: 100%;
  width: 100%;

  label {
    .mdv-card-value();
    cursor: pointer;
  }
}

.top,
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.topIcon {
  transform: rotate(90deg);
}
.bottomIcon {
  transform: rotate(90deg);
}

.moveArea {
  min-width: 50px;
  min-height: 50px;
  height: 100%;
  width: 100%;
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;