@import 'styles/variables.less';

.radioWrapper {
  :global {
    .ant-radio-checked {
      .ant-radio-inner {
        border: none;
        background-color: @primaryLinkColor;
      }
      .ant-radio-inner::after {
        background-color: @white;
      }
    }
    .ant-radio-checked .ant-radio-inner {
      border: none;
      background-color: @primaryLinkColor;
    }
    .ant-radio-checked .ant-radio-inner::after {
      background-color: @white;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }

    .ant-radio-disabled + span {
      color: @primaryLinkDisabledColor;
    }
  }
}

@primary-color: #2A3233;@body-background: #FCFDFE;@layout-body-background: #FFFFFF;@error-color: #E1464E;@select-item-selected-bg: #EBEBEB;@font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;